import { withStyles } from 'tss-react/mui';
import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid, Typography } from '@mui/material';

const styles = theme => ({
  pswd_info: {
    marginBottom: '10px',
    fontSize: '.875em',
  },
  invalid: {
    color: 'red',
  },
  valid: {
    color: '#44d437',
  },
  none: {
    display: 'none',
  },
});

function PassRequireView({ classes, display, equal, length, lower, upper, number }) {
  return (
    <Grid className={display ? classes.pswd_info : classes.none}>
      <Typography variant="body1">The password should:</Typography>

      <Typography className={equal ? classes.invalid : classes.valid} variant="body2">
        <li>
          Not be equal to <strong>email</strong>
        </li>
      </Typography>
      <Typography className={!length ? classes.invalid : classes.valid} variant="body2">
        <li>
          Have at least <strong>6 characters</strong>
        </li>
      </Typography>
      <Typography className={!lower ? classes.invalid : classes.valid} variant="body2">
        <li>
          Have at least <strong>one lowercase latin letter</strong>
        </li>
      </Typography>

      <Typography className={!upper ? classes.invalid : classes.valid} variant="body2">
        <li>
          Have at least <strong>one uppercase latin letter</strong>
        </li>
      </Typography>

      <Typography className={!number ? classes.invalid : classes.valid} variant="body2">
        <li>
          Have at least <strong>one number</strong>
        </li>
      </Typography>
    </Grid>
  );
}
PassRequireView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const PassRequire = withStyles(PassRequireView,styles);
