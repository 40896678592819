import { withStyles } from 'tss-react/mui';
import React from 'react';
import { Grid } from '@mui/material';

import logo from './../../../assets/logo.svg';

const styles = theme => ({
  root: {
    display: 'flex',
    margin: '25px 0px',
    justifyContent: 'center',
    width: '100%',
    height: '40px',
    backgroundColor: '#F4F4F4',
  },
});

const LogoView = ({ classes }) => (
  <Grid item className={classes.root}>
    <img alt="Logo" src={logo} />
  </Grid>
);

export const Logo = withStyles(LogoView, styles);
