import { useEffect, useMemo, useState } from 'react';

export const usePassRequire = (email = '', password, repeatPassword) => {
  const [ isSamePass, setIsSamePass ] = useState(false);
  const [ isEqual, setIsEqual ] = useState(false);
  const [ isMinLength, setIsMinLength ] = useState(false);
  const [ isHaveLower, setIsHaveLower ] = useState(false);
  const [ isHaveUpper, setIsHaveUpper ] = useState(false);
  const [ isHaveNumber, setIsHaveNumber ] = useState(false);

  useEffect(() => {
    if (password.toLocaleLowerCase() === email.toLocaleLowerCase()) {
      setIsEqual(true);
    } else {
      setIsEqual(false);
    }

    if (password.length >= 6) {
      setIsMinLength(true);
    } else {
      setIsMinLength(false);
    }

    if (password.match(/[A-Z]/)) {
      setIsHaveUpper(true);
    } else {
      setIsHaveUpper(false);
    }

    if (password.match(/[a-z]/)) {
      setIsHaveLower(true);
    } else {
      setIsHaveLower(false);
    }

    if (password.match(/\d/)) {
      setIsHaveNumber(true);
    } else {
      setIsHaveNumber(false);
    }
    if (password === repeatPassword) {
      setIsSamePass(true);
    } else {
      setIsSamePass(false);
    }
  }, [ email, password, repeatPassword ]);

  const validPass = useMemo(
    () => !isEqual && isMinLength && isHaveLower && isHaveUpper && isHaveNumber,
    [ isEqual, isMinLength, isHaveLower, isHaveUpper, isHaveNumber ]
  );

  return { isSamePass, isEqual, isMinLength, isHaveLower, isHaveUpper, isHaveNumber, validPass };
};
