import React from 'react';
import { Dialog, DialogTitle } from '@mui/material';

export const Modal = ({ handleClose, open, scroll = 'body', children, title, classes, ...props }) => (
  <Dialog
    aria-labelledby="form-dialog-title"
    className={classes?.root}
    open={open}
    scroll={scroll}
    onClose={handleClose}
    {...props}
  >
    {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
    {children}
  </Dialog>
);
