import { withStyles } from 'tss-react/mui';
import DatePicker from 'react-datepicker';
import React from 'react';
import { getTimezoneOffset } from 'date-fns-tz';
import { isValid } from 'date-fns';
import { InputLabel, FormControl, FormHelperText } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

const styles = theme => ({
  label: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginBottom: '-6px',
    position: 'relative',
    width: 'max-content',
  },
  error: {
    color: 'red',
    opacity: '0.6',
    fontSize: '14px',
  },
  date: {
    border: '1px solid color',
  },
});

const DatepickerView = ({ classes, value, onChange, label, disabled, error, helperText, className, type, manualTime = false, popperContainer, ...props }) => {

  const timeZoneBerlin = getTimezoneOffset('Europe/Berlin', new Date().getTime());
  const localTimeZone = getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone.toString());

  const manualSetTime = (date, manualTime) => {
    date.setHours(...manualTime);
    onChange(date);
  };

  const changeTime = (e) => {
    if (manualTime?.[2]) {
      e.setSeconds(manualTime[2]);
    }
    if (manualTime?.[3]) {
      e.setMilliseconds(manualTime[3]);
    }
    onChange(e);
  };

  return (
    <FormControl className={className} variant="standard">
      <InputLabel shrink className={error ? classes.error : classes.label} htmlFor={props.id}>
        {label}
      </InputLabel>
      <DatePicker
        autoComplete="off"
        className={error ? 'error' : 'date'}
        dateFormat={`dd.MM.yyyy ${type === 'datetime' ? 'HH:mm' : ''}`}
        popperContainer={popperContainer}
        popperPlacement="bottom-start"
        readOnly={disabled}
        selected={value}
        showTimeInput={Boolean(type === 'datetime')}
        styles={{ padding: '0' }}
        timeFormat="HH:mm"
        timeIntervals={1}
        onCalendarOpen={() => {
          if (type === 'datetime') {
            if (value && isValid(value)) {
              console.info('valid');
            } else {
              const newdate = new Date((new Date().getTime() - localTimeZone) + timeZoneBerlin);
              manualTime ? manualSetTime(newdate, manualTime) : onChange(newdate);
            }
          }
        }}
        onChange={e => changeTime(e)}
        {...props}
      />
      <FormHelperText disabled={error} error={true}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export const Datepicker = withStyles(DatepickerView, styles);
