import React from 'react';

import { PositionRoutes } from './models';

const PositionPage = React.lazy(() => import('./pages/position-page'));
const { POSITION_ROOT } = PositionRoutes;
export const positionRoutes = [
  {
    path: POSITION_ROOT,
    element: <PositionPage />,
  },
];
