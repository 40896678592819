import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { actions } from '../symbiotes';
import { assetTypesdSelector } from '../selectors';
import { dictionariesApi } from '../api';

const mapStateToProps = state => ({
  assetTypes: assetTypesdSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getAssetTypes: async () => {
    const res = await dispatch(dictionariesApi.getAssetTypes);
    dispatch(actions.saveAssetTypes(res));
  },
});

export const AssetTypesItemsView = ({ assetTypes, value, onChange, disabled, error, classes, label, name, getAssetTypes }) => {
  useEffect(() => {
    if (!assetTypes.length) {
      getAssetTypes();
    }
  }, [ assetTypes.length, getAssetTypes ]);
  return (
    <FormControl className={classes} error={error} variant="standard">
      <InputLabel shrink htmlFor="label">
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        error={error}
        name={name}
        value={value}
        variant="standard"
        onChange={e => onChange(e.target.value)}>
        <MenuItem value="">None</MenuItem>
        {assetTypes.map(a => (
          <MenuItem key={a.id} value={a.Name}>
            {a.Name}
          </MenuItem>
        ))}
        <MenuItem key='Convertible bonds ("Bonds")' value='Convertible bonds ("Bonds")'>
          Convertible bonds ("Bonds")
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export const AssetTypesItems = connect(mapStateToProps, mapDispatchToProps)(AssetTypesItemsView);
