import { withStyles } from 'tss-react/mui';
import React from 'react';
import { Grid, Button, } from '@mui/material';

const styles = theme => ({
  button: {
    marginRight: '20px',
  },
  wrap: {
    marginTop: '20px',
    width: '200px',
  },
});
const ActionsViews = ({ classes, onSave, onCancel }) => (
  <Grid container justifyContent="center">
    <Grid item className={classes.wrap}>
      <Button className={classes.button} color="primary" variant="contained" onClick={onSave}>
        Save
      </Button>
      <Button color="primary" variant="contained" onClick={onCancel}>
        Cancel
      </Button>
    </Grid>
  </Grid>
);

export const Actions = withStyles(ActionsViews, styles);
