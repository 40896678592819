import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { NOT_FOUND } from 'routes';
import { Mode } from 'features/products/direct-sale/models';
import { Direction, virtualAssetTypes } from 'features/common';

import { IsJsonString } from './object';
import { convertDateTimeTimestamp } from './date';

const { EDIT, VIEW } = Mode;

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [ search ]);
};

export const useModeView = (mode) => {
  const [ modeView, setModeView ] = useState(false);
  useEffect(() => {
    if (mode === 'view') {
      setModeView(true);
    }
  }, [ mode ]);
  return { modeView, setModeView };
};

export const useUpdateValue = (setIsFilterNotApply) => {
  const onChange = useCallback(
    setValue => (e) => {
      const newValue = e;
      setValue(newValue);
      setIsFilterNotApply(true);
    },
    [ setIsFilterNotApply ]
  );
  return onChange;
};

export const useFilterDate = (addFilter, filter, setIsFilterNotApply, withSeconds = false) => {
  const onChangeDate = useCallback(
    (direction, setDate) => (e) => {
      const newDate = e;
      if (setDate) {
        setDate(newDate);
      }
      if (newDate) {
        addFilter({ ...filter, [direction]: convertDateTimeTimestamp(newDate, withSeconds) });
      } else {
        delete filter[direction];
        addFilter({ ...filter });
      }
      setIsFilterNotApply(true);
    },
    [ addFilter, setIsFilterNotApply, filter, withSeconds ]
  );
  return onChangeDate;
};
export const useFilterValue = (addFilter, filter, setIsFilterNotApply) => {
  const onChangeValue = useCallback(
    (value, setValue, regular = false) =>
      (e) => {
        const newValue = !regular ? e.target.value : e;
        if (setValue) {
          setValue(newValue);
        }
        if (newValue) {
          addFilter({ ...filter, [value]: newValue });
        } else {
          delete filter[value];
          addFilter({ ...filter });
        }
        setIsFilterNotApply(true);
      },
    [ addFilter, setIsFilterNotApply, filter ]
  );
  return onChangeValue;
};

export const mapItemsByItem =
  mapItem =>
    (items, ...args) =>
      items?.map(item => mapItem(item, ...args)) ?? [];

export const useWrongMode = (mode, type) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (mode && mode !== EDIT && mode !== VIEW) {
      navigate(NOT_FOUND);
    }
    if (type && type !== Direction.BUY && type !== Direction.SELL) {
      navigate(NOT_FOUND);
    }
  }, [ navigate, mode, type ]);
};

export const useNewTabs = (modelTabs, allTabs, pathOrder, mode) => {
  const [ tabIndex, setTabIndex ] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split('/')[pathOrder];
  const handleCallToRouter = useCallback(
    (value) => {
      navigate(allTabs[value].route, { replace: true });
    },
    [ allTabs, navigate ]
  );

  const handleGoRoot = useCallback((e) => {
    const root = allTabs.find(tab => tab.name === e.target.textContent).route;
    if (location.pathname !== root) {
      navigate(root);
    }
  }, [ allTabs, location, navigate ]) ;

  useEffect(() => {
    if (mode) {
      const tabIndex = Object.values(modelTabs).find(tab => tab.route === path)?.index;
      if (tabIndex < allTabs.length) {
        setTabIndex(tabIndex);
      }
      if (!path) {
        handleCallToRouter(0);
      }
    }
  }, [ location, path, allTabs.length, mode, modelTabs, handleCallToRouter ]);
  return { tabIndex, handleCallToRouter, handleGoRoot };
};

export const useSubTabs = (modelSubTabs, allSubTubs, pathOrder) => {
  let location = useLocation();
  const navigate = useNavigate();

  const [ subTabIndex, setSubTabIndex ] = useState(0);
  const pathArray = location.pathname.split('/');

  const handleCallToRouter = useCallback(
    (value) => {
      pathArray[pathOrder] = allSubTubs[value].route;
      const path = pathArray.join('/');
      navigate(path, { replace: true });
    },
    [ navigate, allSubTubs, pathArray, pathOrder ]
  );

  useEffect(() => {
    setSubTabIndex(Object.values(modelSubTabs).find(tab => tab.route === pathArray[pathOrder])?.index ?? 0);
    if (!pathArray[pathOrder]) {
      handleCallToRouter(0);
    }
  }, [ location, pathArray, modelSubTabs, pathOrder, handleCallToRouter ]);

  return { subTabIndex, handleCallToRouter };
};

export const parseLocalStorage = value => IsJsonString(value) ? value : '""';

export const useLocalStorage = (key, initialValue) => {
  const [ storedValue, setStoredValue ] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);

      return item ? (JSON.parse(parseLocalStorage(item))) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.error(error);
    }
  };
  return [ storedValue, setValue ];
};

export const getLocalStorage = key => JSON.parse(parseLocalStorage(localStorage.getItem(key)));
export const setLocalStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));

export const getUserBrowser = () => {
  let browser = '';
  const { userAgent } = navigator;
  if (userAgent.indexOf('Firefox') > -1) {
    browser = 'Mozilla Firefox';
  } else if (userAgent.indexOf('OPR') > -1) {
    browser = 'Opera';
  } else if (userAgent.indexOf('Trident') > -1) {
    browser = 'Microsoft Internet Explorer';
  } else if (userAgent.indexOf('Edg') > -1) {
    browser = 'Microsoft Edge';
  } else if (userAgent.indexOf('Chrome') > -1) {
    browser = 'Google Chrome or Chromium';
  } else if (userAgent.indexOf('Safari') > -1) {
    browser = 'Apple Safari';
  } else {
    browser = 'unknown browser';
  }
  return browser;
};

export const useMultiselect = (setIsFilterApply, assets = [], filter, addFilter) => {
  const [ allVirtualStatus, setAllVirtualStatus ] = useState('unchecked');

  const assetInclude = assetType => virtualAssetTypes.includes(assetType);

  const onChangeMultiselect = (value, setFunc ) => (e, virtual) => {
    if (e.target.value.includes('none') && e.target.value.length > 1) {
      if (setFunc) setFunc([]);
      if (filter) {
        delete filter[value];
        addFilter({ ...filter });
      }
      setIsFilterApply(true);
    } else if ((e.target.value.includes('none') && e.target.value.length === 1) || e.target.value.length === 0) {
      if (setFunc) setFunc([]);
      if (filter) {
        delete filter[value];
        addFilter({ ...filter });
      }
    } else {
      if (setFunc) setFunc(e.target.value);
      if (filter) addFilter({ ...filter, [value]: e.target.value });
      setIsFilterApply(true);
    }

    if (e.target.value.includes('unchecked')) {
      setAllVirtualStatus('checked');
      assets.pop();
      if (setFunc) setFunc([ ...assets, ...virtual ]);
      if (filter) addFilter({ ...filter, [value]: [ ...assets, ...virtual ] });
      setIsFilterApply(true);
    } else if (e.target.value.includes('checked')) {
      setAllVirtualStatus('unchecked');
      assets.pop();
      const newValue = assets.filter(asset => !assetInclude(asset.Type));
      if (setFunc) setFunc(newValue);
      if (filter) addFilter({ ...filter, [value]: newValue });
      setIsFilterApply(true);
    }
  };

  const selectedVirtualAssets = useMemo(() => assets.filter(asset => assetInclude(asset.Type)), [ assets ]);

  const assetsId = useMemo(() => assets?.map(asset => asset.id), [ assets ]);

  return { onChangeMultiselect, allVirtualStatus, setAllVirtualStatus, selectedVirtualAssets, assetsId };
};

export const getIsValueChanged = (initialValue, newValue) => JSON.stringify(initialValue) !== JSON.stringify(newValue);

export const useValueChangesTraking = (initialValue, newValue) => {
  const isValueChanged = useMemo(() => (getIsValueChanged(initialValue, newValue)), [ initialValue, newValue ]);
  return isValueChanged;
};

export const getSortDescending = (array = [], field = false) => {
  if (Array.isArray(array)) {
    return array.sort((a, b) => {
      if (field) {
        if (b[field] > a[field]) return 1;
        if (b[field] < a[field]) return -1;
        return 0;
      } else {
        if (b > a) return 1;
        if (b < a) return -1;
        return 0;
      }
    });
  } else {
    return [];
  }
};

export const getSortAscending = (array = [], field = false) => {
  if (Array.isArray(array)) {
    return array.sort((a, b) => {
      if (field) {
        if (b[field] < a[field]) return 1;
        if (b[field] > a[field]) return -1;
        return 0;
      } else {
        if (b < a) return 1;
        if (b > a) return -1;
        return 0;
      }
    });
  } else {
    return [];
  }
};
