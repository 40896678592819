import { useEffect, useRef } from 'react';

export const getMSFromMin = minutes => minutes * 60 * 1000;
export const getMinFromMS = ms => ms / 60 / 1000;
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [ callback ]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [ delay ]);
}
