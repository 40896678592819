import { withStyles } from 'tss-react/mui';
import React from 'react';
import { DialogContent, Grid, Typography } from '@mui/material';

import { Button, Modal } from 'ui';

const styles = theme => ({
  main: {
    width: '200px',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  description: {
    padding: '20px 20px 0px 10px',
    fontSize: '15px',
    color: '#333',
  },
  button: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  buttonClose: {
    marginTop: theme.spacing(3),
    width: '178px',
  },
});

const DialogNotifyView = ({ classes, handleClose, open }) => (
  <Modal handleClose={handleClose} open={open} title="Settings">
    <DialogContent >
      <Typography className={classes.title} component="h1" variant="h5">
        Notification
      </Typography>
      <Typography className={classes.description} component="h1" variant="h5">
        Well done! Two-factor authentication enabled.
      </Typography>

      <Grid container justifyContent="center">
        <Button className={classes.buttonClose} theme="white" onClick={handleClose}>
          Close
        </Button>
      </Grid>
    </DialogContent>
  </Modal>
);

export const DialogNotify = withStyles(DialogNotifyView, styles);
