import React from 'react';

import { NotificationsRoutes } from './models';

const NotificationsPage = React.lazy(() => import('./pages/notifications-page'));
const NotificationsTab = React.lazy(() => import('./components/notifications-tab'));
const SettingsTab = React.lazy(() => import('./components/settings-tab'));
const { NOTIFICATIONS_ROOT, MAIN, CONFIGURE } = NotificationsRoutes;
export const notificationsRoutes = [
  {
    path: NOTIFICATIONS_ROOT,
    element: <NotificationsPage />,
    children: [
      {
        path: '',
        element: <NotificationsTab />,
      },
      {
        path: MAIN,
        element: <NotificationsTab />,
      },
      {
        path: CONFIGURE,
        element: <SettingsTab />,
      },
    ],
  },
];
