import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, InputLabel, Select, MenuItem, Tooltip, } from '@mui/material';

import { symbolTypesSelector } from '../selectors';
import { getSymbolTypes } from '../effects';

const styles = theme => ({
  select: {
    width: '160px',
  },
  filterItem: {
    '& + .MuiFormControl-root': {
      marginLeft: 15,
    },
  },
});

const mapStateToProps = state => ({
  types: symbolTypesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getTypes: () => dispatch(getSymbolTypes),
});

export const SymbolTypesView = ({ classes, value, onChangeMultiselect, disabled, label, name, types, getTypes }) => {

  const [ isShowTooltip, setShowTooltip ] = useState(false);
  useEffect(() => {
    getTypes();
  }, [ getTypes ]);

  return (
    <FormControl className={classes.filterItem} variant="standard">
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Tooltip open={isShowTooltip} placement='top' title={value.join(', ')} >
        <Select
          multiple
          className={classes.select}
          disabled={disabled}
          name={name}
          renderValue={selected => selected.join(', ')}
          value={value}
          variant="standard"
          onChange={e => onChangeMultiselect(e)}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          onOpen={() => setShowTooltip(false)}
        >
          <MenuItem value="none">
            Clear
          </MenuItem>
          {types?.map(type => (
            <MenuItem key={type} value={type}>
              <Checkbox checked={value.includes(type)} color="primary" />
              {type}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    </FormControl>
  );
};

export const SymbolTypes = connect(mapStateToProps, mapDispatchToProps)(withStyles(SymbolTypesView, styles));
