import { createFetching, initialFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

const blockchainInitialState = {
  fetchOne: initialFetching,
  fetchGetBCTransfer: initialFetching,
  fetchResendBCTransfer: initialFetching,
  booksTransactions: [],
  bidsTransactions: [],
  bookBuildingIds: [],
  item: {},
  emissions: [],
  trades: [],
  transfers: [],
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchGetBCTransfer: createFetching('fetchGetBCTransfer'),
  fetchResendBCTransfer: createFetching('fetchResendBCTransfer'),
  setBooksTransactions: (state, booksTransactions) => ({ ...state, booksTransactions }),
  setEmissions: (state, emissions) => ({ ...state, emissions }),
  setTrades: (state, trades) => ({ ...state, trades }),
  setTransfers: (state, transfers) => ({ ...state, transfers }),
  setBidsTransactions: (state, bidsTransactions) => ({ ...state, bidsTransactions }),
  setBookBuildingIds: (state, bookBuildingIds) => ({ ...state, bookBuildingIds }),
  setItem: (state, item) => ({ ...state, item }),
};

export const { actions, reducer } = createSymbiote(blockchainInitialState, symbiote, 'blockchains');
