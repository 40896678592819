import { withStyles } from 'tss-react/mui';
import React, { useEffect, useMemo } from 'react';
import { Paper, Table as MaterialTable, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, TableFooter } from '@mui/material';
import { cx } from '@emotion/css';

import { getSorting, stableSort, useTableSort } from 'lib';

const styles = (theme, { isAccounts = false }) => ({
  row: {
    cursor: isAccounts ? 'pointer' : 'default',
  },
  active: {
    backgroundColor: 'rgba(0,0,0,0.05)',
  },
  newStyleCell: {},
});

/**
 *
 * @description rows, [{ key: value }] - массив объектов, которые мы будем показывать,
 *      имена ключей отображаемых полей должны совпадать с columns
 *      columns, {key: label} - объект для отображения столбцов,
 *      key ключа соотвествующий данным который приходят через rows,
 *      label описание стобца для заголовка таблицы
 * @param {*} {
 *   classes,
 *   rows,
 *   columns,
 *   renderActions,
 *   handleRow = () => {},
 *   active
 * }
 * @returns component
 */
function TableView({ classes, rows, columns, renderActions, handleRow = () => {}, pagination, active, isSorted = true, actionsWrap = 'initial' }) {
  const { order, orderBy, createSortHandler } = useTableSort();
  const [ rowsPerPage, setRowsPerPage ] = React.useState(10);
  const [ page, setPage ] = React.useState(0);
  const [ sorted, setSorted ] = React.useState([]);

  const count = useMemo(() => rows.length, [ rows ]);

  const rowsPerPages = useMemo(() => {
    let countPage = [ 10, 25 ];
    if (rows?.length > 100) countPage.push(100);
    if (rows?.length > 250) countPage.push(250);
    if (rows?.length > 500) countPage.push(500);
    countPage.push(rows?.length);
    return countPage;
  }, [ rows ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const sortedRows = stableSort(rows, orderBy, getSorting(order, orderBy));
    if (pagination) {
      setSorted(sortedRows.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage));
    } else setSorted(sortedRows);
  }, [ rows, page, rowsPerPage, order, orderBy, pagination ]);

  return (
    <Paper className='table'>
      <MaterialTable>
        <TableHead>
          <TableRow>
            {Object.entries(columns).map(value => (
              <TableCell className={classes.newStyleCell} key={value[0]}>
                {isSorted ? (
                  <TableSortLabel active={orderBy === value[0]} direction={order} onClick={createSortHandler(value[0])}>
                    {value[1]}
                  </TableSortLabel>
                ) : (
                  <>{value[1]}</>
                )}
              </TableCell>
            ))}
            {renderActions && <TableCell>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {sorted.map((row, index) => (
            <TableRow className={cx(classes.row, active === index ? classes.active : '')} key={'row' + index} onClick={() => handleRow(row, index)}>
              {Object.keys(columns).map((key, i) => (
                <TableCell className={classes.newStyleCell} key={'cell' + i}>
                  {row[key] !== null && row[key] !== undefined && typeof row[key] !== 'object'
                    ? String(row[key])
                    : typeof row[key] === 'object' && row[key] !== null
                      ? row[key]
                      : '-'}
                </TableCell>
              ))}
              {renderActions && <TableCell style={{ textWrap: actionsWrap }}>{renderActions(row, index)}</TableCell>}
            </TableRow>
          ))}
        </TableBody>
        {pagination === true && (
          <TableFooter>
            <TableRow>
              <TablePagination
                component="td"
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPages}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        )}
      </MaterialTable>
    </Paper>
  );
}

export const Table = withStyles(TableView, styles);
