import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  marketingPrograms: [],
  clonedMarketingProgramsSell: null,
  clonedMarketingProgramsBuy: null,
  types: [],
  typeParams: [],
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  setMarketingPrograms: (state, data) => ({ ...state, marketingPrograms: data }),
  setClonedMarketingProgramsSell: (state, data) => ({ ...state, clonedMarketingProgramsSell: data }),
  setClonedMarketingProgramsBuy: (state, data) => ({ ...state, clonedMarketingProgramsBuy: data }),
  setTypes: (state, data) => ({ ...state, types: data }),
  setTypeParams: (state, data) => ({ ...state, typeParams: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'marketingPrograms');
