import { Navigate } from 'react-router-dom';
import React from 'react';

import { transactionsRoutes } from 'features/transactions';
import { settingsRoutes } from 'features/settings';
import { productsRoutes } from 'features/products/routes';
import { positionRoutes } from 'features/positions';
import { marketingRoutes } from 'features/marketing-programs';
import { operatorsRoutes } from 'features/manage-users';
import { disclosureRoutes } from 'features/disclosure';
import { cryptoCurrenciesRoutes } from 'features/cryptocurrencies';
import { conversionOrdersRoutes } from 'features/conversion-orders';
import { NotFoundPage } from 'features/common/not-found-page';
import { CommonTemplate } from 'features/common/components/common-template';
import { clientsRoutes } from 'features/clients';
import { blockchainRoutes } from 'features/blockchain';
import { authRoutes } from 'features/auth';
import { twoFactorAuthRoutes } from 'features/2fa';

export const ROOT = '/';
export const NOT_FOUND = '/404';

const commonRoutes = () => [
  {
    path: ROOT,
    element: <CommonTemplate />,
    children: [
      ...clientsRoutes,
      ...operatorsRoutes,
      ...blockchainRoutes,
      ...settingsRoutes,
      ...cryptoCurrenciesRoutes,
      ...transactionsRoutes,
      ...conversionOrdersRoutes,
      ...twoFactorAuthRoutes,
      ...productsRoutes,
      ...disclosureRoutes,
      ...marketingRoutes,
      ...positionRoutes,
      {
        path: NOT_FOUND,
        element: <NotFoundPage />,
      },
    ],
  },
  ...authRoutes,
  {
    path: '*',
    element: <Navigate to={ROOT} />,
  },
];

export const rootRoutes = commonRoutes();
