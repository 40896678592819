import { connect } from 'react-redux';
import React, { Fragment, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';

import { actions } from '../symbiotes';
import { idTypesNationalsSelector } from '../selectors';
import { dictionariesApi } from '../api';

const mapStateToProps = state => ({
  idTypesNationals: idTypesNationalsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getIdTypeNationals: async () => {
    const res = await dispatch(dictionariesApi.getIdTypeNationals);
    dispatch(actions.saveIdTypesNationals(res));
  },
});

export const IdTypesNationalsItemsView = ({ getIdTypeNationals, idTypesNationals }) => {
  useEffect(() => {
    if (!idTypesNationals.length) {
      getIdTypeNationals();
    }
  }, [ getIdTypeNationals, idTypesNationals.length ]);
  return (
    <Fragment>
      <MenuItem value="">None</MenuItem>
      {idTypesNationals.map(a => (
        <MenuItem key={a.id} value={a.Name}>
          {a.Name}
        </MenuItem>
      ))}
    </Fragment>
  );
};

export const IdTypesNationalsItems = connect(mapStateToProps, mapDispatchToProps)(IdTypesNationalsItemsView);
