import { boolean, string } from 'yup';

export const flatBackendValidationErrors = (error) => {
  let errors = {};
  error.data.forEach((item) => {
    if (item.context.key) {
      errors[item.context.key] = item.message;
    } else {
      const field = item.path[1];
      errors[field] = item.message;
    }
  });
  return errors;
};

export const flatValidationErrors = (errors) => {
  let errorsFlat = {};
  for (let error of errors) {
    const field = error.path.split('.').pop();
    if (field && error.errors.length > 0) {
      errorsFlat[field] = error.errors[0];
    }
  }
  return errorsFlat;
};

export const flatArrayValidationErrors = (errors) => {
  let errorsFlat = {};
  for (let error of errors) {
    const field = error.path;
    if (field && error.errors.length > 0) {
      errorsFlat[field] = error.errors[0];
    }
  }
  return errorsFlat;
};

export const flatFullValidationErrors = (errors) => {
  let errorsFlat = {};
  for (let error of errors) {
    const field = error.path;
    if (field && error.errors.length > 0) {
      errorsFlat[field] = error.errors[0];
    }
  }
  return errorsFlat;
};

/**
 * Filter for onChange input
 * @param {string} value Input value
 * @param {RegExp} regExp RegExp for filter value string
 * @param {Function} cb Callback function when get result
 */
export const filterInput = (value, regExp, cb, ...args) => {
  let filtredValue = value;
  if (typeof value === 'object') {
    if (args) {
      cb(filtredValue, ...args);
    } else {
      cb(filtredValue);
    }
  } else if (typeof value === 'string') {
    filtredValue = value?.replace(regExp, '');
    if (args) {
      cb(filtredValue, ...args);
    } else {
      cb(filtredValue);
    }
  } else {
    if (args) {
      cb(filtredValue, ...args);
    } else {
      cb(filtredValue);
    }
  }
};
export const latinSymAndDigitsRegExp = /[^A-Za-z0-9]/g;
export const allowSymRegExp = /[^A-Za-z0-9\s!"#$%&'()*+,\-./:;\]\\[<=>?@^_`{|}~]|\t/g;
export const allowPrintableSymRegExp = /[^A-Za-z0-9!"#$%&'()*+,\-./:;\]\\[<=>?@^_`{|}~]/g;
export const allowNumberRegExp = /[^0-9\s^%@#$!?{}()|:,./\-+[]]*/g;
export const phoneRegExp = /^$|^\+?[\d\s()]{4,30}$/;
export const urlRegExp =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

/**
 * Repeating validators
 */
export const validators = {
  phone: string()
    .transform(v => (v === null ? '' : v))
    .matches(phoneRegExp, 'Phone must contain from 4 to 30 characters. Numbers, spaces, and parentheses are allowed.'),
  country: string().required('Country is a required field'),
  condition: boolean().oneOf([ true ], 'You have to accept the terms and conditions'),
  description: string().required('Description is a required field').max(3000, 'Description Name must be at most 3000 characters'),
};
