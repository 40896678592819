import { withStyles } from 'tss-react/mui';
import React from 'react';

const styles = theme => ({
  root: {
    display: 'table-row',
    fontSize: '15px',
  },
  title: {
    display: 'table-cell',
    padding: '5px 10px 5px 0',
    fontWeight: theme.typography.fontWeightBold,
    whiteSpace: 'nowrap',
  },
  value: {
    display: 'table-cell',
    padding: '5px 0 5px 10px',
    wordWrap: 'normal',
  },
});

const RowContainer = ({ classes, title, value, children }) => (
  <div className={classes.root}>
    <div className={classes.title}>{title}</div>
    <div className={classes.value}>
      {value} {children}
    </div>
  </div>
);

export const Row = withStyles(RowContainer, styles);
