import React from 'react';
import { FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';

export const SelectUI = ({
  className = '',
  availableValues,
  selectValue,
  onChange,
  label = '',
  connectionId,
  itemKey = false,
  itemValue = false,
  itemDisplay = false,
  renderField = false,
  validationErrors,
  errorField,
  emptyItemText = '',
  emptyItemValue = '',
  isFormHelperText = false,
  disabled = false,
  complexDisplay = false,
  itemDisabled = false,
}) => (
  <FormControl className={className} variant="standard">
    {label ? (
      <InputLabel shrink htmlFor={connectionId}>
        {label}
      </InputLabel>
    ) : null}
    <Select
      disabled={disabled}
      error={Boolean(validationErrors?.[errorField] ?? false)}
      name={connectionId}
      renderValue={selected => (renderField ? selected[renderField] : selected)}
      value={selectValue}
      variant="standard"
      onChange={onChange}>
      {emptyItemText ? <MenuItem value={emptyItemValue}>{emptyItemText}</MenuItem> : null}
      {availableValues?.map(item => (
        <MenuItem disabled={typeof itemDisabled === 'function'? itemDisabled(item) : itemDisabled} key={itemKey ? item[itemKey] : item} value={itemValue ? item[itemValue] : item}>
          <ListItemText primary={itemDisplay ? item[itemDisplay] : complexDisplay ? complexDisplay(item) : item} />
        </MenuItem>
      ))}
    </Select>
    {isFormHelperText ? (
      <FormHelperText error={true} id={connectionId}>
        {validationErrors[errorField]}
      </FormHelperText>
    ) : null}
  </FormControl>
);
