import { createFetching, initialFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

const initialState = {
  fetchOne: initialFetching,
  firstFetchTradingSetting: initialFetching,
  fetchTradingSetting: initialFetching,
  tradingSettings: [ { quoteSymbols: [ { quoteSymbol:{ Ticker: 'EUR' } } ] } ],
  tradingSetting: {},
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  firstFetchTradingSetting: createFetching('firstFetchTradingSetting'),
  fetchTradingSetting: createFetching('fetchTradingSetting'),
  setTradingSettings: (state, tradingSettings) => ({ ...state, tradingSettings }),
  setTradingSetting: (state, tradingSetting) => ({ ...state, tradingSetting }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'tradingSettings');
