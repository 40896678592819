import React, { Fragment } from 'react';
import { Grid, Typography } from '@mui/material';

export const ItemsCount = ({ length, children, twice = false }) => (
  <Fragment>
    <Grid item>
      <Typography variant="subtitle2">Items: {length}</Typography>
    </Grid>
    {children}
    {twice ? (
      <Grid item>
        <Typography variant="subtitle2">Items: {length}</Typography>
      </Grid>
    ) : null}
  </Fragment>
);
