import { connect } from 'react-redux';
import React, { Fragment, useEffect } from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

import { filterInput, allowSymRegExp } from 'lib';

import { actions } from '../symbiotes';
import { idTypesSelector } from '../selectors';
import { dictionariesApi } from '../api';

const mapStateToProps = state => ({
  idTypes: idTypesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getIdTypes: async (query) => {
    const res = await dispatch(dictionariesApi.getIdTypes, query);
    dispatch(actions.saveIdTypes(res));
  },
});

export const IdTypeFieldView = ({ idTypes, getIdTypes, country, readOnly, onChange, value, classes, stringIdType, changedFields }) => {
  useEffect(() => {
    if (!stringIdType) {
      getIdTypes(country);
    }
  }, [ getIdTypes, stringIdType, country ]);

  return (
    <FormControl className={classes.formControl} variant="standard">
      {stringIdType ? (
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          disabled={readOnly}
          id="type-id"
          label="ID Type"
          value={value ?? ''}
          variant="standard"
          onChange={e => filterInput(e.target.value, allowSymRegExp, onChange)} />
      ) : (
        <Fragment>
          <InputLabel shrink htmlFor="type-id">
            ID Type
          </InputLabel>
          <Select
            disabled={readOnly}
            id="type-id"
            label="ID Type"
            name="ID Type"
            value={value ?? ''}
            variant="standard"
            onChange={e => onChange(e.target.value)}>
            <MenuItem value="">None</MenuItem>
            {idTypes?.map(it => (
              <MenuItem key={it.id} value={it.Code}>
                {it.Code}
              </MenuItem>
            ))}
          </Select>
        </Fragment>
      )}
      {changedFields['IDType'] ? <FormHelperText className={classes.changed}>Changed</FormHelperText> : null}
    </FormControl>
  );
};

export const IdTypeField = connect(mapStateToProps, mapDispatchToProps)(IdTypeFieldView);
