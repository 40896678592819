import { createAuthorization, createDeviceId, request } from './request';

function serialize(query) {
  const qURI = Object.keys(query)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
    .join('&');

  return qURI ? `?${qURI}` : '';
}

export const get =
  (url, query = {}, options = {}) =>
    dispatch =>
      dispatch(request, 'GET', url + serialize(query), options);

export const post =
  (url, body, options = {}) =>
    dispatch =>
      dispatch(request, 'POST', url, { ...options, body });

export const put =
  (url, body, options = {}) =>
    dispatch =>
      dispatch(request, 'PUT', url, { ...options, body });

export const del =
  (url, body, options = {}) =>
    dispatch =>
      dispatch(request, 'DELETE', url, { ...options, body });

export const commonApi = {
  uploadFile: (data, accessToken, appDeviceId) => post('/files', data, { headers: {
    'Content-Disposition': 'form-data',
    ...createAuthorization(accessToken),
    ...createDeviceId(appDeviceId),
  } }),
  uploadUIFile: (data, accessToken, appDeviceId) => post('/files/ui', data, { headers: {
    'Content-Disposition': 'form-data',
    ...createAuthorization(accessToken),
    ...createDeviceId(appDeviceId),
  } }),
  // uploadFile: data => post('/files', data, { headers: { 'Content-Disposition': 'form-data' } }),
  // uploadUIFile: data => post('/files/ui', data, { headers: { 'Content-Disposition': 'form-data' } }),
  deleteFile: name => del(`/files/${name}`),
};
