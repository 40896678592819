import { withStyles } from 'tss-react/mui';
import { Outlet } from 'react-router-dom';
import React, { Suspense } from 'react';
import { Box, CssBaseline, Grid } from '@mui/material';

import { Spinner } from 'ui';
import { AccountLoader } from 'features/auth';

import { Header } from './header';
import { Footer } from './footer';

const styles = theme => ({
  content: {
    width: '90%',
    margin: '10px auto',
  },
});

const CommonTemplateView = ({ classes }) => (
  <Box sx={{ width: '100vw', height: '100vh', display:'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden', bgcolor: 'background.wallPaper' }}>
    <CssBaseline />
    <Header />
    <Box sx={{ flex: '0 1 100vh', overflow: 'auto' }}>
      <AccountLoader>
        <Grid container className={classes.content}>
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </Grid>
      </AccountLoader>
    </Box>
    <Footer blockchainStatus='OK'/>
  </Box>
);

export const CommonTemplate = withStyles(CommonTemplateView, styles);
