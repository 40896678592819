import { withStyles } from 'tss-react/mui';
import React from 'react';
import { Grid, Link } from '@mui/material';

const styles = (theme, { preText }) => ({
  link: {
    display: preText ? 'inline-block' : 'block',
  },
  linkOverflow: {
    width: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

const HyperlinkView = ({ classes, url, text, overflow = true, preText = false }) => (
  <Grid>
    {preText? <span>{preText}</span> : null}
    <Link className={classes.link} href={`${String(url)}`} target="_blank">
      <div className={overflow ? classes.linkOverflow : ''}>{text}</div>
    </Link>
  </Grid>

);

export const Hyperlink = withStyles(HyperlinkView, styles);
