export const DisclosureRoutes = {
  DISCLOSURE_ROOT: '/disclosure',
};

export const OfferingDataType = {
  OFFERINGS: 'offerings',
  DIRECT_SALES: 'directsales',
  STRUCTURED_PRODUCTS: 'structuredProducts',
};

export const ViewStatus = {
  ON_PLATFORM: 'On platform',
  CHECKED: 'Operator check',
  REJECTED: 'Rejected',
};
