import { Navigate } from 'react-router-dom';
import React from 'react';

import { SignInPage, ForgetPasswordPage, ResetPasswordPage, RegisterPage } from './pages';
import { AuthPaths } from './constants';

const { JOIN, FORGET, RESET_PASS, REGISTRATION, LOGOUT } = AuthPaths;

export const authRoutes = [
  {
    path: JOIN,
    element: <SignInPage />,
  },
  {
    path: FORGET,
    element: <ForgetPasswordPage />,
  },
  {
    path: RESET_PASS,
    element: <ResetPasswordPage />,
  },
  {
    path: REGISTRATION,
    element: <RegisterPage />,
  },
  {
    path: LOGOUT,
    element: () => <Navigate to={JOIN} />,
  },
];
