import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  fetchReport: initialFetching,
  fetchTransactionReport: initialFetching,
  fetchClientsReport: initialFetching,
  fetchReportOrderDS: initialFetching,
  fetchReportTradesDS: initialFetching,
  fetchReportBarsDS: initialFetching,
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchReport: createFetching('fetchReport'),
  fetchTransactionReport: createFetching('fetchTransactionReport'),
  fetchClientsReport: createFetching('fetchClientsReport'),
  fetchReportOrderDS: createFetching('fetchReportOrderDS'),
  fetchReportTradesDS: createFetching('fetchReportTradesDS'),
  fetchReportBarsDS: createFetching('fetchReportBarsDS'),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'report');
