import React from 'react';

import { ConversionRoutes } from './models';

const ConversionOrdersPage = React.lazy(() => import('./pages/conversion-orders-page'));
const { CONVERSION_ROOT } = ConversionRoutes;
export const conversionOrdersRoutes = [
  {
    path: CONVERSION_ROOT,
    element: <ConversionOrdersPage />,
  },
];
