import React from 'react';

import { tradingPairsRoutes } from './tradings-settings/routes';
import { tokenRoutes } from './token/features/token-offering';
import { structuredProductsRoutes } from './structured-products';
import { ProductsRoutes } from './models';
import { directSaleRoutes } from './direct-sale';

const ProductsPage = React.lazy(() => import('./pages/products-page'));

const { PRODUCTS_ROOT } = ProductsRoutes;

export const productsRoutes = [
  {
    path: PRODUCTS_ROOT,
    element: <ProductsPage />,
    children: [
      ...directSaleRoutes,
      ...structuredProductsRoutes,
      ...tokenRoutes,
      ...tradingPairsRoutes,
    ],
  },
];
