import { BCSStatus } from 'features/blockchain/consts';

const { CONFIRMED, QUEUED } = BCSStatus;

export const isNotObj = data => data && !isEmpty(data) && typeof data !== 'object';
export const isObj = data => data && typeof data === 'object';

export function isEmpty(obj) {
  for (let key in obj) {
    if (Object.hasOwn(obj, key)) return false;
  }
  return true;
}

export const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const errorParser = (data, status) => {
  try {
    if (data && IsJsonString(data)) {
      let result = JSON.parse(data);
      let error = result?.error || result?.status || result?.message;
      if (isNotObj(error)) {
        return error;
      } else if (isObj(error)) {
        return data;
      } else {
        return '';
      }
    } else {
      if (data && Object.keys(data).length === 0) {
        return '';
      }
      if (status === CONFIRMED) {
        return '';
      }
      if (status === QUEUED) {
        return '';
      }
      if (data && Object.keys(data).length > 0) {
        if (isNotObj(data?.message)) {
          return data.message;
        } else if (isObj(data.message)) {
          return JSON.stringify(data.message);
        }
        return JSON.stringify(data);
      }

      return data;
    }
  } catch (e) {
    console.error('Error parse', e);
  }
};
