import { createFetching, initialFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

const initialState = {
  fetchOne: initialFetching,
  authenticated: false,
  tokens: [],
};

const symbiotes = {
  fetchOne: createFetching('fetchOne'),
  auth: {
    set: (state, data) => ({ ...state, ...data }),
    reset: () => ({}),
  },
  setTokens: (state, data) => ({ ...state, tokens: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiotes, 'auth');
