export function fileDownload(data, name, ext) {
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = data.url;
  link.download = `${name}.${ext}`;
  link.click();
  document.body.removeChild(link);
}

export function downloadStringToFile(text, fileType, fileName) {
  let blob = new Blob([ text ], { type: fileType });

  let a = document.createElement('a');
  a.download = `${fileName}.${fileType}`;
  a.href = URL.createObjectURL(blob);
  a.dataset.downloadurl = [ fileType, a.download, a.href ].join(':');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(function () {
    URL.revokeObjectURL(a.href);
  }, 1500);
}
