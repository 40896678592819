import { api } from 'features/common';

export const reportsApi = {
  getClientsReport: data => api.get('/admin/clients/report', data),
  getBookbuildingReport: (id, query) => api.get(`/admin/bookbuildings/${id}/report`, query),
  getAccountStatementReport: data => api.get('/admin/accounts/report', data),
  getHistoryStatuses: () => api.get('/admin/positions/history-dates/'),
  getReportOrderDS: query => api.get('/admin/direct-sale/order/export', query),
  getReportTradesDS: (id, query) => api.get(`/admin/trades/${id}/export`, query),
  getReportBarsDS: query => api.get('/admin/gold-bar/registry/report', query),
  getTransactionsReport: query => api.get('/admin/transactions/report', query),
};
