import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialOffer = {
  id: null,
  orders: [],
};

export const initialState = {
  fetchOne: initialFetching,
  fetchPosition: initialFetching,
  fetchCheckPriceOffer: initialFetching,
  fetchCheckPriceOfferCreated: initialFetching,
  offer: initialOffer,
  offers: [],
  offersFilter: {},
  priceOffer: {},
  availPropOfferSymbols: [],
  availPropBcSymbols: [],
  pricePropOffer: {},
  availToCreateOffers: {},
  propActionCheck: {},
  liquidityCheck: {},
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchPosition: createFetching('fetchPosition'),
  fetchCheckPriceOffer: createFetching('fetchCheckPriceOffer'),
  fetchCheckPriceOfferCreated: createFetching('fetchCheckPriceOfferCreated'),
  setOffer: (state, data) => ({ ...state, offer: data }),
  setOffers: (state, data) => ({ ...state, offers: data }),
  setOffersFilter: (state, data) => ({ ...state, offersFilter: data }),
  setPriceOffer: (state, data) => ({ ...state, priceOffer: data }),
  setAvailPropOfferSymbols: (state, data) => ({ ...state, availPropOfferSymbols: data }),
  setAvailPropBcSymbols: (state, data) => ({ ...state, availPropBcSymbols: data }),
  setPricePropOffer: (state, data) => {
    let newArray = {};
    newArray[data?.direction] = { ...data };
    return { ...state, pricePropOffer: { ...state.pricePropOffer, ...newArray } };
  },
  setAvailToCreateOffers: (state, data) => ({ ...state, availToCreateOffers: data }),
  setPropActionCheck: (state, data) => ({ ...state, propActionCheck: data }),
  setLiquidityCheck: (state, data) => ({ ...state, liquidityCheck: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'offers');
