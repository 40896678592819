import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  fetchRecalculatePositions: initialFetching,
  fetchRecalculatePositionsStatus: initialFetching,
  clients: [],
  deletedClients: [],
  client: null,
  clientBalance: {},
  accessOfferings: [],
  clientsAccessOffering: [],
  recalculatePositionsStatus: { status: 'ok' },
  clientTerms: [],
  expireDate: null,
  copiedDetails: null,
  activeClientsFilter: {},
  deletedClientsFilter: {},
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchRecalculatePositions: createFetching('fetchRecalculatePositions'),
  fetchRecalculatePositionsStatus: createFetching('fetchRecalculatePositionsStatus'),
  setClients: (state, data) => ({ ...state, clients: data }),
  setDeletedClients: (state, data) => ({ ...state, deletedClients: data }),
  setClient: (state, data) => ({ ...state, client: data }),
  setClientBalance: (state, data) => ({ ...state, clientBalance: data }),
  setClientsAccessOffering: (state, data) => ({
    ...state,
    clientsAccessOffering: data,
  }),
  setAccessOfferings: (state, data) => ({ ...state, accessOfferings: data }),
  setAccessOffering: (state, data) => ({
    ...state,
    accessOfferings: state.accessOfferings.map((el) => {
      if (el.TokenOfferingId === data.TokenOfferingId) {
        let el = { ...data };
        return el;
      }
      return el;
    }),
  }),
  setRecalculatePositionsStatus: (state, data) => ({ ...state, recalculatePositionsStatus: data }),
  setClientTerms: (state, data) => ({ ...state, clientTerms: data }),
  setExpireDate: (state, data) => ({ ...state, expireDate: data }),
  setCopiedDetails: (state, data) => ({ ...state, copiedDetails: data }),
  setActiveClientsFilter: (state, data) =>({ ...state, activeClientsFilter: data }),
  setDeletedClientsFilter: (state, data) =>({ ...state, deletedClientsFilter: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'clients');
