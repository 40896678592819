import React, { Fragment } from 'react';

import { TransactionsReportForm } from './transactions-report-form';
import { AccountsDateReportForm } from './accounts-date-report-form';

const Report = {
  allTransactions: props => <TransactionsReportForm {...props} />,
  accountsStatement: props => <AccountsDateReportForm {...props} />,
  accountStatement: props => <AccountsDateReportForm {...props} />,
  clientAccountStatement: props => <AccountsDateReportForm {...props} />,
};
export const ReportForm = ({ reportName, filter, usedFilters, title, handleClose, open, type, ...props }) => (
  <Fragment>{reportName && Report[reportName]({ ...{ reportName, usedFilters, filter, title, handleClose, open, type }, ...props })}</Fragment>
);
