import { createFetching, initialFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

const initialState = {
  fetchOne: initialFetching,
  fetchRateRevaluationType: initialFetching,
  fetchVnxluRevaluationType: initialFetching,
  fetchCheckDeleteGoldBarType: initialFetching,
  fetchUploadCalendar: initialFetching,
  settings: [],
  settingsForCompare: [],
  revaluationType: [],
  fiatRevaluationTypes: [],
  calcRevaluationSourceTypes: [],
  vnxluRevaluationType: [],
  rateRevaluationType: [],
  cryptoExchangeSource: [],
  cardAcquiringService: [],
  neutralSetting: { 'Bitrix':{ id: 0, Data: {} } },
  scoringSettings: [],
  amlProviders: [],
  currentProvider: {},
  spreadPairs: [],
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchRateRevaluationType: createFetching('fetchRateRevaluationType'),
  fetchVnxluRevaluationType: createFetching('fetchVnxluRevaluationType'),
  fetchCheckDeleteGoldBarType: createFetching('fetchCheckDeleteGoldBarType'),
  fetchUploadCalendar: createFetching('fetchUploadCalendar'),
  setSettings: (state, settings) => ({ ...state, settings }),
  setSettingsForCompare: (state, settingsForCompare) => ({ ...state, settingsForCompare }),
  setRevaluationType: (state, revaluationType) => ({ ...state, revaluationType: revaluationType }),
  setFiatRevaluationType: (state, fiatRevaluationTypes) => ({ ...state, fiatRevaluationTypes: fiatRevaluationTypes }),
  setCalcRevaluationSourceTypes: (state, calcRevaluationSourceTypes) => ({
    ...state,
    calcRevaluationSourceTypes: calcRevaluationSourceTypes,
  }),
  setVnxluRevaluationType: (state, vnxluRevaluationType) => ({ ...state, vnxluRevaluationType: vnxluRevaluationType }),
  setRateRevaluationType: (state, rateRevaluationType) => ({ ...state, rateRevaluationType: rateRevaluationType }),
  setCryptoExchangeSource: (state, cryptoExchangeSource) => ({ ...state, cryptoExchangeSource: cryptoExchangeSource }),
  setCardAcquiringService: (state, cardAcquiringService) => ({ ...state, cardAcquiringService: cardAcquiringService }),
  setNeutralSetting: (state, neutralSetting) => ({ ...state, neutralSetting: neutralSetting }),
  setScoringSettings: (state, scoringSettings) => ({ ...state, scoringSettings: scoringSettings }),
  setAmlProviders: (state, amlProviders) => ({ ...state, amlProviders: amlProviders }),
  setCurrentProvider: (state, currentProvider) => ({ ...state, currentProvider: currentProvider }),
  setSpreadPairs: (state, spreadPairs) => ({ ...state, spreadPairs: spreadPairs }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'settings');
