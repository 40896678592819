import { withStyles } from 'tss-react/mui';
import React from 'react';
import { DialogContent, DialogActions, Button, } from '@mui/material';

import { Modal } from './modal';

const styles = theme => ({
  root: {
    width: '450px',
    marginTop: '10px',
    marginBottom: '20px',
  },
});

const ConfirmationView = ({ classes, show, title = 'Confirmation', children, handleSubmit, handleCancel, agreeText = 'Yes', disagreeText = 'No' }) => (
  <Modal open={show} title={title}>
    <DialogContent>
      <div className={classes.root}>{children}</div>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={handleSubmit}>
        {agreeText}
      </Button>
      <Button color="primary" onClick={handleCancel}>
        {disagreeText}
      </Button>
    </DialogActions>
  </Modal>
);

export const Confirmation = withStyles(ConfirmationView, styles);
