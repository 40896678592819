import React from 'react';

import { BlockchainRoutes, ListStatus } from './consts';

const BlockchainPage = React.lazy(() => import('./pages/blockchain-page'));
const StatusPage = React.lazy(() => import('./pages/status-page'));
const BooksTab = React.lazy(() => import('./components/books/books-tab'));
const BidsTab = React.lazy(() => import('./components/bids/bids-tab'));
const EmissionsTab = React.lazy(() => import('./components/emissions/emissions-tab'));
const TradesTab = React.lazy(() => import('./components/trades/trades-tab'));
const TransfersTab = React.lazy(() => import('./components/transfers/transfers-tab'));

const { BLOCKCHAINS_ROOT, BOOKS, BIDS, DEPLOYMENTS, TRADES, TRANSFERS } = BlockchainRoutes;
const { ALL, DECLINED } = ListStatus;

export const blockchainRoutes = [
  {
    path: BLOCKCHAINS_ROOT,
    element: <BlockchainPage />,
    children: [
      {
        path: BOOKS,
        element: <StatusPage />,
        children: [
          {
            path: ALL,
            element: <BooksTab mode={ALL} />,
          },
          {
            path: DECLINED,
            element: <BooksTab mode={DECLINED} />,
          },
        ],
      },
      {
        path: BIDS,
        element: <StatusPage />,
        children: [
          {
            path: ALL,
            element: <BidsTab mode={ALL} />,
          },
          {
            path: DECLINED,
            element: <BidsTab mode={DECLINED} />,
          },
        ],
      },
      {
        path: DEPLOYMENTS,
        element: <StatusPage />,
        children: [
          {
            path: ALL,
            element: <EmissionsTab mode={ALL} />,
          },
          {
            path: DECLINED,
            element: <EmissionsTab mode={DECLINED} />,
          },
        ],
      },
      {
        path: TRADES,
        element: <StatusPage />,
        children: [
          {
            path: ALL,
            element: <TradesTab mode={ALL} />,
          },
          {
            path: DECLINED,
            element: <TradesTab mode={DECLINED} />,
          },
        ],
      },
      {
        path: TRANSFERS,
        element: <StatusPage />,
        children: [
          {
            path: ALL,
            element: <TransfersTab mode={ALL} />,
          },
          {
            path: DECLINED,
            element: <TransfersTab mode={DECLINED} />,
          },
        ],
      },
    ],
  },
];
