import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

import { DirectSaleStatus } from '../../models';

export const initialDirectSale = {
  status: DirectSaleStatus.IDLE,
  offers: [],
};

export const initialState = {
  fetchOne: initialFetching,
  fetchCurrencies: initialFetching,
  fetchEmitDirectSaleDGR: initialFetching,
  fetchBurnDirectSaleDGR: initialFetching,
  fetchAddLiquidity: initialFetching,
  fetchTakeLiquidity: initialFetching,
  fetchAvailableFundsClient: initialFetching,
  directSales: [],
  directSalesFilter: {},
  directSale: initialDirectSale,
  blockChains: [],
  dcrBlockchains: [],
  issuers: [],
  symbols: [],
  allSymbols: [],
  availableFundsClient: {},
  frtTickers: [],
  frtDsTickers: [],
  frtRate: null,
  frtEmit: {},
  frtAmount: null,
  frtSymbol: null,
  frtClients: [],
  dsBurnClients: [],
  availQuantity: null,
  clientsBurn: [],
  availDgrBurn: [],
  availBlockchainsBurn: [],
  frtBurn: {},
  availAssetsBurn: [],
  deliveryAvail: false,
  availGoldBars: [],
  deliveryBlockchains: [],
  checkDelivery: {},
  checkBurn: {},
  takeLiquidityClients: [],
  addLiquidityClients: [],
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchCurrencies: createFetching('fetchCurrencies'),
  fetchEmitDirectSaleDGR: createFetching('fetchEmitDirectSaleDGR'),
  fetchBurnDirectSaleDGR: createFetching('fetchBurnDirectSaleDGR'),
  fetchAddLiquidity: createFetching('fetchAddLiquidity'),
  fetchTakeLiquidity: createFetching('fetchTakeLiquidity'),
  fetchAvailableFundsClient: createFetching('fetchAvailableFundsClient'),
  setDirectSales: (state, data) => ({ ...state, directSales: data }),
  setDirectSalesFilter: (state, data) => ({ ...state, directSalesFilter: data }),
  setDirectSale: (state, data) => ({ ...state, directSale: data }),
  setBlockChains: (state, data) => ({ ...state, blockChains: data }),
  setDcrBlockchains: (state, data) => ({ ...state, dcrBlockchains: data }),
  setIssuers: (state, data) => ({ ...state, issuers: data }),
  setSymbols: (state, data) => ({ ...state, symbols: data }),
  setAllSymbols: (state, data) => ({ ...state, allSymbols: data }),
  setAvailableFundsClient: (state, data) => ({ ...state, availableFundsClient: data }),
  setFrtTickers: (state, data) => ({ ...state, frtTickers: data }),
  setFrtDsTickers: (state, data) => ({ ...state, frtDsTickers: data }),
  setFrtRate: (state, data) => ({ ...state, frtRate: data }),
  setFrtEmit: (state, data) => ({ ...state, frtEmit: data }),
  setAmountEmit: (state, data) => ({ ...state, frtAmount: data }),
  setFrtSymbol: (state, data) => ({ ...state, frtSymbol: data }),
  setFrtClients: (state, data) => ({ ...state, frtClients: data }),
  setAvailQuantity: (state, data) => ({ ...state, availQuantity: data }),
  setClientsBurn: (state, data) => ({ ...state, clientsBurn: data }),
  setAvailDgrBurn: (state, data) => ({ ...state, availDgrBurn: data }),
  setAvailBlockchainsBurn: (state, data) => ({ ...state, availBlockchainsBurn: data }),
  setFrtBurn: (state, data) => ({ ...state, frtBurn: data }),
  setAvailAssetsBurn: (state, data) => ({ ...state, availAssetsBurn: data }),
  setDeliveryAvail: (state, data) => ({ ...state, deliveryAvail: data }),
  setDsBurnClients: (state, data) => ({ ...state, dsBurnClients: data }),
  setAvailGoldBars: (state, data) => ({ ...state, availGoldBars: data }),
  setDeliveryBlockchains: (state, data) => ({ ...state, deliveryBlockchains: data }),
  setCheckDelivery: (state, data) => ({ ...state, checkDelivery: data }),
  setCheckBurn: (state, data) => ({ ...state, checkBurn: data }),
  setTakeLiquidityClients: (state, data) => ({ ...state, takeLiquidityClients: data }),
  setAddLiquidityClients: (state, data) => ({ ...state, addLiquidityClients: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'directSale');
