import { withStyles } from 'tss-react/mui';
import React from 'react';
import { FormControl, MenuItem, InputLabel, Select, } from '@mui/material';

const styles = theme => ({
  select: {
    minWidth: '100px',
    maxWidth: '220px',
  },
  filterItem: {
    '& + .MuiFormControl-root': {
      marginLeft: 15,
    },
  },
});

export const InstantPaymentView = ({ classes, value, onChange, disabled, label, name }) => {

  const instantPayments = [ { label: 'Yes', value: true } ];

  return (
    <FormControl className={classes.filterItem} variant="standard">
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        className={classes.select}
        disabled={disabled}
        name={name}
        value={value}
        variant="standard"
        onChange={onChange}>
        <MenuItem value="">
          None
        </MenuItem>
        {instantPayments.map(item => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const InstantPayment = withStyles(InstantPaymentView, styles);
