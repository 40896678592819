import { withStyles } from 'tss-react/mui';
import { NavLink, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Typography, Toolbar, Button, Grid } from '@mui/material';

import { AuthPaths } from 'features/auth/constants';

import { HeaderItems } from '../models';
import { logout } from '../../auth/effects';

const styles = () => ({
  navlink: {
    textDecoration: 'none',
    color: '#fff',
    marginRight: '15px',
  },
  activeLink: {
    color: '#E10050',
  },
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout),
});

function HeaderView({ classes, logout }) {

  const navigate = useNavigate();
  const exit = async () => {
    await logout();
    navigate(AuthPaths.JOIN);
  };
  const isActiveClass = isActive => (isActive ? `${classes.navlink} ${classes.activeLink}` : classes.navlink);
  return (
    <AppBar position="static" >
      <Toolbar >
        <Grid container>
          {HeaderItems.map(item => (
            <Typography color="inherit" key={item.title} variant="h6">
              <NavLink className={({ isActive }) => isActiveClass(isActive)} to={item.link}>
                {item.title}
              </NavLink>
            </Typography>
          ))}
        </Grid>
        <Typography color="inherit" variant="h6">
          <Button className={`${classes.navlink} logout`} onClick={exit}>
            Logout
          </Button>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

HeaderView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const Header = connect(null, mapDispatchToProps)(withStyles(HeaderView, styles));
