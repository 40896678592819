import { TRADING_ROOT } from './tradings-settings/models';
import { AuctionsRoutes } from './token/features/token-offering/models';
import { StructuredProductsRoutes } from './structured-products/models';
import { DsRoutes } from './direct-sale/models';

export const ProductsRoutes = {
  PRODUCTS_ROOT: '/products',
};

export const ProductsTabs = {
  ds: { name: 'Direct Sales', index: 0, route: DsRoutes.DS_TABLE_ROOT },
  sp: { name: 'Structured Products', index: 1, route: StructuredProductsRoutes.SP_TABLE_ROOT },
  auctions: { name: 'Auctions', index: 2, route: AuctionsRoutes.AUCTION_ROOT },
  trading: { name: 'Trading Pairs', index: 3, route: TRADING_ROOT },
};
