import { connect } from 'react-redux';
import React, { Fragment, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';

import { actions } from '../symbiotes';
import { idTypesKYCsSelector } from '../selectors';
import { dictionariesApi } from '../api';

const mapStateToProps = state => ({
  idTypesKYCs: idTypesKYCsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getIdTypesKycs: async () => {
    const res = await dispatch(dictionariesApi.getIdTypesKycs);
    dispatch(actions.saveIdTypesKYCs(res));
  },
});

export const IdTypesKYCsItemsView = ({ idTypesKYCs, getIdTypesKycs }) => {
  useEffect(() => {
    if (!idTypesKYCs.length) {
      getIdTypesKycs();
    }
  }, [ getIdTypesKycs, idTypesKYCs.length ]);

  return (
    <Fragment>
      <MenuItem value="">None</MenuItem>
      {idTypesKYCs.map(a => (
        <MenuItem key={a.id} value={a.Name}>
          {a.Name}
        </MenuItem>
      ))}
    </Fragment>
  );
};
export const IdTypesKYCsItems = connect(mapStateToProps, mapDispatchToProps)(IdTypesKYCsItemsView);
