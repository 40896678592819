export const UsersType = {
  Client: 'client',
  Operator: 'operator',
  SuperOperator: 'super operator',
};

export const UsersTokenLabels = [ [ 'SessionId', 'Device' ], [ 'LastAccess', 'Last Access' ], [ 'createdAt', 'Creation Date' ] ];

export const OperatorsRoutes = {
  OPERATORS_ROOT: '/operators',
  LIST: 'list',
  ACTIONS: 'actions',
};
export const OperatorTabs = {
  operators: { name: 'Operators', index: 0, route: OperatorsRoutes.LIST },
  actions: { name: 'Actions History', index: 1, route: OperatorsRoutes.ACTIONS },
};
export const CapacityType = {
  Client: 'Client',
  Own: 'Own',
};
