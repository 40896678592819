export const TRN_NEW = 'new';
export const TRN_PAID = 'paid';
export const TRN_CANCELED = 'canceled';

export const TRN_TYPE_DEPOSIT = 'Deposit';
export const TRN_TYPE_TRANSFER = 'Transfer';
export const TRN_TYPE_DCREXREPORT = 'DCR trade';
export const TRN_TYPE_VNXLUEXREPORT = 'VNX trade';
export const TRN_TYPE_CONVERSION = 'Conversion';
export const TRN_TYPE_REFUND = 'Refund';
export const TRN_TYPE_FEE = 'Fee';
export const TRN_TYPE_BONUS = 'Bonus';
export const TRN_TYPE_TRADECANCELATION = 'Trade cancelation';
export const TRN_TYPE_DSCONVERSION = 'DS Conversion';
export const TRN_TYPE_TRADECORRECTION = 'Correction';
export const TRN_TYPE_NETWORKFEE = 'Network Fee';
export const TRN_TYPE_PURCHASE = 'Purchase';
export const TRN_TYPE_SALE = 'Sale';
export const TRN_TYPE_COMMODITIES_MINT = 'Commodities mint';
export const TRN_TYPE_COMMODITIES_BURN = 'Commodities burn';
export const TRN_TYPE_PHYSICAL_DELIVERY = 'Physical delivery';
export const TRN_TYPE_FRT_MINT = 'FRT mint';
export const TRN_TYPE_FRT_BURN = 'FRT burn';

export const TRN_STATUS_COMPLETED = 'completed';
export const TRN_STATUS_PENDING_IN = 'pending_in';
export const TRN_STATUS_PENDING_OUT = 'pending_out';
export const TRN_STATUS_REFUND_PENDING = 'refund_pending';
export const TRN_STATUS_DECLINED = 'declined';
export const TRN_STATUS_REJECTED = 'rejected';
export const TRN_STATUS_CANCELED = 'canceled';
export const TRN_ASSET_DCR = 'DCR';
export const TRN_ASSET_DGR = 'DGR';
export const TRN_ASSET_VNX = 'VNX';
export const TRN_ASSET_CURRENCY = 'Currency';
export const TRN_ASSET_CRYPTO = 'Crypto';
export const TRN_ASSET_ALTCOIN = 'AltCoin';
export const TRN_ASSET_FRT = 'FRT';

export const TRN_ACTION_ACCEPT = 'accept';
export const TRN_ACTION_COMPLETE = 'complete';
export const TRN_ACTION_DECLINE = 'decline';
export const TRN_ACTION_DETAILS = 'details';
export const TRN_STATUS_CHECK = 'check_status';

export const DETAILS_MODE = 'details';
export const ACTIONS_MODE = 'actions';

export const TRN_STATUSES = {
  [TRN_STATUS_COMPLETED]: 'Completed',
  [TRN_STATUS_PENDING_IN]: 'Pending in',
  [TRN_STATUS_PENDING_OUT]: 'Pending out',
  [TRN_STATUS_REFUND_PENDING]: 'Refund pending',
  [TRN_STATUS_DECLINED]: 'Declined',
  [TRN_STATUS_REJECTED]: 'Rejected',
  [TRN_STATUS_CANCELED]: 'Canceled',
};

export const TRN_TYPE = {
  [TRN_TYPE_DEPOSIT]: TRN_TYPE_DEPOSIT,
  [TRN_TYPE_DCREXREPORT]: TRN_TYPE_DCREXREPORT,
  [TRN_TYPE_VNXLUEXREPORT]: TRN_TYPE_VNXLUEXREPORT,
  [TRN_TYPE_CONVERSION]: TRN_TYPE_CONVERSION,
  [TRN_TYPE_REFUND]: TRN_TYPE_REFUND,
  [TRN_TYPE_FEE]: TRN_TYPE_FEE,
  [TRN_TYPE_BONUS]: TRN_TYPE_BONUS,
  [TRN_TYPE_DSCONVERSION]: TRN_TYPE_DSCONVERSION,
  [TRN_TYPE_TRADECANCELATION]: TRN_TYPE_TRADECANCELATION,
  [TRN_TYPE_TRADECORRECTION]: TRN_TYPE_TRADECORRECTION,
};

export const TRN_ASSET = {
  [TRN_ASSET_DCR]: TRN_ASSET_DCR,
  [TRN_ASSET_DGR]: TRN_ASSET_DGR,
  [TRN_ASSET_VNX]: TRN_ASSET_VNX,
  [TRN_ASSET_CURRENCY]: TRN_ASSET_CURRENCY,
  [TRN_ASSET_CRYPTO]: TRN_ASSET_CRYPTO,
};

export const TransactionsRoutes = {
  TRANSACTIONS_ROOT: '/transactions',
};

export const FrtActionsType = {
  EMIT: 'emit',
  BURN: 'burn',
};

export const FrtActionsFromTrnTypes = {
  [TRN_TYPE_FRT_MINT]: FrtActionsType.EMIT,
  [TRN_TYPE_FRT_BURN]: FrtActionsType.BURN,
};

export const DgrActionsType = {
  EMIT: 'emit',
  BURN: 'burn',
  DELIVERY: 'delivery',
};

export const DgrActionsFromTrnTypes = {
  [TRN_TYPE_COMMODITIES_MINT]: DgrActionsType.EMIT,
  [TRN_TYPE_COMMODITIES_BURN]: DgrActionsType.BURN,
  [TRN_TYPE_PHYSICAL_DELIVERY]: DgrActionsType.DELIVERY,
};

export const rowsPerPageOptions = [ 10, 25, 100, 250, 500 ];
