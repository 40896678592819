import { createBreakpoints } from '@mui/system';
import { createTheme } from '@mui/material/styles';

import AvenirNextCyrRegular from './assets/fonts/AvenirNextCyr-Regular.woff';
import AvenirNextCyrMedium from './assets/fonts/AvenirNextCyr-Medium.woff';
import AvenirNextCyrDemi from './assets/fonts/AvenirNextCyr-Demi.woff';

const fontSize = 15;
const htmlFontSize = 16;
const coef = fontSize / 14;
const pxToRem = size => `${(size / htmlFontSize) * coef}rem`;

const customBreakpointValues = {
  values: {
    xs: 400,
    xx: 500,
    sm: 600,
    ssm: 700,
    md: 900,
    lg: 1200,
    llg: 1390,
    xl: 1536,
    xxl: 1920,
    xxl2: 2240,
    xxxl: 2560,
    xxxl2: 2816,
    xxxll: 3072,
    xxxlll: 3440,
    xxx4k: 3840,
  },
  keys: [ 'xs', 'xx', 'sm', 'ssm', 'md', 'lg', 'llg', 'xl', 'xxl', 'xxl2', 'xxxl', 'xxxl2', 'xxxll', 'xxxlll', 'xxx4k' ],
};

const breakpoints = createBreakpoints({ ...customBreakpointValues });

export const theme = createTheme({
  breakpoints: {
    ...breakpoints,
  },
  typography: {
    fontFamily: 'Avenir Next Cyr',
    fontSize: 15,
    htmlFontSize: 16,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    useNextVariants: true,
  },
  palette: {
    success: {
      main: '#4caf50',
    },
    error: {
      main: '#ff0000',
      dark: '#d32f2f',
    },
    primary: {
      main: '#3f51b5',
      dark: '#303f9f',
    },
    secondary: {
      main: '#f50057',
      dark: '#c51162',
    },
    info: {
      main: '#e0e0e0',
      dark: '#d5d5d5',
      contrastText: '#000',
    },
    background: {
      wallPaper: '#ddd',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: 'Avenir Next Cyr';
            src: local('Avenir Next Cyr Regular'), local('AvenirNextCyr-Regular'),
              url(${AvenirNextCyrRegular}) format('woff');
            font-style: 'normal';
            font-weight: 400;
          }

          @font-face {
            font-family: 'Avenir Next Cyr';
            src: local('Avenir Next Cyr Medium'), local('AvenirNextCyr-Medium'),
              url(${AvenirNextCyrMedium}) format('woff');
            font-style: 'normal';
            font-weight: 500;
          }

          @font-face {
            font-family: 'Avenir Next Cyr';
            src: local('Avenir Next Cyr Demi'), local('AvenirNextCyr-Demi'),
              url(${AvenirNextCyrDemi}) format('woff');
            font-style: 'normal';
            font-weight: 600;
          }

      `,
    },
    MuiTypography:{
      styleOverrides: {
        root: {
          '&.head' :{
            marginTop: '10px',
            // marginBottom: '10px',
            fontSize: '22px',
            fontWeight: 600,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.table' : {
            width: '100%',
            marginTop: '10px',
            marginBottom: '10px',
            overflowY: 'auto',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          padding: '6px 8px',
          color: '#3f51b5',
          '&:hover': {
            backgroundColor: 'rgba(63, 81, 181, 0.04)',
          },
        },

        root: {
          fontFamily: 'Avenir Next Cyr',
          position: 'relative',
          width: 'auto',
          border: 'none',
          fontSize: 15,
          borderRadius: '4px',
          fontWeight: 500,
          lineHeight: 1.75,
          textTransform: 'uppercase',
          padding: '6px 16px',
          whiteSpace: 'nowrap',
          '&.logout': {
            padding: '6px 8px',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'Avenir Next Cyr',
          textTransform: 'uppercase',
          fontSize: 15,
          fontWeight: 500,
          lineHeight: 1.75,
          maxWidth: '264px',
          minHeight: 0,
          position: 'relative',
          height: '48px',
          padding: '6px 12px',
          color: '#fff',
          opacity: 0.7,
          '&:disabled': {
            opacity: 0.5,
            cursor: 'default',
            color: '#fff',
          },
          '&.Mui-selected': {
            opacity: 1,
            color: '#fff',
          },
          '@media screen and (min-width: 600px) ': {
            minWidth: '160px',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        spacer: {
          flex: '0',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px 56px 4px 24px',
          '&:last-child': {
            paddingRight: 24,
          },
        },
        head: {
          textAlign: 'center',
          padding: '4px 12px',
          backgroundColor: '#fff',
          position: 'sticky',
          top: 0,
          zIndex: '1',
          textTransform: 'uppercase',
          fontSize: pxToRem(12),
          lineHeight: 'inherit',
          color: 'rgba(0, 0, 0, 0.54)',
          fontWeight: 500,
          '&:hover': {
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },
        body: {
          textAlign: 'center',
          padding: '4px 28px 4px 12px',
          fontSize: pxToRem(13),
        },
        footer: {
          fontSize: pxToRem(12),
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Avenir Next Cyr',
          color: 'rgba(0, 0, 0, 0.54)',
          fontWeight: 500,
          '&:hover': {
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },
        icon: {
          height: 16,
          width: 16,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: 48,
        },
        head: {
          height: 56,
        },
        footer: {
          height: 56,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: '#3f51b5',
          },
          '&.Mui-disabled': {
            color: '#BDBDBD',
          },
          '&.Mui-checked.Mui-disabled': {
            color: '#CEF2FF',
          },
        },
        track: {
          '.Mui-checked.Mui-checked + &': {
            opacity: 0.5,
            backgroundColor: '#3f51b5',
          },
          '.Mui-checked.Mui-disabled + &': {
            opacity: 0.3,
            backgroundColor: '#3f51b5',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '8px 24px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '&.dialog': {
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            padding: '5px 0 5px 15px',
            justifyContent: 'space-between',
          },
        },
      },
    },
  },
});
