import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  fetchNotification: initialFetching,
  fetchConfig: initialFetching,
  notifications: [],
  recipients: [],
  config: {},
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchNotification: createFetching('fetchNotification'),
  fetchConfig: createFetching('fetchConfig'),
  setNotifications: (state, data) => ({ ...state, notifications: data }),
  setRecipients: (state, data) => ({ ...state, recipients: data }),
  setConfig: (state, data) => ({ ...state, config: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'notifications');
