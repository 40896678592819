import React from 'react';
import { Grid, Typography, Switch as MaterialSwitch } from '@mui/material';

export const Switch = ({ classes, checked, onChange, readOnly, children }) => (
  <Grid container>
    <Typography className={classes.title} variant="inherit">
      {children}
    </Typography>
    <MaterialSwitch
      checked={checked}
      color="primary"
      disabled={readOnly}
      onChange={() => {
        onChange(!checked);
      }}
    />
  </Grid>
);
