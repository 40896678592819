export const BookBuildingStatus = {
  IDLE: 'idle',
  ROADSHOW: 'roadshow',
  OPEN: 'open',
  CLOSED: 'closed',
  SUSPENDED: 'suspended',
  CANCELED: 'canceled',
  AUCTION_COMPLETED: 'auctionCompleted',
  SETTLEMENT_PENDING: 'settlementPending',
  SETTLEMENT_COMPLETED: 'settlementCompleted',
  QUEUE: 'queue',
  FAILED: 'failed',
};

export const TransactionStatus = {
  TransactionCreated: 'created',
  TransactionWritten: 'written',
};

export const PossibleStatus = {
  CONFIRMED: 'confirmed',
  AWAITING: 'awaiting',
  QUEUED: 'queued',
  FAILED: 'failed',
  NEED_DEPLOY: 'need_deploy',
  NEED_TRANSFER: 'need_transfer',
};
