import { TransactionsRoutes } from 'features/transactions/consts';
import { SettingsRoutes } from 'features/settings/models';
import { ProductsRoutes } from 'features/products/models';
import { PositionRoutes } from 'features/positions/models';
import { MarketingProgramsRoutes } from 'features/marketing-programs/models';
import { OperatorsRoutes } from 'features/manage-users/models';
import { DisclosureRoutes } from 'features/disclosure/models';
import { RatesRoutes } from 'features/cryptocurrencies/models';
import { ConversionRoutes } from 'features/conversion-orders/models';
import { ClientRoutes } from 'features/clients/models';
import { BlockchainRoutes } from 'features/blockchain/consts';

export const Role = {
  ISSUER: 'issuer',
  INVESTOR: 'investor',
  BROKER: 'broker',
};

export const ClientType = {
  INDIVIDUAL: 'individual',
  LEGAL: 'legal',
  BROKER: 'broker',
};

export const BackendRole = {
  issuer: 'issuer',
  marketParticipant: 'investor',
  marketOperator: 'operator',
  broker: 'broker',
};
export const KeyboardEventKey = { ENTER: 'Enter', ESC: 'Escape' };
export const Direction = { BUY: 'buy', SELL: 'sell' };
export const TransactionDirection = { BUY: 'Buy', SELL: 'Sell' };
export const ErrorType = {
  BACK_VALID_ERR: 'validation_error',
  FRONT_VALID_ERR: 'ValidationError',
  CREATE_OFFER_ERR: 'error_create_offer',
  CREATE_OFFERING_ERR: 'error_create_offering',
  CREATE_TRADE_ERR: 'error_create_trade',
  JWT_EXPIRED: 'jwt expired',
  REFRESH_JWT_EXPIRED: 'refresh token. jwt expired',
  TOKEN_NOT_REGISTRED: 'token not registred',
  TOKEN_RECALLED_OR_DISABLED: 'token recalled or disabled',
};
export const BlockchainSymbol = { Q: 'Q', ETH: 'ETH', BTC: 'BTC' };
export const AssetSymbol = { EUR: 'EUR', CHF: 'CHF', BTC: 'BTC', ETH: 'ETH', VNXLU: 'VNXLU', USDC: 'USDC' };
export const ActivityStatus = { ACTIVE: 'Active', INACTIVE: 'Inactive' };
export const ClientDocumentType = { PASSPORT: 'PASSPORT', ID_CARD: 'ID_CARD', DRIVERS: 'DRIVERS' };

export const HeaderItems = [
  {
    title: 'Clients',
    link: ClientRoutes.CLIENTS_ROOT,
  },
  {
    title: 'Operators',
    link: OperatorsRoutes.OPERATORS_ROOT,
  },
  {
    title: 'Products',
    link: ProductsRoutes.PRODUCTS_ROOT,
  },
  {
    title: 'Positions',
    link: PositionRoutes.POSITION_ROOT,
  },
  {
    title: 'Transactions',
    link: TransactionsRoutes.TRANSACTIONS_ROOT,
  },
  {
    title: 'Blockchains',
    link: BlockchainRoutes.BLOCKCHAINS_ROOT,
  },
  {
    title: 'Settings',
    link: SettingsRoutes.SETTINGS_ROOT,
  },
  {
    title: 'Revaluation Rates',
    link: RatesRoutes.RATES_ROOT,
  },
  {
    title: 'Conversion Orders',
    link: ConversionRoutes.CONVERSION_ROOT,
  },
  {
    title: 'Disclosure',
    link: DisclosureRoutes.DISCLOSURE_ROOT,
  },
  {
    title: 'Discount Programs',
    link: MarketingProgramsRoutes.MARKETING_PROGRAMS_ROOT,
  },
];

export const FilesType = {
  'application/pdf': {
    description: 'PDF',
    accept: {
      'application/pdf': '.pdf',
    },
  },
  'text/plain': {
    description: 'Text',
    accept: {
      'text/plain': '.txt',
    },
  },
  'image/jpeg': {
    description: 'Image',
    accept: {
      'image/jpeg': '.jpeg',
    },
  },
  'image/png': {
    description: 'Image',
    accept: {
      'image/jpeg': '.png',
    },
  },
  'image/svg+xml': {
    description: 'Image',
    accept: {
      'image/jpeg': '.svg',
    },
  },
};
