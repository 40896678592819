import { NumericFormat } from 'react-number-format';
import React from 'react';

export const NumberFormatFloat = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      allowNegative={false}
      decimalScale="2"
      decimalSeparator=","
      getInputRef={inputRef}
      thousandSeparator="."
      valueIsNumericString={true}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
};

export const NumberFormatFloatNegative = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      allowNegative={true}
      decimalScale="2"
      decimalSeparator=","
      getInputRef={inputRef}
      thousandSeparator="."
      valueIsNumericString={true}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
};

export const NumberFormatCrypto = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      allowNegative={false}
      decimalScale="5"
      decimalSeparator=","
      getInputRef={inputRef}
      thousandSeparator="."
      valueIsNumericString={true}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
};

export const NumberFormatCryptoNegative = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      allowNegative={true}
      decimalScale="5"
      decimalSeparator=","
      getInputRef={inputRef}
      thousandSeparator="."
      valueIsNumericString={true}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
};

export const NumberFormatInt = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      allowNegative={false}
      decimalScale="0"
      decimalSeparator=","
      getInputRef={inputRef}
      isAllowed={values => values.formattedValue.slice(-1) !== ','}
      thousandSeparator="."
      valueIsNumericString={true}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
};

export const NumberFormatGoldPrice = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      allowNegative={false}
      decimalScale="4"
      decimalSeparator=","
      getInputRef={inputRef}
      thousandSeparator="."
      valueIsNumericString={true}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
};

export const NumberFormatGoldPriceNegative = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      allowNegative={true}
      decimalScale="4"
      decimalSeparator=","
      getInputRef={inputRef}
      thousandSeparator="."
      valueIsNumericString={true}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
};
