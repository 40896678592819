import { createExecute } from 'redux-execute';
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { composeWithDevTools, composeWithDevToolsLogOnlyInProduction } from '@redux-devtools/extension';

import { createReducer } from './reducers';

const isProduction = process.env.REACT_APP_PLATFORM === 'production' || process.env.REACT_APP_PLATFORM === 'prod' || process.env.NODE_ENV === 'production';

const composeEnhancers = isProduction
  ? composeWithDevToolsLogOnlyInProduction({
    // options like actionSanitizer, stateSanitizer
  })
  : composeWithDevTools({
    // Specify name here, actionsDenylist, actionsCreators and other options if needed
  });

export function configureStore({ initialState = {} } = {}) {
  const middlewares = [ createExecute({ log: !isProduction }) ];

  const store = createStore(createReducer(), initialState, composeEnhancers(applyMiddleware(...middlewares)));

  return store;
}
