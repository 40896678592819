import { getDateTimeFormatFromString, convertNumber } from 'lib';
import { TransactionDirection } from 'features/common';

const { BUY, SELL } = TransactionDirection;

const quantity = (transaction) => {
  if (transaction.Quantity === 0) {
    return '0';
  } else if (transaction?.Direction === BUY) {
    return String('+' + convertNumber(transaction.Symbol.Type, transaction.Quantity));
  } else if (transaction?.Direction === SELL) {
    return String('-' + convertNumber(transaction.Symbol.Type, transaction.Quantity));
  } else return null;
};

export const mapTransaction = (transaction) => {
  let row = {
    isResend: false,
    isDecline: false,
    isAccept: false,
    description: transaction?.Description || '',
    transactionId: transaction?.id || 'null',
    offerId: transaction?.OfferId,
    date: getDateTimeFormatFromString(transaction?.Date, true) || '-',
    type: transaction?.Type || '-',
    quantity: transaction && quantity(transaction),
    asset: transaction?.Symbol?.Ticker || '-',
    account: transaction?.Account?.AccNo || '-',
    accountType: transaction?.Account?.Type,
    status: transaction?.Status || '-',
    details: transaction?.TxDetails || '-',
    symbolType: transaction?.Symbol?.Type || '-',
    hash: transaction?.Hash || null,
    accountId: transaction?.AccountId || '-',
    clientId: transaction?.ClientId || '-',
    clientName: transaction?.ClientName || '-',
    direction: transaction?.Direction || null,
    clientType: (transaction?.Client && transaction.Client.ClientType) || null,
    instantPayment: transaction?.TxDetails?.find(detail => detail?.Type === 'ExternalTx')?.Data?.source === 'PaymentIQ' ? '+' : '',
    bookBuildingId: transaction?.BBId || null,
    createdAt: transaction?.createdAt || null,
    transferBlockchainTransaction:
        transaction?.TransferBlockchainTransaction === null ||
        transaction.TransferBlockchainTransaction === undefined ||
        Object.keys(transaction.TransferBlockchainTransaction)?.length === 0
          ? null
          : transaction.TransferBlockchainTransaction,
    unsettled: transaction?.Unsettled || null,
    counterpartyTxId: transaction?.CounterpartyTxId || null,
    txHash: transaction.TxHash,
    blockchainSymbol: transaction?.Symbol?.BlockchainId,
    tradeId: transaction.TradeId,
    goldBars: transaction.GoldBars || null,
    initiatorLogin: transaction.ActionInfo.InitiatorLogin,
    initiatorId: transaction.ActionInfo.InitiatorId,
    approverLogin: transaction.ActionInfo.ApproverLogin,
    approverId: transaction.ActionInfo.ApproverId,
    approveRequired: transaction.ActionInfo.ApproveRequired,
  };
  for (let txdetails of transaction.TxDetails) {
    if (txdetails.Type === 'Commission' && txdetails.Data) {
      row = {
        ...row,
        txFee: Number(txdetails.Data.comission),
        axcessFee: Number(txdetails.Data.fee),
      };
    }

    if (txdetails.Type === 'ExternalTx' && txdetails.Data) {
      if (txdetails.Data?.source) {
        row = {
          ...row,
          axcessSource: txdetails.Data?.source,
        };
      }
      if (txdetails.Data.btc_tx_subid?.length) {
        row = {
          ...row,
          btcTxUrl: txdetails.Data.btc_url ?? '',
          btcTxHash: txdetails.Data.btc_tx_subid,
        };
      }
      if (txdetails.Data.BankDetail) {
        row = {
          ...row,
          beneficiary: txdetails.Data.BankDetail.Beneficiary,
          iban: txdetails.Data.BankDetail.Iban,
          bic: txdetails.Data.BankDetail.Bic,
        };
      }
      if (txdetails.Data.Vasp) {
        row = {
          ...row,
          vasp: txdetails.Data.Vasp.Name ?? '',
        };
      }
      if (txdetails.Data.eth_tx_subid?.length) {
        row = {
          ...row,
          ethTxUrl: txdetails.Data.eth_url ?? '',
          ethTxHash: txdetails.Data.eth_tx_subid,
        };
      }
      if (txdetails.Data.vnxlu_tx_subid?.length) {
        row = {
          ...row,
          vnxluTxUrl: txdetails.Data.vnxlu_url ?? '',
          vnxluTxHash: txdetails.Data.vnxlu_tx_subid,
        };
      } else {
        row = {
          ...row,
          txUrl: txdetails.Data.url ?? '',
          externalRef: txdetails.Data.tx_subid || txdetails.Data.txid || row.hash,
          hash: txdetails.Data.tx_subid || txdetails.Data.txid || row.hash,
        };
      }
    }
    if (txdetails.Type === 'Scoring' && txdetails.Data) {
      row = {
        ...row,
        aml: txdetails.Data.score,
      };
    }
  }

  return row;
};

export const mapTransactions = (transactions) => {
  let rows = [];
  for (const [ index, transaction ] of transactions.entries()) {
    let row = mapTransaction(transaction);
    row.index = index;
    rows.push(row);
  }
  return rows;
};

