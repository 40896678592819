import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, } from '@mui/material';

import { actions } from '../symbiotes';
import { tradeBlockchainTxTypesSelector } from '../selectors';
import { dictionariesApi } from '../api';

const styles = theme => ({
  filterItem: {
    minWidth: '120px',
    '& + .MuiFormControl-root': {
      marginLeft: 15,
    },
  },
});

const mapStateToProps = state => ({
  tradeBlockchainTxTypes: tradeBlockchainTxTypesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getAvailableTradeBlockchainTxTypes: async () => {
    const res = await dispatch(dictionariesApi.getAvailableTradeBlockchainTxTypes);
    dispatch(actions.saveTradeBlockchainTxTypes(res));
  },
});

export const TradeBlockchainTxTypesView = ({ classes, tradeBlockchainTxTypes, value, onChange, disabled, label, name, getAvailableTradeBlockchainTxTypes }) => {

  useEffect(() => {
    if (!tradeBlockchainTxTypes.length) {
      getAvailableTradeBlockchainTxTypes();
    }
  }, [ getAvailableTradeBlockchainTxTypes, tradeBlockchainTxTypes.length ]);
  return (
    <FormControl className={classes.filterItem} variant="standard">
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        name={name}
        value={value}
        variant="standard"
        onChange={onChange}>
        <MenuItem value="">
          None
        </MenuItem>
        {tradeBlockchainTxTypes?.map(a => (
          <MenuItem key={a} value={a}>
            {a}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const TradeBlockchainTxTypes = connect(mapStateToProps, mapDispatchToProps)(withStyles(TradeBlockchainTxTypesView, styles));
