import React from 'react';

import { TwoFactorRoutes } from './models';

const TwoFactorAuthPage = React.lazy(() => import('./pages/2fa'));
const { TWO_FACTOR_ROOT } = TwoFactorRoutes;
export const twoFactorAuthRoutes = [
  {
    path: TWO_FACTOR_ROOT,
    element: <TwoFactorAuthPage />,
  },
];
