import React from 'react';

import { DisclosureRoutes } from './models';

const DisclosurePage = React.lazy(() => import('./pages/disclosure-page'));
const { DISCLOSURE_ROOT } = DisclosureRoutes;
export const disclosureRoutes = [
  {
    path: DISCLOSURE_ROOT,
    element: <DisclosurePage />,
  },
];
