import { withStyles } from 'tss-react/mui';
import React from 'react';
import { FormControl, InputBase, Paper, Typography, Grid, InputLabel, FormHelperText } from '@mui/material';

import { Button } from 'ui';

const styles = theme => ({
  root: {
    width: '650px',
  },
  paper: {
    width: '440px',
    padding: '40px 30px 30px 30px',
    backgroundColor: '#FFFFFF',
  },
  form: {
    width: '100%',
    marginTop: '0',
  },
  formControl: {
    marginTop: '0',
  },
  titlCode: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '26px',
    fontHeight: '32px',
    marginBottom: '74px',
  },
  button: {
    marginTop: '15px',
    marginBottom: '60px',
  },
  inputRoot: {
    'label + &': {
      marginTop: theme.spacing(1.5),
    },
  },
  inputField: {
    border: '1px solid #E3E3E3',
    fontSize: 16,
    width: '100%',
    transition: theme.transitions.create([ 'border-color', 'box-shadow' ]),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    paddingLeft: '5px',
  },
  inputLabel: {
    fontSize: '20px',
    color: '#333333',
  },
  submit: {
    width: '190px',
  },
  MuiInputLabelFormControl: {
    top: '-30px',
    width: 'fit-content',
  },
});

const InputCodeTwoFactorView = ({ classes, handleContinue, setCode, code, error, isError }) => (
  <Paper className={classes.paper}>
    <Typography className={classes.titlCode} component="h1" variant="h5">
      Two-Factor Authentification
    </Typography>
    <Grid container justifyContent="center">
      <form className={classes.form} onSubmit={handleContinue}>
        <FormControl
          fullWidth
          className={classes.formControl}
          error={isError}
          margin="normal"
          variant="standard">
          <InputLabel shrink className={classes.inputLabel} classes={{ formControl: classes.MuiInputLabelFormControl }} htmlFor="code">
            Enter the verification code generated by Google Authentificator app your phone.
          </InputLabel>
          <InputBase
            autoFocus
            required
            classes={{
              root: classes.inputRoot,
              input: classes.inputField,
            }}
            id="code"
            name="code"
            placeholder="Enter the verification code"
            type="text"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
          <FormHelperText error={isError}>{isError && String(error)}</FormHelperText>
        </FormControl>
        <Grid container alignItems="flex-start" justifyContent="center">
          <Grid item>
            <Button fullWidth className={classes.submit} theme="normal" type="submit" variant="contained">
              VERIFY
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  </Paper>
);

export const InputCodeTwoFactor = withStyles(InputCodeTwoFactorView, styles);
