import React from 'react';

import { MarketingProgramsRoutes } from './models';

const MarketingProgramsPage = React.lazy(() => import('./pages/marketing-programs-page'));
const { MARKETING_PROGRAMS_ROOT } = MarketingProgramsRoutes;
export const marketingRoutes = [
  {
    path: MARKETING_PROGRAMS_ROOT,
    element: <MarketingProgramsPage />,
  },
];
