import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, Input, FormHelperText, MenuItem } from '@mui/material';

import { commissionTypesSelector } from '../selectors';
import { getCommissionTypes } from '../effects';

const mapStateToProps = state => ({
  commissionTypes: commissionTypesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getCommissionTypes: () => dispatch(getCommissionTypes),
});

export const CommissionTypesItemsView = ({ getCommissionTypes, commissionTypes, classes, error, value, readOnly, onChange, label, name }) => {
  useEffect(() => {
    if (commissionTypes?.length === 0) {
      getCommissionTypes();
    }
  }, [ getCommissionTypes, commissionTypes?.length ]);

  return (
    <FormControl className={classes} error={error} variant="standard">
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        disabled={readOnly}
        error={error}
        input={<Input id="commissionTypes" />}
        name={name}
        value={value}
        variant="standard"
        onChange={onChange}>
        {commissionTypes?.map(a => (
          <MenuItem key={a} value={a}>
            {a}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText id="commissionTypes">{error}</FormHelperText>
    </FormControl>
  );
};

export const CommissionTypesItems = connect(mapStateToProps, mapDispatchToProps)(CommissionTypesItemsView);
