import { Navigate } from 'react-router-dom';
import React from 'react';

import { SettingsRoutes } from './models';

const SettingsPage = React.lazy(() => import('./pages/settings-page'));
const SettingsPageTab = React.lazy(() => import('./pages/settings-page-tab'));
const CryptoPageTab = React.lazy(() => import('./pages/crypto-page-tab'));
const AcquiringPageTab = React.lazy(() => import('./pages/acquiring-page-tab'));
const CommoditiesSettingsPageTab = React.lazy(() => import('./pages/commodities-page-tab'));
const SecurityPageTab = React.lazy(() => import('./pages/security-page-tab'));
const SourceSettingsPageTab = React.lazy(() => import('features/cryptocurrencies/pages/source-settings-page-tab'));
const ProvidersPageTab = React.lazy(() => import('./pages/providers-page-tab'));

const { SETTINGS_ROOT, COMMON, CRYPTO, COMMODITIES, SECURITY, RATES, PROVIDERS } = SettingsRoutes;

export const settingsRoutes = [
  {
    path: SETTINGS_ROOT,
    element: <SettingsPage />,
    children: [
      {
        path: '',
        element: <SettingsPageTab />,
      },
      {
        path: COMMON,
        element: <SettingsPageTab />,
      },
      {
        path: CRYPTO,
        element: <CryptoPageTab />,
      },
      // {
      //   path: ACQUIRING,
      //   element: <AcquiringPageTab />,
      // },
      {
        path: COMMODITIES,
        element: <CommoditiesSettingsPageTab />,
      },
      {
        path: SECURITY,
        element: <SecurityPageTab />,
      },
      {
        path: RATES,
        element: <SourceSettingsPageTab />,
      },
      {
        path: PROVIDERS,
        element: <ProvidersPageTab />,
      },
      {
        path: '*',
        element: <Navigate to={SETTINGS_ROOT} />,
      },
    ],
  },
];
