import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { FormControl, MenuItem, InputLabel, Select, } from '@mui/material';

import { blockchainsSymbolsSelector } from '../selectors';
import { getBlockchains } from '../effects';

const styles = theme => ({
  select: {
    minWidth: '80px',
    maxWidth: '220px',
  },
  filterItem: {
    '& + .MuiFormControl-root': {
      marginLeft: 15,
    },
  },
});

const mapStateToProps = state => ({
  blockchains: blockchainsSymbolsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getBlockchains: () => dispatch(getBlockchains),
});

export const BlockchainView = ({ classes, value, onChange, disabled, label, name, blockchains, getBlockchains }) => {

  useEffect(() => {
    getBlockchains();
  }, [ getBlockchains ]);

  return (
    <FormControl className={classes.filterItem} variant="standard">
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        className={classes.select}
        disabled={disabled}
        name={name}
        value={value}
        variant="standard"
        onChange={onChange}>
        <MenuItem value="">
          None
        </MenuItem>
        {blockchains?.map(blolckchain => (
          <MenuItem key={blolckchain} value={blolckchain}>
            {blolckchain}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const Blockchain = connect(mapStateToProps, mapDispatchToProps)(withStyles(BlockchainView, styles));
