import { useRoutes } from 'react-router-dom';
import React, { Fragment } from 'react';

import { rootRoutes } from './routes';
import './global-styles.css';
import './react-vis.css';

function App() {
  const routes = useRoutes(rootRoutes);
  return <Fragment>{routes}</Fragment>;
}

export default App;
