import { object, string } from 'yup';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Grid, Button, FormControl, Typography, } from '@mui/material';

import { DatepickerContainer, Datepicker, Modal } from 'ui';
import { flatValidationErrors, fileDownload, convertDateTimeTimestamp } from 'lib';
import { ErrorType } from 'features/common';

import { getReport } from '../effects';

const styles = theme => ({
  root: {
    padding: '25px',
  },
  title: {
    fontSize: '24px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  formControl: {
    width: '100%',
    marginRight: '10px',
    marginBottom: '10px',
  },
});

let schema = object().shape({
  begin: string().nullable(),
  end: string().nullable(),
});

const mapDispatchToProps = dispatch => ({
  getClientsReport: query => dispatch(getReport, query),
});

const TransactionsReportFormView = ({ classes, open, handleClose, readOnly, reportName, title, getClientsReport }) => {

  const [ start, setStart ] = useState('');
  const [ end, setEnd ] = useState('');
  const { FRONT_VALID_ERR } = ErrorType;
  const [ validationErrors, setValidationErrors ] = useState({});

  const clear = () => {
    setStart('');
    setEnd('');
    setValidationErrors({});
  };
  const close = () => {
    clear();
    handleClose();
  };

  const submit = async (form) => {
    try {
      const formValidated = await schema.validate(form, { abortEarly: false });
      setValidationErrors({});
      if (formValidated.begin === null) delete formValidated.begin;
      if (formValidated.end === null) delete formValidated.end;
      const response = await getClientsReport({ reportName, ...formValidated });
      fileDownload(response.data, title, 'xlsx');
      close();
    } catch (e) {
      if (e.name === FRONT_VALID_ERR) {
        setValidationErrors(flatValidationErrors(e.inner));
      }
      throw e;
    }
  };

  return (
    <Modal open={open}>
      <div className={classes.root}>
        <Typography className={classes.title} variant="inherit">
          {title}
        </Typography>
        <FormControl className={classes.formControl} variant="standard">
          <Datepicker
            disabled={readOnly}
            error={validationErrors['begin']}
            helperText={validationErrors['begin']}
            id="start"
            label="Start date"
            popperContainer={DatepickerContainer}
            type="datetime"
            value={start}
            onChange={setStart}
          />
        </FormControl>
        <FormControl className={classes.formControl} variant="standard">
          <Datepicker
            disabled={readOnly}
            error={validationErrors['end']}
            helperText={validationErrors['end']}
            id="endDate"
            label="End date"
            popperContainer={DatepickerContainer}
            type="datetime"
            value={end}
            onChange={setEnd}
          />
        </FormControl>
        <Grid container justifyContent="flex-end">
          <Button color="primary" onClick={close}>
            Close
          </Button>
          <Button
            color="primary"
            disabled={readOnly}
            onClick={() =>
              submit({
                begin: convertDateTimeTimestamp(start),
                end: convertDateTimeTimestamp(end),
              })
            }
          >
            Create
          </Button>
        </Grid>
      </div>
    </Modal>
  );
};

export const TransactionsReportForm = connect(null, mapDispatchToProps)(withStyles(TransactionsReportFormView, styles));
