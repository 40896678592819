import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  fetchGetGoldBarTypes: initialFetching,
  fetchGetGoldBarsRegistry: initialFetching,
  fetchRecalculate: initialFetching,
  goldBarTypes: [],
  goldBarsRegistry: [],
  goldBarsRegistryFilter: {},
  availGoldBars: [],
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchGetGoldBarTypes: createFetching('fetchGetGoldBarTypes'),
  fetchGetGoldBarsRegistry: createFetching('fetchGetGoldBarsRegistry'),
  fetchRecalculate: createFetching('fetchRecalculate'),
  setGoldBarTypes: (state, data) => ({ ...state, goldBarTypes: data.types }),
  setGoldBarsRegistry: (state, data) => ({ ...state, goldBarsRegistry: data }),
  setGoldBarsRegistryFilter: (state, data) => ({ ...state, goldBarsRegistryFilter: data }),
  setAvailGoldBars: (state, data) => ({ ...state, availGoldBars: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'goldBars');
