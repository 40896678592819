import { getLocalStorage, getUserBrowser } from 'lib';
import { toRefreshToken } from 'features/auth/effects';
import { APP_DEVICE_ID, AUTH_DATA, TIME_ZONE } from 'features/auth/constants';

import { redirectToJoin } from './response';
import { ErrorType } from './models';
import { responseStatus } from './constants';

export const checkIsFormData = options => options.body instanceof FormData;
export const changeOptionsAfterRefresh = (options, accessToken) => {
  if (checkIsFormData(options)) {
    return { ...options, headers: {
      ...options.headers,
      ...createAuthorization(accessToken),
    } };
  } else {
    return options;
  }
};

let tasks = [];
let isRefreshing = false;
let busy = false;

const run = async () => {
  if (busy) return;

  busy = true;
  while (tasks.length) {
    const task = tasks.shift();

    try {
      task();
    } catch (error) {
      console.error(error);
    }
  }
  busy = false;
};

const addTask = task => new Promise((resolve) => {
  tasks.push(async () => {
    const result = await task();
    resolve(result);
  });
});

const addTaskAndRun = task => new Promise((resolve) => {
  tasks.push(async () => {
    const result = await task();
    resolve(result);
  });
  run();
});

export const request =
  (method, url, options = {}, isRepeat = false) =>
    (dispatch, getState) => {
      const authData = getLocalStorage(AUTH_DATA);
      const appDeviceId = getLocalStorage(APP_DEVICE_ID);
      const timeZone = getLocalStorage(TIME_ZONE);
      const defaultOptions = {};
      const headers = new Headers({
        ...options.headers,
        ...createContentType(options),
        ...createAuthorization(authData?.access_token),
        ...createDeviceId(appDeviceId),
        ...createSessionId(timeZone),
      });
      const baseUrl = '/api';
      const host = '';
      const uri = `${host}${baseUrl}${url}`;

      const config = new Request(uri, {
        method,
        headers,
        ...defaultOptions,
        ...options,
        body: createBody(options, headers),
      });

      return fetch(config).then( async (response) => {
        if (response.status === responseStatus.unauthorized) {
          const code = await response.clone().json().then(data => Promise.resolve(data?.code));
          if (code === ErrorType.JWT_EXPIRED) {
            if (url !== '/auth/refresh' && !isRepeat) {
              if (!isRefreshing) {
                isRefreshing = true;
                const refreshFunc = async () => {
                  const { ok, data } = await dispatch(toRefreshToken, { refresh_token: authData?.refresh_token });
                  if (ok) {
                    let newResponce = await addTaskAndRun(() => dispatch(
                      request,
                      method,
                      url,
                      changeOptionsAfterRefresh(options, data.access_token),
                      true
                    ));
                    return newResponce;
                  } else {
                    tasks = [];
                    redirectToJoin();
                  }
                };

                const newResponce = await refreshFunc();
                isRefreshing = false;
                return newResponce;
              } else {
                let newResponce = await addTask(() => dispatch(request,method,url, options,true));
                return newResponce;
              }
            }
          }
        }
        if (options.parse === 'text') {
          return response.text();
        }
        if (options.parse === 'noparse') {
          return response;
        }
        const contentType = response.headers.get('Content-Type');
        if (contentType?.includes('json')) {
        // if API returns array data is corrupted, use source_data instead
          return response.json().then(data => ({ ...data, status: response.status, source_data: data }));
        }
        if (contentType?.includes('text/html')) {
          return response.text().then(data => ({ data, status: response.status }));
        }
        if (contentType && [ 'application/octet-stream', 'application/pdf' ].includes(contentType)) {
          return response.blob().then(data => ({
            url: URL.createObjectURL(data),
            status: response.status,
          }));
        }
        throw new TypeError('Unexpected content-type');
      });
    };

const createContentType = (options) => {
  const header = contentTypeFromOptions(options);

  return header ? { 'Content-Type': header } : {};
};

const contentTypeFromOptions = options =>
  typeof options.body === 'object' && !(options.body instanceof FormData)
    ? 'application/json'
    : options.body instanceof FormData
      ? options.headers
      : options.headers?.['Content-Type'];

export const createAuthorization = accessToken => (accessToken ? { Authorization: `Bearer ${accessToken}` } : {});
export const createDeviceId = appDeviceId => (appDeviceId ? { 'X-App-Device-Id': appDeviceId } : {});

export const createSessionId = (timeZone) => {
  const browser = getUserBrowser();
  const start = window.navigator.userAgent.indexOf('(') + 1;
  const end = window.navigator.userAgent.indexOf(')');
  const OS = window.navigator.oscpu || window.navigator.userAgent.slice(start, end);
  return { 'x-session-id': `${browser}, ${OS}${timeZone ? ', ' + timeZone : ''}` };
};

const createBody = (options, headers) => {
  if (options.body && headers.get('content-type').includes('json')) {
    return JSON.stringify(options.body);
  }
  return options.body;
};
