export const StructuredProductStatus = {
  IDLE: 'idle',
  CLOSED: 'Closed',
  OPEN: 'Open',
};
export const StructuredProductOrderStatus = {
  COMPLETED: 'Completed',
};
export const StructuredProductOfferStatus = {
  IDLE: 'idle',
  CLOSED: 'Closed',
  OPEN: 'Open',
};
export const STRUCTURED_ROUTE = 'structured-products/structured';
export const StructuredProductsRoutes = {
  SP_TABLE_ROOT: 'structured-products',
  SP_ROOT: 'structured-products/main',
  NEW: 'new',
  ID: ':id',
  INFO: 'info',
  ORDERS: 'orders',
  SP_OFFER: `${STRUCTURED_ROUTE}/:productId/offer`,
};
export const SpTabs = {
  info: { name: 'Sctructured Product Info', index: 0, route: StructuredProductsRoutes.INFO },
  orders: { name: 'Orders', index: 1, route: StructuredProductsRoutes.ORDERS },
};
