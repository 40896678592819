import { createSelector } from 'reselect';

export const authRootSelector = createSelector(
  root => root.auth,
  auth => auth
);

export const authenticatedSelector = createSelector(authRootSelector, auth => auth.authenticated);

export const authTypeSelector = createSelector(authRootSelector, auth => auth.type);

export const authIdSelector = createSelector(authRootSelector, auth => auth.id);

export const tokensSelector = createSelector(authRootSelector, auth => auth.tokens);

export const auth2FASelector = createSelector(authRootSelector, auth => auth.Enable2FA);

export const authLoginSelector = createSelector(authRootSelector, auth => auth.Login);

export const authSuperAdminSelector = createSelector(authRootSelector, auth => auth.Superadmin);
