import { withStyles } from 'tss-react/mui';
import React from 'react';
import { DialogContent, DialogTitle, DialogActions, Button, } from '@mui/material';

import { Modal } from './modal';

const styles = theme => ({
  root: {
    width: '450px',
    marginTop: '10px',
    marginBottom: '20px',
  },
});
const MessageView = ({ classes, open, children, setOpen, title = 'Error' }) => (
  <Modal handleClose={setOpen} open={open}>
    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <div className={classes.root}>{children}</div>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={setOpen}>
        Ok
      </Button>
    </DialogActions>
  </Modal>
);

export const Message = withStyles(MessageView, styles);
