import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  users: [],
  operatorAction: null,
  operatorActions: [],
  actionsTypes: [],
  actionsFilter: {},
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  setUsers: (state, data) => ({ ...state, users: data }),
  setOperatorAction: (state, data) => ({ ...state, operatorAction: data }),
  setOperatorActions: (state, data) => ({ ...state, operatorActions: data }),
  setActionsTypes: (state, data) => ({ ...state, actionsTypes: data }),
  setActionsFilter: (state, data) => ({ ...state, actionsFilter: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'users');
