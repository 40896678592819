export const asHash = (data, visibleLength = 6) => {
  let res = data;
  if (data?.length > (visibleLength * 2) + 4) {
    return `${data.substr(0, visibleLength)}....${data.substr(visibleLength * -1)}`;
  }
  return res;
};

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const trimApiKey = (key) => {
  if (key?.length > 42) {
    return key.slice(0, 12) + '...' + key.slice(key.length - 12, key.length);
  }
  if (key?.length <= 20) {
    return key;
  }
  return '';
};
