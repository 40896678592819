export const statusSignUp = {
  invalidToken: 'invalid_email_confirmation_token',
  alreadyConfirmed: 'email_already_confirmed',
  expiredToken: 'expired_email_confirmation_token',
  confirmationRequired: 'email_confirmation_required',
  twoFactorAuth: 'two_factor_auth',
  confirm: 'ok',
  successSent: 'success_sent',
};
export const AuthPaths = {
  JOIN: '/join',
  FORGET: '/forget',
  RESET_PASS: '/change-password/:token',
  REGISTRATION: '/register/:token',
  LOGOUT: '/logout',
};
export const APP_DEVICE_ID = 'appDeviceId';
export const AUTH_DATA = 'authData';
export const TIME_ZONE = 'timeZone';
