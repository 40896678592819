import React from 'react';
import Typography from '@mui/material/Typography';

import { Message } from './message';

const ErrorDialog = ({ show, handleClose, title, message }) => (
  <Message open={show} setOpen={() => handleClose(!show)} title={title}>
    {Array.isArray(message) ? (
      message.map((error, index) => {
        const errorMessage = error.message || error;
        return (
          <Typography color="error" key={index} variant="subtitle1">
            {errorMessage}
          </Typography>
        );
      })
    ) : typeof message == 'object' && Object.hasOwn(message, 'data') ? (
      typeof message.data !== 'object' ? (
        <Typography color="error" variant="subtitle1">
          {message.data}
        </Typography>
      ) : (
        <Typography color="error" variant="subtitle1">
          {message.statusText}
        </Typography>
      )
    ) : typeof message == 'object' ? (
      <Typography color="error" variant="subtitle1">
        {Object.entries(message).map(([ key, value ]) => `${value} `)}
      </Typography>
    ) : (
      <Typography color="error" variant="subtitle1">
        {message}
      </Typography>
    )}
  </Message>
);

export { ErrorDialog };
