export const getStatusErrors = (errors, form) => {
  let statusErrors = {};
  for (let error of errors) {
    const path = error.path.split('.');
    const clientType = path[0];
    if (error.errors[0] && (clientType === 'Organization' || clientType === 'Individual') && path[1].includes('Checks')) {
      const checks = path[1].split('[')[0];
      const checksIndex = path[1].split('[')[1].split(']')[0];
      const field = path[2];
      const status = form[clientType][checks][parseInt(checksIndex)];
      statusErrors[status.Type] = {};
      statusErrors[status.Type][field] = error.errors[0];
    }
    if (error.errors[0] && path[0].includes('KycReport')) {
      const status = path[0];
      const field = path[1];
      statusErrors[status] = {};
      statusErrors[status][field] = error.errors[0];
    }
  }
  return statusErrors;
};

export const checkPanelValidation = (errors, fields) => {
  for (let field of fields) {
    if (errors[field]) {
      return true;
    }
  }
  return false;
};
