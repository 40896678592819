import { Navigate } from 'react-router-dom';
import React from 'react';

import { StructuredProductsRoutes } from './models';

const StructuredProductsPage = React.lazy(() => import('./pages/structured-products-page'));
const StructuredProductPage = React.lazy(() => import('./pages/sctructured-product-page'));
const StructuredProductOfferPage = React.lazy(() => import('./pages/structured-product-offer-page'));
const StructuredProductTab = React.lazy(() => import('./components/structured-products/sctructured-product-tab'));
const OrdersTab = React.lazy(() => import('./components/orders/orders-tab'));

const { SP_TABLE_ROOT, SP_ROOT, SP_OFFER, NEW, ID, INFO, ORDERS } = StructuredProductsRoutes;

export const structuredProductsRoutes = [
  {
    path: SP_TABLE_ROOT,
    element: <StructuredProductsPage />,
  },
  {
    path: SP_ROOT,
    element: <StructuredProductPage />,
    children: [
      {
        path: ID,
        children: [
          {
            path: '',
            element: <StructuredProductTab />,
          },
          {
            path: INFO,
            element: <StructuredProductTab />,
          },
          {
            path: ORDERS,
            element: <OrdersTab />,
          },
          {
            path: '*',
            element: <Navigate to={SP_TABLE_ROOT} />,
          },
        ],
      },
      {
        path: NEW,
        element: <StructuredProductTab />,
      },
    ],
  },
  {
    path: SP_OFFER,
    children: [
      {
        path: ID,
        element: <StructuredProductOfferPage />,
      },
      {
        path: NEW,
        element: <StructuredProductOfferPage />,
      },
    ],
  },
];
