export const NotificationsRoutes = {
  NOTIFICATIONS_ROOT: '/notifications',
  TAB: ':tab',
  MAIN: 'main',
  CONFIGURE: 'configure',
};
export const NotificationTabs = {
  notification: { name: 'Notification', index: 0, route: 'main' },
  configure: { name: 'Configure notifications', index: 1, route: 'configure' },
};
