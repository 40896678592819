import { withStyles } from 'tss-react/mui';
import React from 'react';

const styles = theme => ({
  root: {
    width: '100%',
  },
});

const ContainerView = ({ classes, children, shift = 120, noflex }) => (
  <div className={classes.root} style={!noflex ? { height: `calc(100vh - ${shift}px)`, display: 'flex', flexDirection: 'column' } : null}>
    {children}
  </div>
);

export const Container = withStyles(ContainerView, styles);
