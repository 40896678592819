import React from 'react';

import { TransactionsRoutes } from './consts';

const TransactionsMainPage = React.lazy(() => import('./pages/transactions-main-page'));
const { TRANSACTIONS_ROOT } = TransactionsRoutes;
export const transactionsRoutes = [
  {
    path: TRANSACTIONS_ROOT,
    element: <TransactionsMainPage />,
  },
];
