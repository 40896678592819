import React from 'react';

import { TRADING_ROOT } from './models';

const TradingsPageTab = React.lazy(() => import('./pages/trading-page-tab'));

export const tradingPairsRoutes = [ {
  path: TRADING_ROOT,
  element: <TradingsPageTab />,
} ];
