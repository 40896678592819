import { object, ref, string } from 'yup';
import { withStyles } from 'tss-react/mui';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, InputBase, Grid, Button, InputLabel, Typography, FormHelperText, FormControl, CssBaseline } from '@mui/material';

import { ErrorDialog } from 'ui';
import { flatValidationErrors } from 'lib';
import { ErrorType } from 'features/common';

import { usePassRequire } from '../usePassRequire';
import { getRegisterEmail, register } from '../effects';
import { AuthPaths } from '../constants';
import { PassRequire } from '../components/pass-require';
import { PassRepeat } from '../components/pass-repeat';
import { Logo } from '../components';

const styles = theme => ({
  main: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#F4F4F4',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '40px 30px 30px 30px',
    width: '300px',
    boxShadow: 'inherit',
    borderRadius: '0px',
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '22px',
    fontSize: '26px',
  },
  submit: {
    backgroundColor: '#00BDFF',
    marginTop: '10px',
    width: '119px',
    height: '40px',
    borderRadius: '0px',
    boxShadow: 'inherit',
    '&:hover': {
      backgroundColor: '#333333',
      color: '#FFFFFF',
    },
  },
  link: {
    alignItems: 'center',
    marginTop: '20px',
    '& > a': {
      fontWeight: theme.typography.fontWeightMedium,
      textDecoration: 'none',
      color: '#00BDFF',
      letterSpacing: '0.01em',
    },
  },
  text: {
    width: '300px',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: '100px',
    marginBottom: '25px',
  },
  inputRoot: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  inputField: {
    border: '1px solid #E3E3E3',
    fontSize: 16,
    width: '100%',
    transition: theme.transitions.create([ 'border-color', 'box-shadow' ]),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    paddingLeft: '5px',
  },
  inputLabel: {
    fontSize: '20px',
    color: '#333333',
  },
  formControl: {
    marginTop: '0px',
    marginBottom: '20px',
  },
});

let schema = object().shape({
  password: string().required(),
  repeatPassword: string().oneOf([ ref('password'), null ], 'Please enter the same password.'),
});

const mapDispatchToProps = dispatch => ({
  getRegisterEmail: token => dispatch(getRegisterEmail, token),
  register: (token, password) => dispatch(register, token, password),
});

function RegisterPageView({ classes, register, getRegisterEmail }) {
  const { JOIN } = AuthPaths;
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ repeatPassword, setRepeatPassword ] = useState('');

  const { FRONT_VALID_ERR } = ErrorType;
  const [ error, setError ] = useState('');
  const [ validationErrors, setValidationErrors ] = useState({});
  const [ errorShow, setErrorShow ] = useState(false);

  let { token } = useParams();
  const navigate = useNavigate();

  const { isSamePass, isEqual, isMinLength, isHaveLower, isHaveUpper, isHaveNumber, validPass } = usePassRequire(email, password, repeatPassword );

  useEffect(() => {
    const checkEmail = async () => {
      const { ok, error, data } = await getRegisterEmail(token);
      if (ok) {
        setEmail(data.Email);
      } else {
        setError(error.data);
        setErrorShow(true);
      }
    };
    checkEmail();
  }, [ getRegisterEmail, token ]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formValidated = await schema.validate({ password, repeatPassword }, { abortEarly: false });
      setValidationErrors({});
      const { ok, error } = await register(token, formValidated.password);
      if (ok) {
        navigate(JOIN);
      } else {
        setError(error.data);
        setErrorShow(true);
      }
    } catch (e) {
      if (e.name === FRONT_VALID_ERR) {
        setValidationErrors(flatValidationErrors(e.inner));
      }
      throw e;
    }
  };

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Logo />
      <Paper className={classes.paper}>
        <Typography className={classes.title} component="h1" variant="h5">
          Enter password
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <FormControl fullWidth required className={classes.formControl} variant="standard">
            <InputLabel shrink className={classes.inputLabel} htmlFor="password">
              Password
            </InputLabel>
            <InputBase
              autoComplete="current-password"
              classes={{
                root: classes.inputRoot,
                input: classes.inputField,
              }}
              error={validationErrors['password']}
              helperText={validationErrors['password']}
              id="password"
              inputProps={{
                maxLength: '30',
                minLength: '6',
              }}
              name="password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </FormControl>
          <PassRequire
            display={password && !validPass}
            equal={isEqual}
            length={isMinLength}
            lower={isHaveLower}
            number={isHaveNumber}
            upper={isHaveUpper}
          />
          <FormControl fullWidth required className={classes.formControl} variant="standard">
            <InputLabel shrink className={classes.inputLabel} htmlFor="repeat-password">
              Repeat password
            </InputLabel>
            <InputBase
              autoComplete="current-password"
              classes={{
                root: classes.inputRoot,
                input: classes.inputField,
              }}
              id="repeat-password"
              inputProps={{
                maxLength: '30',
                minLength: '6',
              }}
              name="repeat-password"
              type="password"
              value={repeatPassword}
              onChange={e => setRepeatPassword(e.target.value)}
            />
            <FormHelperText disabled={validationErrors['repeatPassword']} error={true}>
              {validationErrors['repeatPassword']}
            </FormHelperText>
          </FormControl>
          <PassRepeat display={repeatPassword && !isSamePass} same={isSamePass} />
          <Grid container alignItems="flex-start" justifyContent="center">
            <Grid item>
              <Button fullWidth className={classes.submit} color="primary" disabled={!validPass || !isSamePass} type="submit" variant="contained">
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Grid className={classes.text}>
        <Typography variant="body1" />
      </Grid>
      <ErrorDialog handleClose={setErrorShow} message={error} show={errorShow} />
    </main>
  );
}

RegisterPageView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const RegisterPage = connect(null, mapDispatchToProps)(withStyles(RegisterPageView, styles));
