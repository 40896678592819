import { Navigate } from 'react-router-dom';
import React from 'react';

import { DsRoutes, DsTabRoutes } from './models';

const DirectSalePage = React.lazy(() => import('./pages/direct-sale-page'));
const DirectSalesPage = React.lazy(() => import('./pages/direct-sales-page'));
const DirectSaleOfferPage = React.lazy(() => import('./pages/direct-sale-offer-page'));
const DirectSalePropOfferPage = React.lazy(() => import('./pages/direct-sale-prop-offer-page'));
const PromoCardPage = React.lazy(() => import('./pages/promo-card-page'));
const DirectSaleTab = React.lazy(() => import('./components/direct-sales/direct-sale-tab'));
const OffersTab = React.lazy(() => import('./components/offers/offers-tab'));
const LpTransactionsTab = React.lazy(() => import('./components/lp-transactions/lp-transactions-tab'));
const OrdersTabGolden = React.lazy(() => import('./components/orders/orders-tab-golden'));
const OrdersTab = React.lazy(() => import('./components/orders/orders-tab'));
const ConversionOrdersTab = React.lazy(() => import('features/conversion-orders/components/conversion-orders-tab'));
const TerminateTradesTab = React.lazy(() => import('./components/terminate-trades/terminate-trades-tab'));
const TradesTab = React.lazy(() => import('./components/trades/trades-tab'));
const GoldBarsRegistryTab = React.lazy(() => import('./components/gold-bars-registry/gold-bars-registry-tab'));
const SmartContractsTab = React.lazy(() => import('./components/smart-contracts/smart-contracts-tab'));
const PromoCardsTab = React.lazy(() => import('./components/promotion-cards/promo-card-tab'));

const { DS_TABLE_ROOT, DS_ROOT, DS_ID, CREATE, OFFER_ROOT, OFFER_CREATE, GOLD_OFFER_MODE, OFFER_MODE, PROP_OFFER_ROOT, PROP_OFFER_CREATE, PROMO_ROOT, ID } =
  DsRoutes;

const { INFO, OFFERS, TRANSACTION, GOLD_ORDERS, ORDERS, CONVERSION, TRADES, BARS, CONTRACTS, PROMOTION, BALANCING } = DsTabRoutes;

export const directSaleRoutes = [
  {
    path: DS_TABLE_ROOT,
    element: <DirectSalesPage />,
  },
  {
    path: DS_ROOT,
    element: <DirectSalePage />,
    children: [
      {
        path: DS_ID,
        children: [
          {
            path: '',
            element: <DirectSaleTab />,
          },
          {
            path: INFO,
            element: <DirectSaleTab />,
          },
          {
            path: OFFERS,
            element: <OffersTab />,
          },
          {
            path: BALANCING,
            element: <TradesTab />,
          },
          {
            path: TRANSACTION,
            element: <LpTransactionsTab />,
          },
          {
            path: GOLD_ORDERS,
            element: <OrdersTabGolden />,
          },
          {
            path: ORDERS,
            element: <OrdersTab />,
          },
          {
            path: CONVERSION,
            element: <ConversionOrdersTab />,
          },
          {
            path: TRADES,
            element: <TerminateTradesTab />,
          },
          {
            path: BARS,
            element: <GoldBarsRegistryTab />,
          },
          {
            path: CONTRACTS,
            element: <SmartContractsTab />,
          },
          {
            path: PROMOTION,
            element: <PromoCardsTab />,
          },
          {
            path: '*',
            element: <Navigate to={DS_TABLE_ROOT} />,
          },
        ],
      },
      {
        path: CREATE,
        element: <DirectSaleTab />,
      },
    ],
  },
  {
    path: OFFER_ROOT,
    children: [
      {
        path: CREATE,
        element: <DirectSaleOfferPage />,
      },
      {
        path: OFFER_CREATE,
        element: <DirectSaleOfferPage />,
      },
      {
        path: OFFER_MODE,
        element: <DirectSaleOfferPage />,
      },
      {
        path: GOLD_OFFER_MODE,
        element: <DirectSaleOfferPage />,
      },
    ],
  },
  {
    path: PROP_OFFER_ROOT,
    children: [
      {
        path: PROP_OFFER_CREATE,
        element: <DirectSalePropOfferPage />,
      },
      {
        path: GOLD_OFFER_MODE,
        element: <DirectSalePropOfferPage />,
      },
    ],
  },
  {
    path: PROMO_ROOT,
    children: [
      {
        path: CREATE,
        element: <PromoCardPage />,
      },
      {
        path: ID,
        element: <PromoCardPage />,
      },
    ],
  },
];
