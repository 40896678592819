export const DirectSaleStatus = {
  IDLE: 'idle',
  NEW: 'new',
  CLOSE: 'close',
  OPEN: 'open',
};
export const DirectSaleOfferStatus = {
  IDLE: 'idle',
  NEW: 'new',
  CLOSE: 'close',
  OPEN: 'open',
};

export const TradeType = {
  TRADE: 'trade',
  PROP: 'prop',
  BALANCE: 'balance',
  TERMINATE: 'terminate',
};
export const FilterStatusOffer = [
  {
    name: 'New',
    value: 'new',
  },
  {
    name: 'Close',
    value: 'close',
  },
  {
    name: 'Open',
    value: 'open',
  },
];
export const DirectionsOfferGolden = [
  {
    name: 'Buy',
    value: 'buy',
  },
  {
    name: 'Sell',
    value: 'sell',
  },
];
export const ReverseDirectionsTrades = {
  Buy: 'Sell',
  Sell: 'Buy',
};
export const DirectionsTradesForFilter = [
  {
    name: 'Buy',
    value: 'Sell',
  },
  {
    name: 'Sell',
    value: 'Buy',
  },
];
export const LiquidityAction = { TAKE: 'take', ADD: 'add' };

export const BAR_NOT_EXISTED_CODE = 'gold_bars_are_not_existed_in_the_registry';
export const BAR_MISSING_CODE = 'gold_bars_must_be_missing_in_the_registry';

export const FilterOrderGoldenStatus = [
  {
    name: 'Active',
    value: 'active',
  },
  {
    name: 'Completed',
    value: 'completed',
  },
  {
    name: 'Canceled',
    value: 'canceled',
  },
  {
    name: 'Rejected',
    value: 'rejected',
  },
];

export const FilterOfferType = [
  {
    name: 'Agency offer',
    value: 'trade',
  },
  {
    name: 'Prop offer',
    value: 'prop',
  },
];

export const OrderGoldenStatus = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  REJECTED: 'rejected',
};

export const columnsOrdersDCR = {
  date: 'Order Date',
  clientId: 'Investor',
  quantity: 'Quantity',
  price: 'Price',
  orderValue: 'Order Value',
  transferStatus: 'Transfer Status',
};

export const columnsOrdersDGR = {
  date: 'Order Date',
  orderStatus: 'Status',
  id: 'Order ID',
  clientId: 'Investor',
  quantity: 'Quantity',
  price: 'Price',
  asset: 'Asset',
};

export const TradeTypeOfferView = {
  trade: 'Agency offer',
  prop: 'Prop offer',
};

export const TypeTradeOfferView = {
  trade: 'agency',
  prop: 'prop',
};
export const InstrumentType = {
  DGR: 'DGR',
  DCR: 'DCR',
  FRT: 'FRT',
};
export const PropOfferAction = {
  OPEN: 'Open',
  CLOSE: 'Close',
};
export const needOpenQuery = '?needOpenId=';
export const Mode = { EDIT: 'edit', VIEW: 'view' };
export const DIRECT_ROUTE = 'direct-sales/direct';
export const DsRoutes = {
  DS_TABLE_ROOT: 'direct-sales',
  DS_ROOT: 'direct-sales/main',
  OFFER_ROOT: `${DIRECT_ROUTE}/:directSaleId/offer`,
  PROP_OFFER_ROOT: `${DIRECT_ROUTE}/:directSaleId/prop/offer`,
  PROMO_ROOT: `${DIRECT_ROUTE}/:directSaleId/promo`,
  DS_ID: ':id',
  CREATE: 'new',
  OFFER_CREATE: ':type/new',
  OFFER_MODE: ':mode/:id',
  GOLD_OFFER_MODE: ':type/:mode/:id',
  PROP_OFFER_CREATE: ':create',
  ID: ':id',
};
export const DsTabRoutes = {
  INFO: 'info',
  OFFERS: 'offers',
  TRANSACTION: 'transactions',
  GOLD_ORDERS: 'gold-orders',
  ORDERS: 'orders',
  CONVERSION: 'conversion',
  TRADES: 'trades',
  BARS: 'bars',
  CONTRACTS: 'contracts',
  PROMOTION: 'promotion',
  BALANCING: 'balancing',
};
export const DsTabs = {
  [InstrumentType.DGR]: {
    info: { name: 'Direct Sale Info', index: 0, route: DsTabRoutes.INFO },
    offers: { name: 'Offers', index: 1, route: DsTabRoutes.OFFERS },
    transactions: { name: 'LP transactions', index: 2, route: DsTabRoutes.TRANSACTION },
    orders: { name: 'Orders', index: 3, route: DsTabRoutes.GOLD_ORDERS },
    conversion: { name: 'Conversion orders', index: 4, route: DsTabRoutes.CONVERSION },
    trades: { name: 'Trades', index: 5, route: DsTabRoutes.TRADES },
    bars: { name: 'Metal bars allocation', index: 6, route: DsTabRoutes.BARS },
    contracts: { name: 'Deployed Smart Contracts', index: 7, route: DsTabRoutes.CONTRACTS },
    promotion: { name: 'Promotion Cards', index: 8, route: DsTabRoutes.PROMOTION },
  },
  [InstrumentType.FRT]: {
    info: { name: 'Direct Sale Info', index: 0, route: DsTabRoutes.INFO },
    offers: { name: 'Offers', index: 1, route: DsTabRoutes.OFFERS },
    transactions: { name: 'LP transactions', index: 2, route: DsTabRoutes.TRANSACTION },
    orders: { name: 'Orders', index: 3, route: DsTabRoutes.GOLD_ORDERS },
    conversion: { name: 'Conversion orders', index: 4, route: DsTabRoutes.CONVERSION },
    trades: { name: 'Trades', index: 5, route: DsTabRoutes.TRADES },
    contracts: { name: 'Deployed Smart Contracts', index: 6, route: DsTabRoutes.CONTRACTS },
    promotion: { name: 'Promotion Cards', index: 7, route: DsTabRoutes.PROMOTION },
  },
  [InstrumentType.DCR]: {
    info: { name: 'Direct Sale Info', index: 0, route: DsTabRoutes.INFO },
    offers: { name: 'Offers', index: 1, route: DsTabRoutes.OFFERS },
    balancing: { name: 'Balancing trades', index: 2, route: DsTabRoutes.BALANCING },
    orders: { name: 'Orders', index: 3, route: DsTabRoutes.ORDERS },
    trades: { name: 'Trades', index: 4, route: DsTabRoutes.TRADES },
  },
};
export const MAIN_TAB = DsTabRoutes.INFO;
export const OFFERS_TAB = DsTabRoutes.OFFERS;
export const MarkupType = {
  PERCENTS: 'Percents',
  ABSOLUTE: 'Absolute',
};
export const GoldBarsWeightType = {
  10: '10G',
  1000: '1KG',
};
