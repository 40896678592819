import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  promoCards: [],
  promoCardsFilter: {},
  promoCard: {},
  promoCardPropOffers: [],
  promoCardAgencyOffers: [],
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  setPromoCards: (state, data) => ({ ...state, promoCards: data }),
  setPromoCardsFilter: (state, data) => ({ ...state, promoCardsFilter: data }),
  setPromoCard: (state, data) => ({ ...state, promoCard: data }),
  setPromoCardPropOffers: (state, data) => ({ ...state, promoCardPropOffers: data }),
  setPromoCardAgencyOffers: (state, data) => ({ ...state, promoCardAgencyOffers: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'promoCard');
