import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, } from '@mui/material';

import { actions } from '../symbiotes';
import { transferBlockchainTxTypesSelector } from '../selectors';
import { dictionariesApi } from '../api';

const styles = theme => ({
  filterItem: {
    minWidth: '120px',
    '& + .MuiFormControl-root': {
      marginLeft: 15,
    },
  },
});

const mapStateToProps = state => ({
  transferBlockchainTxTypes: transferBlockchainTxTypesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getAvailableTransferBlockchainTxTypes: async () => {
    const res = await dispatch(dictionariesApi.getAvailableTransferBlockchainTxTypes);
    dispatch(actions.saveTransferBlockchainTxTypes(res));
  },
});

export const TransferBlockchainTxTypesView = ({
  classes,
  transferBlockchainTxTypes,
  value,
  onChange,
  disabled,
  label,
  name,
  getAvailableTransferBlockchainTxTypes,
}) => {

  useEffect(() => {
    if (!transferBlockchainTxTypes.length) {
      getAvailableTransferBlockchainTxTypes();
    }
  }, [ getAvailableTransferBlockchainTxTypes, transferBlockchainTxTypes.length ]);
  return (
    <FormControl className={classes.filterItem} variant="standard">
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        name={name}
        value={value}
        variant="standard"
        onChange={onChange}>
        <MenuItem value="">
          None
        </MenuItem>
        {transferBlockchainTxTypes?.map(a => (
          <MenuItem key={a} value={a}>
            {a}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const TransferBlockchainTxTypes = connect(mapStateToProps, mapDispatchToProps)(withStyles(TransferBlockchainTxTypesView, styles));
