import { withStyles } from 'tss-react/mui';
import React from 'react';
import { LinearProgress, Typography, } from '@mui/material';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '20px',
  },
  title: {
    paddingBottom: '5px',
  },
  linearColorPrimary: {
    backgroundColor: '#DEDEDE',
  },
  linearBarColorPrimary: {
    backgroundColor: '#ED2939',
  },
});

const progressColor = classes => ({
  colorPrimary: classes.linearColorPrimary,
  barColorPrimary: classes.linearBarColorPrimary,
});

const ProgressView = ({ classes, titleText, completed }) => (
  <div className={classes.root}>
    <Typography className={classes.title} variant="inherit">
      {titleText}: {completed} %
    </Typography>
    <LinearProgress classes={progressColor(classes)} value={completed} variant="determinate" />
  </div>
);

export const Progress = withStyles(ProgressView, styles);
