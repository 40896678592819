import { get } from '../common/api';

import { api } from './../common/index';

export const accountApi = {
  create: (token, data) => api.post(`/register/${token}`, data),
  sendTokenToEmail: email => api.post('/change-password/send-email', email),
  changePassword: (token, data) => api.post(`/change-password/${token}`, data),
  getEmail: token => api.get(`/change-password/verify/${token}`),
  getRegisterEmail: token => api.get(`/register/${token}`),
};

export const sessionApi = {
  me: () => get('/admin/users/me'),
  toRefreshToken: refresh_token => api.post('/auth/refresh', refresh_token),
  getTokens: query => api.get('/admin/token', query),
  changeTokenStatus: (id, query) => api.put(`/admin/token/status/${id}`, query),
  create: loginData => api.post('/auth/sign-in', loginData),
  drop: logoutData => api.post('/auth/sign-out', logoutData),
  create2fa: loginData => api.post('/auth/2fa-sign-in', loginData),
};
