import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  fetchGetInstrumentTypes: initialFetching,
  fetchGetInstrumentTypesDS: initialFetching,
  fetchGetCommissionTypes: initialFetching,
  fetchGetMarkupTypes: initialFetching,
  fetchGetAvailableBidBlockchainTxTypes: initialFetching,
  assetTypes: [],
  countries: [],
  currencies: [],
  idTypesKYCs: [],
  idTypesNationals: [],
  investmentStages: [],
  idTypes: [],
  instrumentTypes: [],
  instrumentTypesDS: [],
  bookBlockchainStatuses: [],
  bookBlockchainTxTypes: [],
  bidBlockchainTxTypes: [],
  bidBlockchainBusinessStatuses: [],
  DCRBlockchainStatuses: [],
  DCRBlockchainTxTypes: [],
  tradeBlockchainTxTypes: [],
  transferBlockchainTxTypes: [],
  DCRTickers: [],
  transferTickers: [],
  commissionTypes: [],
  markupTypes: [],
  transactionTypes: [],
  symbolTypes: [],
  statuses: [],
  tradeTypes: [],
  balancingTransactionsAvailableTypes: [],
  blockchains: [],
  orderSources: [],
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchGetInstrumentTypes: createFetching('fetchGetInstrumentTypes'),
  fetchGetInstrumentTypesDS: createFetching('fetchGetInstrumentTypesDS'),
  fetchGetCommissionTypes: createFetching('fetchGetCommissionTypes'),
  fetchGetMarkupTypes: createFetching('fetchGetMarkupTypes'),
  fetchGetAvailableBidBlockchainTxTypes: createFetching('fetchGetAvailableBidBlockchainTxTypes'),
  fetchGetBlockchains: createFetching('fetchGetBlockchains'),
  saveAssetTypes: (state, data) => ({ ...state, assetTypes: data.rows }),
  saveCountries: (state, data) => ({ ...state, countries: data.rows }),
  saveIdTypesKYCs: (state, data) => ({ ...state, idTypesKYCs: data.rows }),
  saveIdTypesNationals: (state, data) => ({ ...state, idTypesNationals: data.rows }),
  saveInvestmentStages: (state, data) => ({ ...state, investmentStages: data.rows }),
  saveIdTypes: (state, data) => ({ ...state, idTypes: data.source_data }),
  saveCCY: (state, data) => ({ ...state, currencies: data.rows }),
  saveInstrumentTypes: (state, data) => ({ ...state, instrumentTypes: data }),
  saveInstrumentTypesDS: (state, data) => ({ ...state, instrumentTypesDS: data }),
  saveBookBlockchainStatuses: (state, data) => ({ ...state, bookBlockchainStatuses: data.source_data }),
  saveBookBlockchainTxTypes: (state, data) => ({ ...state, bookBlockchainTxTypes: data.source_data }),
  saveBidBlockchainTxTypes: (state, data) => ({ ...state, bidBlockchainTxTypes: data.source_data }),
  saveBidBlockchainBusinessStatuses: (state, data) => ({ ...state, bidBlockchainBusinessStatuses: data.source_data }),
  saveDCRBlockchainStatuses: (state, data) => ({ ...state, DCRBlockchainStatuses: data.source_data }),
  saveDCRBlockchainTxTypes: (state, data) => ({ ...state, DCRBlockchainTxTypes: data.source_data }),
  saveTradeBlockchainTxTypes: (state, data) => ({ ...state, tradeBlockchainTxTypes: data.source_data }),
  saveTransferBlockchainTxTypes: (state, data) => ({ ...state, transferBlockchainTxTypes: data.source_data }),
  saveDCRTickers: (state, data) => ({ ...state, DCRTickers: data.source_data }),
  saveTransferTickers: (state, data) => ({ ...state, transferTickers: data.source_data }),
  saveCommissionTypes: (state, data) => ({ ...state, commissionTypes: data }),
  saveMarkupTypes: (state, data) => ({ ...state, markupTypes: data }),
  saveTransactionTypes: (state, data) => ({ ...state, transactionTypes: data.source_data }),
  saveSymbolTypes: (state, data) => ({ ...state, symbolTypes: data.source_data }),
  saveStatuses: (state, data) => ({ ...state, statuses: data }),
  saveTradeTypes: (state, data) => ({ ...state, tradeTypes: data.source_data }),
  saveBalancingTransactionsAvailableTypes: (state, data) => ({
    ...state,
    balancingTransactionsAvailableTypes: data.source_data,
  }),
  saveBlockchains: (state, data) => ({ ...state, blockchains: data }),
  saveOrderSources: (state, data) => ({ ...state, orderSources: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'dictionaries');
