import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { FormHelperText, MenuItem, FormControl, Select, InputLabel } from '@mui/material';

import { dictionariesApi, actions } from 'features/dictionaries';

import { countriesSelector } from '../selectors';

const mapStateToProps = state => ({
  countries: countriesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getCountries: async () => {
    const res = await dispatch(dictionariesApi.getCountries);
    dispatch(actions.saveCountries(res));
  },
});
export const CountriesItemsView = ({
  getCountries,
  countries,
  value,
  onChange,
  disabled,
  error,
  classes,
  name,
  label,
  multiple,
  array,
  changedFields = {},
  fieldName = false,
}) => {
  const handler = (e) => {
    if (array) {
      onChange([ e.target.value ]);
    } else {
      onChange(e.target.value);
    }
  };

  useEffect(() => {
    if (!countries.length) {
      getCountries();
    }
  }, [ getCountries, countries.length ]);
  return (
    <FormControl className={classes.select || classes} error={error} variant="standard">
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        error={error}
        multiple={multiple}
        name={name}
        value={value ?? ''}
        variant="standard"
        onChange={e => handler(e)}>
        <MenuItem value="">None</MenuItem>
        {countries.map(c => (
          <MenuItem key={c.id} value={c.Country}>
            {c.Country}
          </MenuItem>
        ))}
      </Select>
      {changedFields[fieldName] ? <FormHelperText className={classes.changed}>Changed</FormHelperText> : null}
    </FormControl>
  );
};
export const CountriesItems = connect(mapStateToProps, mapDispatchToProps)(CountriesItemsView);
