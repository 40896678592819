import { api } from 'features/common';

export const accountsApi = {
  getAccountsV2: query => api.get('/admin/accounts/v2', query),
  getCurrencies: query => api.get('/admin/symbols', query),
  getAvailSymbolTickers: () => api.get('/admin/transactions/avail-symbol-tickers'),
  getPositions: query => api.get('/admin/positions', query),
  getTransactions: query => api.get('/admin/transactions', query),
  getTransaction: id => api.get(`/admin/transactions/${id}`),
  createTrade: data => api.post('/admin/trades', data),
  createTradeVNXLU: data => api.post('/admin/trades/vnx', data),
  getTradeTypes: query => api.get('/admin/trades/tx-avail-types', query),
  getTradeSymbols: query => api.get('/admin/trades/tx-avail-symbols', query),
  getTradesAvailExternal: query => api.get('/admin/trades/tx-avail-external', query),
  getAvailDirections: query => api.get('/admin/trades/tx-avail-directions', query),
  getSymbol: id => api.get(`/admin/symbols/${id}`),
};
