import { connect } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import { InputLabel, MenuItem, FormControl, Select, Input, FormHelperText } from '@mui/material';

import { markupTypesSelector } from '../selectors';
import { getMarkupTypes } from '../effects';

const mapStateToProps = state => ({
  markupTypes: markupTypesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getMarkupTypes: () => dispatch(getMarkupTypes),
});

export const MarkupTypesItemsView = ({ getMarkupTypes, markupTypes, classes, error, value, readOnly, onChange, label, name }) => {
  useEffect(() => {
    if (markupTypes?.length === 0) {
      getMarkupTypes();
    }
  }, [ getMarkupTypes, markupTypes?.length ]);

  const disabled = useMemo(() => markupTypes.length === 1, [ markupTypes.length ]);

  return (
    <FormControl className={classes} error={error} variant="standard">
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        disabled={readOnly || disabled}
        error={error}
        input={<Input id="markupTypes" />}
        name={name}
        style={{ fontSize: 'initial' }}
        value={value}
        variant="standard"
        onChange={onChange}>
        {markupTypes?.map(a => (
          <MenuItem key={a} value={a}>
            {a}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText id="markupTypes">{error}</FormHelperText>
    </FormControl>
  );
};

export const MarkupTypesItems = connect(mapStateToProps, mapDispatchToProps)(MarkupTypesItemsView);
