import React from 'react';

import { RatesRoutes } from './models';

const CryptocurrenciesPage = React.lazy(() => import('./pages/cryptocurrencies-page'));

const { RATES_ROOT } = RatesRoutes;

export const cryptoCurrenciesRoutes = [
  {
    path: RATES_ROOT,
    element: <CryptocurrenciesPage />,
  },
];
