import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialError = { ok: true, data: '' };
export const initialState = {
  fetchOne: initialFetching,
  fetchIssuers: initialFetching,
  fetchOfferings: initialFetching,
  fetchStatus: initialFetching,
  fetchSendNotification: initialFetching,
  fetchDeleteDisclosureDocument: initialFetching,
  fetchDeactivateDisclosureDocument: initialFetching,
  fetchRestoreDisclosureDocument: initialFetching,
  fetchApproveDisclosureDocument: initialFetching,
  fetchDeclineDisclosureDocument: initialFetching,
  filter: [],
  disclosureItems: [],
  issuers: null,
  brokers: null,
  offerings: [],
  downloadStatus: {},
  error: initialError,
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchIssuers: createFetching('fetchIssuers'),
  fetchOfferings: createFetching('fetchOfferings'),
  fetchStatus: createFetching('fetchStatus'),
  fetchSendNotification: createFetching('fetchSendNotification'),
  fetchDeleteDisclosureDocument: createFetching('fetchDeleteDisclosureDocument'),
  fetchDeactivateDisclosureDocument: createFetching('fetchDeactivateDisclosureDocument'),
  fetchRestoreDisclosureDocument: createFetching('fetchRestoreDisclosureDocument'),
  fetchApproveDisclosureDocument: createFetching('fetchApproveDisclosureDocument'),
  fetchDeclineDisclosureDocument: createFetching('fetchDeclineDisclosureDocument'),
  setIssuers: (state, data) => ({ ...state, issuers: data }),
  setBrokers: (state, data) => ({ ...state, brokers: data }),
  setOfferingsDirectSale: (state, data) => ({ ...state, offerings: data }),
  setDisclosureItems: (state, data) => ({ ...state, disclosureItems: data }),
  setError: (state, data) => ({ ...state, error: data }),
  setDownloadStatus: (state, data) => ({ ...state, downloadStatus: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'disclosure');
