export const responseStatus = {
  success: 200,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  validationError: 422,
  serverError: 500,
};

export const filesHost = process.env.REACT_APP_FILEHOST;
export const regexpLat = /([^A-Za-z0-9\s`'"&.,-]+)/g;
export const regexpNum = /^[0-9](\d+)?(,\d{1,2})?$|^$/;
export const regexpNumHundred = /^[1-9]([0-9])?(,\d{1,2})?$|^100$|^$/;
export const kycSumsubUrl = process.env.REACT_APP_KYC_SUMSUB_BASE_URL;
export const kycOneboardUrl = process.env.REACT_APP_KYC_ONEBOARD_BASE_URL;
export const virtualAssetTypes = [ 'VNX', 'DCR', 'DGR', 'FRT', 'Crypto', 'AltCoin', 'StructuredDeposit', 'Obligation' ];

export const defaultMaxSizeFile = 1024;
export const defaultImageExtensions = [ 'png', 'jpg', 'jpeg', 'svg' ];
export const defaultCommonFileExtensions = [ 'pdf', ...defaultImageExtensions ];
export const defaultSettingsFileExtensions = [ 'csv' ];
export const defaultDisclosureFileExtensions = [ 'pdf' ];
export const subTabsShiftContainer = 230;
export const TargetType = {
  DS: 'DS',
  SETTINGS: 'settings',
  DISCLOSURE: 'disclosure',
};
