import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { FormControl, MenuItem, InputLabel, Select, } from '@mui/material';

import { actions } from '../symbiotes';
import { DCRBlockchainTxTypesSelector } from '../selectors';
import { dictionariesApi } from '../api';

const styles = theme => ({
  filterItem: {
    minWidth: '120px',
    '& + .MuiFormControl-root': {
      marginLeft: 15,
    },
  },
});

const mapStateToProps = state => ({
  DCRBlockchainTxTypes: DCRBlockchainTxTypesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getAvailableDCRBlockchainTxTypes: async () => {
    const res = await dispatch(dictionariesApi.getAvailableDCRBlockchainTxTypes);
    dispatch(actions.saveDCRBlockchainTxTypes(res));
  },
});

export const DCRBlockchainTxTypesView = ({ classes, DCRBlockchainTxTypes, value, onChange, disabled, label, name, getAvailableDCRBlockchainTxTypes }) => {

  useEffect(() => {
    if (!DCRBlockchainTxTypes.length) {
      getAvailableDCRBlockchainTxTypes();
    }
  }, [ getAvailableDCRBlockchainTxTypes, DCRBlockchainTxTypes.length ]);
  return (
    <FormControl className={classes.filterItem} variant="standard">
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        name={name}
        value={value}
        variant="standard"
        onChange={onChange}>
        <MenuItem value="">
          None
        </MenuItem>
        {DCRBlockchainTxTypes?.map(a => (
          <MenuItem key={a} value={a}>
            {a}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const DCRBlockchainTxTypes = connect(mapStateToProps, mapDispatchToProps)(withStyles(DCRBlockchainTxTypesView, styles));
