import { withStyles } from 'tss-react/mui';
import { NavLink, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Paper, Typography, InputBase, FormControl, InputLabel, Grid, CssBaseline } from '@mui/material';

import { ErrorDialog } from 'ui';

import { sendTokenToEmail } from '../effects';
import { AuthPaths } from '../constants';
import { Logo } from '../components';

const styles = theme => ({
  main: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#F4F4F4',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '40px 30px 30px 30px',
    width: '300px',
    height: '318px',
    boxShadow: 'inherit',
    borderRadius: '0px',
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '22px',
    fontSize: '26px',
  },
  submit: {
    backgroundColor: '#00BDFF',
    marginTop: '30px',
    width: '239px',
    height: '40px',
    borderRadius: '0px',
    boxShadow: 'inherit',
    '&:hover': {
      backgroundColor: '#333333',
      color: '#FFFFFF',
    },
  },
  link: {
    alignItems: 'center',
    marginTop: '20px',
    '& > a': {
      fontWeight: theme.typography.fontWeightMedium,
      textDecoration: 'none',
      color: '#00BDFF',
      marginRight: '15px',
    },
  },
  text: {
    width: '300px',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: '100px',
    marginBottom: '25px',
  },
  inputRoot: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  inputField: {
    border: '1px solid #E3E3E3',
    fontSize: 16,
    width: '100%',
    transition: theme.transitions.create([ 'border-color', 'box-shadow' ]),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    paddingLeft: '5px',
  },
  inputLabel: {
    fontSize: '20px',
    color: '#333333',
  },
});

const mapDispatchToProps = dispatch => ({
  sendTokenToEmail: email => dispatch(sendTokenToEmail, email),
});

function ForgetPasswordPageView({ classes, sendTokenToEmail }) {
  const { JOIN } = AuthPaths;
  const [ email, setEmail ] = useState('');

  const [ error, setError ] = useState('');
  const [ errorShow, setErrorShow ] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { ok, error } = await sendTokenToEmail(email);
    if (ok) {
      navigate(JOIN);
    } else {
      setError(error.data);
      setErrorShow(true);
    }
  };

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Logo />
      <Paper className={classes.paper}>
        <Typography className={classes.title} component="h1" variant="h5">
          Forgot password
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <FormControl fullWidth required variant="standard">
            <InputLabel shrink className={classes.inputLabel} htmlFor="email">
              Your email
            </InputLabel>
            <InputBase
              autoFocus
              autoComplete="Email"
              classes={{
                root: classes.inputRoot,
                input: classes.inputField,
              }}
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </FormControl>
          <Grid container alignItems="flex-start" justifyContent="center">
            <Grid item>
              <Button fullWidth className={classes.submit} color="primary" type="submit" variant="contained">
                Resend password link
              </Button>
            </Grid>
            <Grid item className={classes.link}>
              <NavLink to={JOIN}>Back to login</NavLink>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Grid className={classes.text}>
        <Typography variant="body1" />
      </Grid>
      <ErrorDialog handleClose={setErrorShow} message={error} show={errorShow} />
    </main>
  );
}

ForgetPasswordPageView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const ForgetPasswordPage = connect(null, mapDispatchToProps)(withStyles(ForgetPasswordPageView, styles));
