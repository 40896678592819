import { createSelector } from 'reselect';

import { getSortAscending } from 'lib';

export const dictRootSelector = createSelector(
  root => root.dictionaries,
  dictionaries => dictionaries
);

export const assetTypesdSelector = createSelector(dictRootSelector, dictionaries => dictionaries.assetTypes);

export const countriesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.countries);

export const idTypesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.idTypes);

export const idTypesKYCsSelector = createSelector(dictRootSelector, dictionaries => dictionaries.idTypesKYCs);

export const idTypesNationalsSelector = createSelector(dictRootSelector, dictionaries => dictionaries.idTypesNationals);
export const investmentStagesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.investmentStages);
export const instrumentTypesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.instrumentTypes);
export const instrumentTypesDSSelector = createSelector(dictRootSelector, dictionaries => dictionaries.instrumentTypesDS);
export const markupTypesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.markupTypes);
export const commissionTypesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.commissionTypes);
export const bookBlockchainStatusesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.bookBlockchainStatuses);
export const bookBlockchainTxTypesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.bookBlockchainTxTypes);

export const bidBlockchainTxTypesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.bidBlockchainTxTypes);

export const bidBlockchainBusinessStatusesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.bidBlockchainBusinessStatuses);
export const DCRBlockchainStatusesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.DCRBlockchainStatuses);

export const DCRBlockchainTxTypesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.DCRBlockchainTxTypes);

export const DCRAvailableTickersSelector = createSelector(dictRootSelector, dictionaries => dictionaries.DCRTickers);

export const tradeBlockchainTxTypesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.tradeBlockchainTxTypes);

export const transferBlockchainTxTypesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.transferBlockchainTxTypes);

export const TransferAvailableTickersSelector = createSelector(dictRootSelector, dictionaries => dictionaries.transferTickers);

export const transactionTypesSelector = createSelector(dictRootSelector, dictionaries => getSortAscending(dictionaries.transactionTypes));
export const tradeTypesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.tradeTypes);

export const symbolTypesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.symbolTypes);

export const statusesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.statuses);

export const balancingTransactionsAvailableTypesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.balancingTransactionsAvailableTypes);
export const orderSourcesSelector = createSelector(dictRootSelector, dictionaries => dictionaries.orderSources);

export const blockchainsSelector = createSelector(dictRootSelector, dictionaries => getSortAscending(dictionaries.blockchains, 'BlockchainId'));
export const blockchainsSymbolsSelector = createSelector(dictRootSelector, dictionaries => dictionaries.blockchains
  .filter(blockchain => blockchain.Supported)
  .map(blockchain => blockchain.BlockchainId));
