import { combineReducers } from 'redux';

import { directSale, goldBars, offers, orders, promoCard, trades } from 'features/products/direct-sale';

import { reducer as transactions } from './features/transactions/symbiote';
import { reducer as settings } from './features/settings';
import { reducer as report } from './features/report';
import { reducer as tradingSettings } from './features/products/tradings-settings';
import { reducer as tokenOffering } from './features/products/token/features/token-offering';
import { reducer as bids } from './features/products/token/features/book-building/features/bids';
import { reducer as bookBuilding } from './features/products/token/features/book-building';
import { reducer as structuredProducts } from './features/products/structured-products';
import { reducer as positions } from './features/positions';
import { reducer as notifications } from './features/notifications';
import { reducer as marketingPrograms } from './features/marketing-programs';
import { reducer as users } from './features/manage-users';
import { reducer as disclosure } from './features/disclosure';
import { reducer as dictionaries } from './features/dictionaries';
import { reducer as cryptoCurrencies } from './features/cryptocurrencies';
import { reducer as conversionOrders } from './features/conversion-orders';
import { reducer as referralProgram } from './features/clients/referral-program/symbiotes';
import { reducer as paymentDestinations } from './features/clients/features/payment-destinations';
import { reducer as clientMarketingPrograms } from './features/clients/features/marketing-programs';
import { reducer as publicKeys } from './features/clients/features/api-keys';
import { reducer as account } from './features/clients/features/accounts';
import { reducer as clients } from './features/clients';
import { reducer as blockchains } from './features/blockchain/symbiote';
import { reducer as auth } from './features/auth';
import { reducer as twoFactor } from './features/2fa';

export const createReducer = () =>
  combineReducers({
    auth,
    report,
    clients,
    users,
    token: combineReducers({
      tokenOffering,
      bookBuilding,
      bids,
    }),
    directSale: combineReducers({
      directSale,
      offers,
      orders,
      trades,
      goldBars,
      promoCard,
    }),
    account,
    dictionaries,
    blockchains,
    settings,
    cryptoCurrencies,
    transactions,
    conversionOrders,
    notifications,
    twoFactor,
    referralProgram,
    disclosure,
    marketingPrograms,
    paymentDestinations,
    publicKeys,
    clientMarketingPrograms,
    structuredProducts,
    positions,
    tradingSettings,
  });
