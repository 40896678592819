import { withStyles } from 'tss-react/mui';
import { Outlet } from 'react-router-dom';
import React, { Suspense } from 'react';
import { AppBar, Tabs as MaterialTabs, Tab } from '@mui/material';

import { Spinner } from './spinner';

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  paddingTop: {
    paddingTop: '10px',
  },
});

const TabsView = ({ classes, tabs, handleChange, value, outletContext, handleClick }) => {

  const handleonChange = (_, val) => {
    handleChange(val);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        {/* <MaterialTabs classes={{ indicator: classes.tabsIndicator }} scrollButtons="auto" value={value} variant="scrollable" onChange={handleonChange}> */}
        <MaterialTabs indicatorColor="secondary" scrollButtons="auto" value={value} variant="scrollable" onChange={handleonChange} >
          {tabs.map(tab => (
            // <Tab classes={{ root: classes.tab, selected: classes.selected }} disabled={tab.disabled} key={tab.name} label={tab.name} />
            <Tab disabled={tab.disabled} key={tab.name} label={tab.name} wrapped={true} onClick={handleClick} />
          ))}
        </MaterialTabs>
      </AppBar>
      <div className={classes.paddingTop}>
        <Suspense fallback={<Spinner />}>
          <Outlet context={outletContext} />
        </Suspense>
      </div>
    </div>
  );
};

export const Tabs = withStyles(TabsView, styles);
