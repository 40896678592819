import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

import { PossibleStatus } from '../book-building/models';

import { GroupStatus } from './models';

export const initialGroup = {
  status: GroupStatus.IDLE,
  type: null,
  clientId: null,
  id: null,
  name: null,
  ticker: null,
  instrumentType: null,
  volume: null,
  offerings: [],
  statusDCR: PossibleStatus.NEED_DEPLOY,
};
export const initialState = {
  fetchOne: initialFetching,
  tokenOfferings: [],
  groups: [],
  group: initialGroup,
  tokenOffering: null,
  offeringsFilter: {},
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  setOfferings: (state, data) => ({ ...state, tokenOfferings: data }),
  setGroups: (state, data) => ({ ...state, groups: data }),
  setOffering: (state, data) => ({ ...state, tokenOffering: data }),
  setGroup: (state, data) => ({ ...state, group: data }),
  setOfferingsFilter: (state, value) => ({ ...state, offeringsFilter: value }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'tokenOffering');
