import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  bookBuilding: null,
  bookbuildingBids: [],
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  setBookBuilding: (state, data) => ({ ...state, bookBuilding: data }),
  setBookbuildingBids: (state, data) => ({ ...state, bookbuildingBids: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'bookBuilding');
