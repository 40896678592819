import { handleFetching } from 'symbiote-fetching';

import { mapTransactions } from 'features/transactions/mappers';
import { getResponse } from 'features/common';

import { actions } from './symbiotes';
import { mapAccounts, mapPositions } from './mappers';
import { accountsApi } from './api';

export const getAccountsV2 = query =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountsApi.getAccountsV2, query);
        const { ok, error, data } = getResponse(response);

        if (ok) {
          dispatch(actions.setAccounts(mapAccounts(response.source_data)));
        }

        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getCurrencies = query =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountsApi.getCurrencies, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setCurrencies(response.rows));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });
export const getAvailSymbolTickers = query =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountsApi.getAvailSymbolTickers, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setAvailTickers(response.source_data));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPositions = query =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountsApi.getPositions, query);
        const { ok, error, data } = getResponse(response);

        if (ok) {
          dispatch(actions.setPositions(mapPositions(response.rows)));
        }

        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getTransactions = query =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountsApi.getTransactions, query);

        const { ok, error, data } = getResponse(response);

        if (ok) {
          dispatch(actions.setTransactions(mapTransactions(response.rows)));
        }

        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const createTrade = form =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountsApi.createTrade, form);
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const createTradeVNXLU = form =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountsApi.createTradeVNXLU, form);
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getTradeTypes = query =>
  handleFetching(actions.fetchGetTradeTypes, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountsApi.getTradeTypes, query);

        const { ok, error, data } = getResponse(response);

        if (ok) {
          dispatch(actions.setTradeTypes(data));
        }

        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getTradeSymbols = query =>
  handleFetching(actions.fetchGetTradeSymbols, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountsApi.getTradeSymbols, query);

        const { ok, error, data } = getResponse(response);

        if (ok) {
          dispatch(actions.setTradeSymbols(data));
        }

        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getTradesAvailExternal = query =>
  handleFetching(actions.fetchGetTradeSymbols, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountsApi.getTradesAvailExternal, query);

        const { ok, error, data } = getResponse(response);

        if (ok) {
          dispatch(actions.setTradesAvailExternal(data));
        }

        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getAvailDirections = (AccountId, Type) =>
  handleFetching(actions.fetchGetTradeSymbols, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountsApi.getAvailDirections, { AccountId, Type });

        const { ok, error, data } = getResponse(response);

        if (ok) {
          dispatch(actions.setAvailDirections(data));
        }

        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });
