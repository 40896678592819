import { handleFetching } from 'symbiote-fetching';

import { setLocalStorage } from 'lib';
import { getResponse } from 'features/common';

import { actions } from './symbiotes';
import { AUTH_DATA } from './constants';
import { accountApi, sessionApi } from './api';

export const changePassword = (token, password) => async (dispatch) => {
  try {
    const response = await dispatch(accountApi.changePassword, token, {
      password,
    });

    return getResponse(response);
  } catch (error) {
    return { ok: false, error: { data: String(error), type: 'unknown_error' } };
  }
};

export const checkAuthenticated = () =>
  handleFetching(actions.fetchOne, {
    async run(dispatch) {
      const data = await dispatch(sessionApi.me);
      if (data && !data.code) {
        dispatch(actions.auth.set({ ...data, authenticated: true, type: data.Type }));
        return { authenticated: true, type: data.Type };
      } else {
        dispatch(actions.auth.set({ ...data, authenticated: false /*, type: data.Type*/ }));
        return { authenticated: false, ...data };
      }
    },
  });

export const getEmail = token => async (dispatch) => {
  try {
    const response = await dispatch(accountApi.getEmail, token);

    return getResponse(response);
  } catch (error) {
    return { ok: false, error: { data: String(error), type: 'unknown_error' } };
  }
};

export const getRegisterEmail = token => async (dispatch) => {
  try {
    const response = await dispatch(accountApi.getRegisterEmail, token);

    return getResponse(response);
  } catch (error) {
    return { ok: false, error: { data: String(error), type: 'unknown_error' } };
  }
};

export const logout = () => async (dispatch) => {
  try {
    const data = await dispatch(sessionApi.drop);
    if (data.status === 200 && !data.code) {
      await dispatch(actions.auth.reset());
      await dispatch(actions.auth.set({ authenticated: false }));
      return { ok: true, data };
    }
    return { ok: false, result: data?.code };
  } catch (error) {
    console.info(error);
  }
};

export const toRefreshToken = body => async (dispatch) => {
  try {
    const response = await dispatch(sessionApi.toRefreshToken, body);
    const { ok, data, error } = getResponse(response);

    if (ok) {
      setLocalStorage(AUTH_DATA, {
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        expires_in: data.expires_in,
        refresh_token_expire_in: data.refresh_token_expire_in,
      });
    }
    return { ok, data, error };
  } catch (error) {
    return { ok: false, error: { data: String(error), type: 'unknown_error' } };
  }
};

export const join =
  ({ login, password }) =>
    async (dispatch) => {
      try {
        const response = await dispatch(sessionApi.create, {
          login,
          password,
        });
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error), type: 'unknown_error' } };
      }
    };

export const join2fa =
  ({ login, password, code }) =>
    async (dispatch) => {
      try {
        const response = await dispatch(sessionApi.create2fa, {
          login,
          password,
          code,
        });

        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error), type: 'unknown_error' } };
      }
    };

export const register = (token, password) => async (dispatch) => {
  try {
    const response = await dispatch(accountApi.create, token, {
      password,
      region: 'US',
    });

    return getResponse(response);
  } catch (error) {
    return { ok: false, error: { data: String(error), type: 'unknown_error' } };
  }
};

export const sendTokenToEmail = email => async (dispatch) => {
  try {
    const response = await dispatch(accountApi.sendTokenToEmail, {
      email,
    });
    return getResponse(response);
  } catch (error) {
    return { ok: false, error: { data: String(error), type: 'unknown_error' } };
  }
};

export const getTokens = query => async (dispatch) => {
  try {
    const response = await dispatch(sessionApi.getTokens, query);
    const { ok, error, data } = getResponse(response);
    if (ok) {
      dispatch(actions.setTokens(data));
    }
    return { ok, error, data };
  } catch (error) {
    return { ok: false, error: { data: String(error), type: 'unknown_error' } };
  }
};

export const changeTokenStatus = (id, query) => async (dispatch) => {
  try {
    const response = await dispatch(sessionApi.changeTokenStatus, id, query);
    const { ok, error, data } = getResponse(response);

    return { ok, error, data };
  } catch (error) {
    return { ok: false, error: { data: String(error), type: 'unknown_error' } };
  }
};
