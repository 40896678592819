import { isEmpty } from 'lib';

import { ErrorType } from './models';
import { responseStatus } from './constants';

function compare(response) {
  return response.message ?? response.translatedMessage ?? response.code;
}

export const redirectToJoin = () => {
  let joinURL = `${window.location.protocol}//${window.location.host}/join`;
  const currentURL = window.location.href;
  const excludePathURL = [ '/join', '/sign-up', '/change-password', '/forger', '/register', '/confirm-email', '/sign-up/success' ];
  const isNotExcludeURL = !excludePathURL.filter(path => Boolean(currentURL.match(path))).length;

  if (isNotExcludeURL) {
    window.location.href = joinURL;
  }
};

export function getResponse(response) {
  if (response.status === responseStatus.badRequest) {
    if (response.details && !isEmpty(response.details)) {
      return {
        ok: false,
        error: { data: response.details, type: response.code, message: response.message ?? '' },
      };
    } else {
      return {
        ok: false,
        error: {
          data: compare(response),
          type: response.code,
        },
      };
    }
  }
  if (response.status === responseStatus.validationError || response.status === responseStatus.notFound) {
    if (response.details) {
      return { ok: false, error: { data: response.details, type: response.code } };
    }
    return {
      ok: false,
      error: { data: response.message, type: response.code },
    };
  }

  const isInvalidCredentials = response.code === 'invalid_credentials';
  if (response.status === responseStatus.forbidden && isInvalidCredentials) {
    redirectToJoin();
  }
  const isSessionExpired = response.code === 'session_expired';
  const isTokenDisabled = [
    ErrorType.TOKEN_NOT_REGISTRED,
    ErrorType.TOKEN_RECALLED_OR_DISABLED,
    ErrorType.JWT_EXPIRED,
    ErrorType.REFRESH_JWT_EXPIRED,
  ].includes(response.code);
  if (response.status === responseStatus.unauthorized && (isSessionExpired || isTokenDisabled)) {
    redirectToJoin();
  }
  if (response.status === responseStatus.success) {
    if (response.source_data) {
      return { ok: true, data: response.source_data };
    }
    return { ok: true, data: response };
  }

  return {
    ok: false,
    error: { data: compare(response), type: response.code },
  };
}
