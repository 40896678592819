import { withStyles } from 'tss-react/mui';
import React from 'react';
import { CircularProgress, } from '@mui/material';

const styles = (theme, { withoutMinHeight }) => ({
  root: {
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
    width: 'auto',
    minHeight: !withoutMinHeight ? '100vh' : 'none',
    alignItems: 'center',
  },
  colorCircularProgress: {
    color: '#00BDFF',
  },
});

const SpinnerView = ({ classes, size = 50, thickness = 5, isFullSize = true, withoutMinHeight = false }) => {
  if (isFullSize) {
    return (
      <div className={classes.root}>
        <CircularProgress classes={{ root: classes.colorCircularProgress }} size={size} thickness={thickness} />
      </div>
    );
  } else {
    return (
      <CircularProgress classes={{ root: classes.colorCircularProgress }} size={size} thickness={thickness} />
    );
  }
};

export const Spinner = withStyles(SpinnerView, styles);
