import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  referralList: [],
  filter: {},
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  setReferral: (state, data) => ({ ...state, referralList: data }),
  setFilter: (state, data) => ({ ...state, filter: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'referral');
