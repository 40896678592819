import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { BottomNavigation, Grid, TextField, Typography } from '@mui/material';

import { EditPen } from 'ui/edtit-pen';
import { NumberFormatInt } from 'ui';
import { getMSFromMin, getMinFromMS, useInterval, useLocalStorage } from 'lib';
import { blockchainsSelector } from 'features/dictionaries/selectors';
import { getBlockchains } from 'features/dictionaries/effects';

import { KeyboardEventKey } from '../models';

import { BlockchainsStatusBar } from './blockchains-status-bar';

const styles = theme => ({
  content: {
    height: '30px',
    padding: '0 15px',
  },
});

const mapStateToProps = state => ({
  blockchains: blockchainsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getBlockchains: () => dispatch(getBlockchains),
});
const timer = 600000;
const FooterView = ({ classes, getBlockchains, blockchains }) => {
  const [ minutes, setMinutes ] = useLocalStorage('blockchainsFreq', getMinFromMS(timer));
  const [ tempTime, setTempTime ] = useState('');
  const [ edit, setEdit ] = useState(false);

  useEffect(() => {
    getBlockchains();
  }, [ getBlockchains ]);

  useInterval(() => {
    getBlockchains();
  }, getMSFromMin(minutes));

  const aceptTime = () => {
    setEdit(false);
    if (!+tempTime) return;
    setMinutes(tempTime);
  };

  const onBlur = () => {
    setEdit(false);
    if (!+tempTime) return;
  };

  return (
    <BottomNavigation className={classes.content}>
      <Grid
        container
        alignItems='center'
        onKeyDown={(e) => {
          if (e.key === KeyboardEventKey.ENTER) aceptTime();
          if (e.key === KeyboardEventKey.ESC) setEdit(false);
        }}>
        <Typography style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>request frequency (min): {!edit ? minutes : ''}</Typography>
        {!edit ? <EditPen onClick={() => setEdit(true)}/> : null}
        {edit ?
          <TextField
            autoFocus
            InputProps={{
              inputComponent: NumberFormatInt,
              disableUnderline: true,
            }}
            value={minutes}
            variant='standard'
            onBlur={onBlur}
            onChange={e => setTempTime(e.target.value)}
          /> : null}

      </Grid>
      {blockchains?.length > 0 && <BlockchainsStatusBar blockchains={blockchains} classes={classes}/>}
    </BottomNavigation>
  );
};

export const Footer = connect(mapStateToProps, mapDispatchToProps)(withStyles(FooterView, styles));

