import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Fragment, useCallback, useEffect } from 'react';

import { ROOT } from 'routes';
import { ClientRoutes } from 'features/clients/models';

import { authenticatedSelector, authTypeSelector } from './selectors';
import { checkAuthenticated } from './effects';
import { AuthPaths } from './constants';

const mapStateToProps = state => ({
  authenticated: authenticatedSelector(state),
  type: authTypeSelector(state),
});

const mapDispatchToProps = dispatch => ({
  checkAuthenticated: () => dispatch(checkAuthenticated),
});

const AccountLoaderContainer = ({ children, authenticated, checkAuthenticated }) => {
  const { JOIN } = AuthPaths;
  const { CLIENTS_ROOT } = ClientRoutes;
  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo = useCallback(
    (path) => {
      navigate(path);
    },
    [ navigate ]
  );

  const redirectToPage = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const isNotForcedRedirect = !searchParams.get('redirect');
    if (isNotForcedRedirect && location.pathname === ROOT && authenticated) {
      navigate(CLIENTS_ROOT);
    } else if (!isNotForcedRedirect) {
      navigate(searchParams.get('redirect'));
    }
  }, [ navigate, authenticated, location.pathname, CLIENTS_ROOT ]);

  const checkUser = useCallback(async () => {
    const { authenticated } = await checkAuthenticated();
    if (!authenticated) {
      const currentPath = window.location.pathname;
      const searchParams = new URLSearchParams(window.location.search);
      const redirect = searchParams.get('redirect');
      if (currentPath === ROOT) {
        redirectTo(JOIN);
      } else if (currentPath !== JOIN && !redirect) {
        redirectTo(`${JOIN}?redirect=${window.location.pathname}`);
      }
    }
  }, [ checkAuthenticated, redirectTo, JOIN ]);

  useEffect(() => {
    if (!authenticated) {
      checkUser();
    } else {
      redirectToPage();
    }
  }, [ checkUser, redirectToPage, authenticated ]);

  return <Fragment>{children}</Fragment>;
};

export const AccountLoader = connect(mapStateToProps, mapDispatchToProps)(AccountLoaderContainer);
