import { createSelector } from 'reselect';

import { virtualAssetTypes } from 'features/common';

export const accountRootSelector = createSelector(
  root => root.account,
  account => account
);

export const accountCurrenciesSelector = createSelector(accountRootSelector, account => account.currencies);
export const accountAvailSymbolTickersSelector = createSelector(accountRootSelector, account => account.availTickers);
export const accountVirtualSelector = createSelector(accountRootSelector, account =>
  account.availTickers?.filter(currency => virtualAssetTypes.includes(currency.Type)) ?? []
);
export const accountsSelector = createSelector(accountRootSelector, account => account.accounts);

export const accountPositionsSelector = createSelector(accountRootSelector, account => account.positions);

export const accountTransactionsSelector = createSelector(accountRootSelector, account => account.transactions);

export const tradeTypesSelector = createSelector(accountRootSelector, account => account.tradeTypes);

export const tradeSymbolsSelector = createSelector(accountRootSelector, account =>
  account.tradeSymbols?.sort((a, b) => {
    if (a.Ticker < b.Ticker) {
      return -1;
    }
    if (a.Ticker > b.Ticker) {
      return 1;
    }
    return 0;
  })
);

export const availDirectionsSelector = createSelector(accountRootSelector, account => account.availDirections);

export const tradesAvailExternalSelector = createSelector(accountRootSelector, account => account.tradesAvailExternal);
