export const componentCryptoSettingsForm = [
  { titleAddress: 'BTC', address: 'BTC_ADDRESS' },
  { titleAddress: 'ETH', address: 'ETH_ADDRESS' },
  { titleAddress: 'USDC', address: 'USDC_ADDRESS' },
];
export const SettingsRoutes = {
  SETTINGS_ROOT: '/settings',
  COMMON: 'common',
  CRYPTO: 'crypto',
  // ACQUIRING: 'acquiring',
  COMMODITIES: 'commodities',
  SECURITY: 'security',
  RATES: 'rates',
  PROVIDERS: 'providers',
  TAB: ':tab',
};
export const SettingsTabs = {
  common: { name: 'Common settings', index: 0, route: SettingsRoutes.COMMON },
  crypto: { name: 'Crypto exchange settings', index: 1, route: SettingsRoutes.CRYPTO },
  // acquiring: { name: 'Acquiring settings', index: 2, route: SettingsRoutes.ACQUIRING },
  commodities: { name: 'Commodities settings', index: 2, route: SettingsRoutes.COMMODITIES },
  security: { name: 'Security settings', index: 3, route: SettingsRoutes.SECURITY },
  rates: { name: 'Rates source settings', index: 4, route: SettingsRoutes.RATES },
  providers: { name: 'KYC and AML Providers', index: 5, route: SettingsRoutes.PROVIDERS },
};

export const SpreadsMode = {
  ADD: 'Add',
  EDIT: 'Edit',
  DELETE: 'Delete',
};

export const MAX_SPREAD = 100;
