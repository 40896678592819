import { withStyles } from 'tss-react/mui';
import React from 'react';

import { ReactComponent as Pen } from 'assets/icons/edit_pen.svg';

const styles = (theme, { marginLeft }) => ({
  pen: {
    marginLeft: marginLeft ? theme.spacing(2) : 0,
    cursor: 'pointer',
    width: 16,
    height: 16,
  },
});

const EditPenView = ({ classes, marginLeft, ...props }) => (
  <Pen {...props} className={classes.pen}/>
);

export const EditPen = withStyles(EditPenView, styles);
