import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'common');
