import React from 'react';

import { OperatorsRoutes } from './models';

const OperatorsPage = React.lazy(() => import('./pages/operators-page'));
const OperatorsTab = React.lazy(() => import('./components/operators-tab'));
const ActionsTab = React.lazy(() => import('./components/actions-tab'));

const { OPERATORS_ROOT, LIST, ACTIONS } = OperatorsRoutes;
export const operatorsRoutes = [
  {
    path: OPERATORS_ROOT,
    element: <OperatorsPage />,
    children: [
      {
        path: LIST,
        element: <OperatorsTab/>,
      },
      {
        path: ACTIONS,
        element: <ActionsTab/>,
      },
    ],
  },
];
