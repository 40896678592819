import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

import { StructuredProductStatus } from './models';

export const initialStructuredProducts = {
  status: StructuredProductStatus.IDLE,
  offers: [],
};

export const initialOffer = {
  id: null,
  orders: [],
};

export const initialState = {
  fetchOne: initialFetching,
  structuredProducts: [],
  structuredProductsFilter: {},
  structuredProduct: initialStructuredProducts,
  offers: [],
  offer: initialOffer,
  orders: [],
  ordersFilters: {},
  order: {},
  orderStatuses: [],
  structuredProductsAvailSymbols: [],
  structuredProductsTypes: [],
  structuredProductsStatuses: [],
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  setStructuredProducts: (state, data) => ({ ...state, structuredProducts: data }),
  setStructuredProductsFilter: (state, data) => ({ ...state, structuredProductsFilter: data }),
  setStructuredProduct: (state, data) => ({ ...state, structuredProduct: data }),
  setOffers: (state, data) => ({ ...state, offers: data }),
  setOffer: (state, data) => ({ ...state, offer: data }),
  setOrders: (state, data) => ({ ...state, orders: data }),
  setOrdersFilters: (state, data) => ({ ...state, ordersFilters: data }),
  setOrder: (state, data) => ({ ...state, order: data }),
  setOrderStatuses: (state, data) => ({ ...state, orderStatuses: data }),
  setStructuredProductAvailSymbols: (state, data) => ({ ...state, structuredProductAvailSymbols: data }),
  setStructuredProductTypes: (state, data) => ({ ...state, structuredProductsTypes: data }),
  setStructuredProductStatuses: (state, data) => ({ ...state, structuredProductsStatuses: data }),
};
export const { actions, reducer } = createSymbiote(initialState, symbiote, 'structuredProducts');
