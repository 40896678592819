import React from 'react';
import { AccordionSummary, Accordion, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpansionPanel = ({ classes, renderTitle, renderContent, error, expanded, onChange }) => (
  <Accordion className={classes} expanded={expanded} style={{ border: `${error ? '1px solid red' : 'none'}` }} onChange={onChange}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>{renderTitle()}</AccordionSummary>
    <AccordionDetails>{renderContent()}</AccordionDetails>
  </Accordion>
);

export { ExpansionPanel };
