import cet from 'timezone/Europe';
import tz from 'timezone';
import { getTimezoneOffset } from 'date-fns-tz';

export const timeZoneBerlin = getTimezoneOffset('Europe/Berlin', new Date().getTime());
export const localTimeZone = getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone.toString());

export function addZeroBefore(n) {
  return (n < 10 ? '0' : '') + n;
}

export const monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
export const currentDate = new Date().toISOString().substring(0, 10);

export function convertDateTimeToCET(dateTime) {
  if (dateTime === null || dateTime === '' || dateTime === undefined) {
    return '';
  }
  const isValidDate = (typeof dateTime === 'string' && dateTime.slice(0, 4) > '1000') || typeof dateTime !== 'string';
  const eu = tz(cet);
  return isValidDate ? new Date(eu(tz(dateTime), '%Y/%m/%d %H:%M:%S', 'Europe/Berlin')) : new Date(dateTime);
}

export function convertDateTimestamp(dateTime) {
  if (dateTime === null || dateTime === '' || dateTime === undefined) {
    return null;
  }
  const eu = tz(cet);
  return eu(`${dateTime.getFullYear()}-${addZeroBefore(dateTime.getMonth() + 1)}-${addZeroBefore(dateTime.getDate())} 03:00`, 'Europe/Berlin');
}

export function convertDateTimeTimestamp(dateTime, withSeconds = false) {
  if (dateTime === null || dateTime === '' || dateTime === undefined) {
    return null;
  }
  const eu = tz(cet);
  return eu(
    `${dateTime.getFullYear()}-${addZeroBefore(dateTime.getMonth() + 1)}-${addZeroBefore(dateTime.getDate())} ${getTimeFormat(dateTime, withSeconds)}`,
    'Europe/Berlin'
  );
}

export const getTimeFormat = (dateString, withSeconds = false) => {
  if (dateString === null || dateString === undefined || dateString === '') {
    return '';
  }
  let date = new Date(dateString);

  let milliseconds = addZeroBefore(date.getMilliseconds());
  let seconds = addZeroBefore(date.getSeconds());
  let minutes = addZeroBefore(date.getMinutes());
  let hours = addZeroBefore(date.getHours());

  return `${hours}:${minutes}${withSeconds ? ':' + (seconds + '.' + milliseconds) : ''}`;
};

export const getDateMonthYearFormat = (date) => {
  const eu = tz(cet);
  let dateFormat = new Date(eu(tz(date), '%Y/%m/%d %H:%M:%S', 'Europe/Berlin'));
  return `${monthNames[dateFormat.getMonth()]} ${dateFormat.getFullYear()}`;
};

export const getDayMonthFormat = (date) => {
  const eu = tz(cet);
  let dateFormat = new Date(eu(tz(date), '%Y/%m/%d %H:%M:%S', 'Europe/Berlin'));
  return `${dateFormat.getDate()} ${monthNames[dateFormat.getMonth()]}`;
};

export const getDateFormat = (date) => {
  if (date === null || date === undefined || date === '') {
    return '';
  }
  const eu = tz(cet);
  return eu(tz(date), '%d.%m.%Y', 'Europe/Berlin');
};

export const currentFormatDate = getDateFormat(currentDate);

export const getDateFormatLocal = (date) => {
  if (date === null || date === undefined || date === '') {
    return '';
  }
  let yyyy = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  let mm = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
  let dd = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date);
  return mm + ' ' + dd + ', ' + yyyy;
};

export const getDateFormatForCalendar = (date) => {
  if (date === null || date === undefined || date === '') {
    return '';
  }
  let yyyy = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  let mm = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  let dd = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  return yyyy + '-' + mm + '-' + dd;
};

export const getDateTimeFormat = (date, withSeconds = false) => {
  if (date === null || date === undefined || date === '') {
    return '';
  }
  const eu = tz(cet);
  return eu(tz(date), `%d.%m.%Y %H:%M${withSeconds ? ':%S' : ''}`, 'Europe/Berlin');
};

export const getDateTimeFormatFromString = (dateString, withSeconds = false) => {
  if (dateString === null || dateString === undefined || dateString === '') {
    return '';
  }
  const dateTime = new Date(dateString);
  const date = `${dateTime.getFullYear()}/${addZeroBefore(dateTime.getMonth() + 1)}/${addZeroBefore(dateTime.getDate())} ${getTimeFormat(dateTime, withSeconds)}`;

  return `${getDateTimeFormat(new Date(date), withSeconds)} CET`;
};

export const getNowCET = () => {
  const eu = tz(cet);
  return eu(tz(new Date()), 'Europe/Berlin');
};

export function convertDateTimestampFilter(dateTime) {
  if (dateTime === null || dateTime === '' || dateTime === undefined) {
    return null;
  }
  return new Date((new Date(dateTime).getTime() + localTimeZone) - timeZoneBerlin).getTime();
}

export function getCurrentTime() {
  return new Date((new Date().getTime() - localTimeZone) + timeZoneBerlin);
}

export function convertDateTimeTimestampFilter(dateTime) {
  if (dateTime === null || dateTime === '' || dateTime === undefined) {
    return null;
  }
  return new Date(new Date(dateTime) - new Date('1970-01-01 00:00:00')).getTime();
}

export const checkIsExpired = (expiredDate) => {
  if (expiredDate === '' || expiredDate === null) {
    return false;
  }
  return convertDateTimestampFilter(expiredDate) < convertDateTimestampFilter(currentDate);
};
