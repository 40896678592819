export const TransactionTypes = {
  Deploy: 'deploy',
  Close: 'close',
};

export const BlockchainStatuses = {
  QUEUE: 'queue',
  CONFIRMATION: 'confirmation',
  FAILED: 'failed',
};

export const ListStatus = {
  ALL: 'all',
  DECLINED: 'declined',
};

export const mapBCStatuses = {
  confirmed: 'success',
  failed: 'failed',
  queued: 'queued',
};
export const BCSStatus = { CONFIRMED: 'confirmed', QUEUED: 'queued' };

export const TradeBlockchainTxTypes = {
  DCR_TRANSFER: 'dcr-transfer',
  DGR_MINT: 'dgr-mint',
  DCR_BURN: 'dcr-burn',
  DGR_BURN: 'dgr-burn',
  DGR_TRADE: 'dgr-trade',
};

export const BlockchainRoutes = {
  BLOCKCHAINS_ROOT: '/blockchains',
  BOOKS: 'books',
  BIDS: 'bids',
  DEPLOYMENTS: 'deployments',
  TRADES: 'trading',
  TRANSFERS: 'transfers',
};
export const BlockchainTabs = {
  books: { name: 'Books', index: 0, route: BlockchainRoutes.BOOKS },
  bids: { name: 'Bids', index: 1, route: BlockchainRoutes.BIDS },
  dcrs: { name: 'Deployments', index: 2, route: BlockchainRoutes.DEPLOYMENTS },
  trades: { name: 'Trading', index: 3, route: BlockchainRoutes.TRADES },
  transfers: { name: 'Transfers', index: 4, route: BlockchainRoutes.TRANSFERS },
};
export const BlockchainSubTabs = {
  all: { name: 'All', index: 0, route: ListStatus.ALL },
  declined: { name: 'Declined', index: 1, route: ListStatus.DECLINED },
};
