import ReactDOM from 'react-dom';
import React from 'react';

export const DatepickerContainer = ({ children }) =>
  children
    ? ReactDOM.createPortal(
      React.cloneElement(children, {
        className: 'react-datepicker-popper',
      }),
      document.body
    )
    : null;
