import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';

import { theme } from './theme';
import { configureStore } from './store';
import * as serviceWorker from './serviceWorker';
import App from './app';

const root = createRoot(document.querySelector('#root'));
const store = configureStore({});

const render = () => {
  root.render(
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

if (module.hot) {
  module.hot.accept('./app', render);
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
