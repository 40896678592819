import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  bids: [],
  bid: null,
  clientBalance: {
    deposit: 0,
    vnxBalance: 0,
  },
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  setBids: (state, data) => ({ ...state, bids: data }),
  setBid: (state, data) => ({ ...state, bid: data }),
  setClientBalance: (state, data) => ({ ...state, clientBalance: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'bids');
