import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, InputLabel, Select, MenuItem, Tooltip, } from '@mui/material';

import { actions } from '../symbiotes';
import { transactionTypesSelector } from '../selectors';
import { dictionariesApi } from '../api';

const styles = theme => ({
  select: {
    minWidth: '80px',
    maxWidth: '160px',
  },
  filterItem: {
    '& + .MuiFormControl-root': {
      marginLeft: 15,
    },
  },
});

const mapStateToProps = state => ({
  transactionTypes: transactionTypesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getAvailableTransactionTypes: async () => {
    const res = await dispatch(dictionariesApi.getAvailableTransactionTypes);
    dispatch(actions.saveTransactionTypes(res));
  },
});

export const TransactionTypesView = ({ classes, transactionTypes, value, onChangeMultiselect, disabled, label, name, getAvailableTransactionTypes }) => {

  const [ isShowTooltip, setShowTooltip ] = useState(false);

  useEffect(() => {
    if (!transactionTypes.length) {
      getAvailableTransactionTypes();
    }
  }, [ getAvailableTransactionTypes, transactionTypes.length ]);

  return (
    <FormControl className={classes.filterItem} variant="standard">
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Tooltip open={isShowTooltip} placement='top' title={value.join(', ')} >
        <Select
          multiple
          className={classes.select}
          disabled={disabled}
          name={name}
          renderValue={selected => selected.join(', ')}
          value={value}
          variant="standard"
          onChange={e => onChangeMultiselect(e)}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          onOpen={() => setShowTooltip(false)}
        >
          <MenuItem value="none">
            Clear
          </MenuItem>
          {transactionTypes?.map(type => (
            <MenuItem key={type} value={type}>
              <Checkbox checked={value.includes(type)} color="primary" />
              {type}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    </FormControl>
  );
};

export const TransactionTypes = connect(mapStateToProps, mapDispatchToProps)(withStyles(TransactionTypesView, styles));
