import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  fetchGetTradeTypes: initialFetching,
  accounts: [],
  currencies: [],
  availTickers: null,
  positions: [],
  transactions: [],
  tradeTypes: [],
  tradeSymbols: [],
  availDirections: [],
  tradesAvailExternal: null,
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchGetTradeTypes: createFetching('fetchGetTradeTypes'),
  fetchGetTradeSymbols: createFetching('fetchGetTradeSymbols'),
  setAccounts: (state, data) => ({ ...state, accounts: data }),
  setCurrencies: (state, data) => ({ ...state, currencies: data }),
  setAvailTickers: (state, data) => ({ ...state, availTickers: data }),
  setPositions: (state, data) => ({ ...state, positions: data }),
  setTransactions: (state, data) => ({ ...state, transactions: data }),
  setTradeTypes: (state, data) => ({ ...state, tradeTypes: data }),
  setTradeSymbols: (state, data) => ({ ...state, tradeSymbols: data }),
  setAvailDirections: (state, data) => ({ ...state, availDirections: data }),
  setTradesAvailExternal: (state, data) => ({ ...state, tradesAvailExternal: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'account');
