import React from 'react';
import Typography from '@mui/material/Typography';

import { Message } from './message';

const InfoDialog = ({ show, handleClose, message }) => (
  <Message open={show} setOpen={() => handleClose(!show)} title="Info">
    <Typography variant="subtitle1">{message}</Typography>
  </Message>
);

export { InfoDialog };
