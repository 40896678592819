import React from 'react';

import { AuctionsRoutes } from './models';

const TokensPage = React.lazy(() => import('./pages/tokens-page'));
const OfferingPage = React.lazy(() => import('./pages/offering-page'));
const GroupPage = React.lazy(() => import('./pages/group-page'));
const OfferingsTab = React.lazy(() => import('./components/offering/offerings-tab'));
const BookBuildingPage = React.lazy(() => import('../book-building/book-building-page'));
const OfferingDetails = React.lazy(() => import('./components/offering/offering-details'));
const GroupsTab = React.lazy(() => import('./components/group/groups-tab'));

const { AUCTION_ROOT, GROUPS, OFFERINGS, GROUP_OFFERING, NEW, ID, TOKEN, BIDDING, GROUP_PAGE } = AuctionsRoutes;

export const tokenRoutes = [
  {
    path: AUCTION_ROOT,
    element: <TokensPage />,
    children: [
      {
        path: GROUPS,
        element: <GroupsTab />,
      },
      {
        path: OFFERINGS,
        element: <OfferingsTab />,
      },
    ],
  },

  {
    path: GROUP_OFFERING,
    element: <OfferingPage />,
    children: [
      {
        path: NEW,
        element: <OfferingDetails />,
      },
      {
        path: ID,
        children: [
          {
            path: TOKEN,
            element: <OfferingDetails />,
          },
          {
            path: BIDDING,
            element: <BookBuildingPage />,
          },
        ],
      },
    ],
  },
  {
    path: GROUP_PAGE,
    element: <GroupPage />,
    children: [
      {
        path: NEW,
        element: <GroupPage />,
      },
      {
        path: ID,
        element: <GroupPage />,
      },
    ],
  },
];
