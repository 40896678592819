import { withStyles } from 'tss-react/mui';
import { NavLink, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, FormHelperText, InputBase, Typography, Button, FormControl, InputLabel, CssBaseline } from '@mui/material';
import { load } from '@fingerprintjs/fingerprintjs';

import { ErrorDialog } from 'ui';
import { useLocalStorage } from 'lib';

import { join, join2fa } from '../effects';
import { APP_DEVICE_ID, AUTH_DATA, AuthPaths, TIME_ZONE, statusSignUp } from '../constants';
import { Logo } from '../components';
import { InputCodeTwoFactor } from '../../2fa/components';

const styles = theme => ({
  main: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#F4F4F4',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '40px 30px 30px 30px',
    width: '300px',
    height: '402px',
    boxShadow: 'inherit',
    borderRadius: '0px',
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '26px',
    fontHeight: '32px',
  },
  submit: {
    backgroundColor: '#00BDFF',
    width: '127px',
    height: '40px',
    borderRadius: '0px',
    boxShadow: 'inherit',
    '&:hover': {
      backgroundColor: '#333333',
      color: '#FFFFFF',
    },
  },
  link: {
    alignItems: 'center',
    marginTop: '20px',
    '& > a': {
      fontWeight: theme.typography.fontWeightMedium,
      textDecoration: 'none',
      color: '#00BDFF',
      letterSpacing: '0.01em',
    },
  },
  text: {
    width: '300px',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: '100px',
    marginBottom: '25px',
  },
  inputRoot: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  inputField: {
    border: '1px solid #E3E3E3',
    fontSize: 16,
    width: '100%',
    transition: theme.transitions.create([ 'border-color', 'box-shadow' ]),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    paddingLeft: '5px',
  },
  inputLabel: {
    fontSize: '20px',
    color: '#333333',
  },
});

const mapDispatchToProps = dispatch => ({
  onLogin: loginData => dispatch(join, loginData),
  onLogin2fa: loginData => dispatch(join2fa, loginData),
});

function SignInPageView({ classes, onLogin, onLogin2fa }) {
  const { FORGET } = AuthPaths;
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');

  const [ emailPasswordError, setEmailPasswordError ] = useState('');
  const [ isError, setIsError ] = useState(false);

  const [ error, setError ] = useState({});
  const [ errorShow, setErrorShow ] = useState(false);

  const [ status, setStatus ] = useState('');
  const [ code, setCode ] = useState('');

  const navigate = useNavigate();
  const [ , setAppDeviceId ] = useLocalStorage(APP_DEVICE_ID, '');
  const [ , setAuthData ] = useLocalStorage(AUTH_DATA, '');
  const [ , setTimeZone ] = useLocalStorage(TIME_ZONE, '');

  useEffect(() => {
    const fpPromise = load();
    (async () => {
      const fp = await fpPromise;
      const result = await fp.get();
      setAppDeviceId(result.visitorId);
      setTimeZone(result.components.timezone.value);
    })();
  }, [ setAppDeviceId, setTimeZone ]);

  const clearError = () => {
    setEmailPasswordError('');
    setIsError(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { ok, data, error } = await onLogin({
      login: email,
      password: password,
    });
    if (ok) {
      setAuthData({
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        expires_in: data.expires_in,
        refresh_token_expire_in: data.refresh_token_expire_in,
      });
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get('redirect')) {
        navigate(searchParams.get('redirect'));
      } else if (!searchParams.get('redirect')) {
        navigate('/');
      }
    } else {
      if (error.type === 'wrong_email_or_password') {
        setEmailPasswordError('Wrong email or password');
        setIsError(true);
      } else if (error.type === 'invalid_credentials') {
        setEmailPasswordError('Wrong email or password');
        setIsError(true);
      } else if (error.type === '2fa_required') {
        setStatus(statusSignUp.twoFactorAuth);
      } else {
        setError(error.data);
        setErrorShow(true);
      }
    }
  };

  const handleContinue = async (event) => {
    event.preventDefault();
    const { ok, data, error } = await onLogin2fa({
      login: email,
      password: password,
      code: code,
    });

    if (ok) {
      setAuthData({
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        expires_in: data.expires_in,
        refresh_token_expire_in: data.refresh_token_expire_in,
      });
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get('redirect')) {
        navigate(searchParams.get('redirect'));
      } else if (!searchParams.get('redirect')) {
        navigate('/');
      }
    } else {
      if (error.type === 'invalid_credentials') {
        setError('Wrong email or password or code');
        setIsError(true);
      } else {
        setError(error.data);
        setErrorShow(true);
      }
    }
  };

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Logo />
      {status === statusSignUp.twoFactorAuth ? (
        <div className={classes.container}>
          <InputCodeTwoFactor
            code={code}
            error={error}
            handleContinue={handleContinue}
            isError={isError}
            setCode={setCode}
            title="Enter the verification code generated by Google Authentificator app your phone."
          />
        </div>
      ) : ( //TODO: переделать <form... ?
        <Paper className={classes.paper}>
          <Typography className={classes.title} component="h1" variant="h5">
            Login
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl fullWidth required error={isError} margin="normal" variant="standard">
              <InputLabel shrink className={classes.inputLabel} htmlFor="email">
                Email
              </InputLabel>
              <InputBase
                autoFocus
                autoComplete="Email"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputField,
                }}
                id="email"
                name="email"
                value={email}
                onChange={(e) => {
                  clearError();
                  setEmail(e.target.value);
                }}
              />
              <FormHelperText error={isError}>{emailPasswordError}</FormHelperText>
            </FormControl>
            <FormControl fullWidth required error={isError} margin="normal" variant="standard">
              <InputLabel shrink className={classes.inputLabel} htmlFor="password">
                Password
              </InputLabel>
              <InputBase
                autoComplete="current-password"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputField,
                }}
                id="password"
                inputProps={{
                  maxLength: '30',
                  minLength: '5',
                }}
                name="password"
                type="password"
                value={password}
                onChange={(e) => {
                  clearError();
                  setPassword(e.target.value);
                }}
              />
              <FormHelperText error={isError}>{emailPasswordError}</FormHelperText>
            </FormControl>
            <Grid container alignItems="flex-start" justifyContent="center">
              <Grid item>
                <Button fullWidth className={classes.submit} color="primary" type="submit" variant="contained">
                  Login
                </Button>
              </Grid>
              <Grid item className={classes.link}>
                <NavLink to={FORGET}>Forgot password</NavLink>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
      <Grid className={classes.text}>
        <Typography variant="body1" />
      </Grid>
      <ErrorDialog handleClose={setErrorShow} message={error} show={errorShow} />
    </main>
  );
}

SignInPageView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const SignInPage = connect(null, mapDispatchToProps)(withStyles(SignInPageView, styles));
