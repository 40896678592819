import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  positionsList: [],
  positionsListFilter: {},
  selectedAssets: [],
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  setPositionsList: (state, data) => ({ ...state, positionsList: data }),
  setPositionsListFilter: (state, data) => ({ ...state, positionsListFilter: data }),
  setSelectedAssets: (state, data) => ({ ...state, selectedAssets: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'positions');
