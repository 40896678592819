import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, MenuItem, InputLabel, Select, Tooltip, } from '@mui/material';

import { statusesSelector } from '../selectors';
import { getAvailableStatuses } from '../effects';

const styles = theme => ({
  select: {
    minWidth: '120px',
    maxWidth: '220px',
  },
  filterItem: {
    '& + .MuiFormControl-root': {
      marginLeft: 15,
    },
  },
});

const mapStateToProps = state => ({
  statuses: statusesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getAvailableStatuses: () => dispatch(getAvailableStatuses),
});

export const StatusesView = ({ classes, statuses = [], value, onChangeMultiselect, disabled, label, name, getAvailableStatuses }) => {

  const [ isShowTooltip, setShowTooltip ] = useState(false);
  useEffect(() => {
    if (!statuses.length) {
      getAvailableStatuses();
    }
  }, [ getAvailableStatuses, statuses.length ]);
  return (
    <FormControl className={classes.filterItem} variant="standard">
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Tooltip open={isShowTooltip} placement='top' title={value.join(', ')} >
        <Select
          multiple
          className={classes.select}
          disabled={disabled}
          name={name}
          renderValue={selected => selected.join(', ')}
          value={value}
          variant="standard"
          onChange={e => onChangeMultiselect(e)}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          onOpen={() => setShowTooltip(false)}
        >
          <MenuItem value="none">
            Clear
          </MenuItem>
          {statuses?.map(status => (
            <MenuItem key={status} value={status}>
              <Checkbox checked={value.includes(status)} color="primary" />
              {status}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    </FormControl>
  );
};

export const Statuses = connect(mapStateToProps, mapDispatchToProps)(withStyles(StatusesView, styles));
