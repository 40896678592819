import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, FormControl, InputLabel, Select, MenuItem, Tooltip, } from '@mui/material';

import { accountAvailSymbolTickersSelector, accountVirtualSelector } from 'features/clients/features/accounts/selectors';
import { getAvailSymbolTickers } from 'features/clients/features/accounts/effects';

const styles = theme => ({
  select: {
    minWidth: '160px',
    maxWidth: '360px',
  },
  filterItem: {
    '& + .MuiFormControl-root': {
      marginLeft: 15,
    },
  },
});

const mapStateToProps = state => ({
  symbols: accountAvailSymbolTickersSelector(state),
  virtualSymbols: accountVirtualSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getSymbols: () => dispatch(getAvailSymbolTickers),
});

export const SymbolIdView = ({
  classes,
  value,
  selectedVirtualAssets,
  onChangeMultiselect,
  disabled,
  label,
  name,
  symbols,
  allVirtualStatus,
  virtualSymbols,
  setAllVirtualStatus,
  getSymbols,
}) => {

  useEffect(() => {
    if (!symbols) {
      getSymbols();
    }
  }, [ getSymbols, symbols ]);

  const notSelectedVirtualAssets = useMemo(() => virtualSymbols.filter(symbol => !value.some(val => val.id === symbol.id)), [ value, virtualSymbols ]);

  const [ isShowTooltip, setShowTooltip ] = useState(false);
  useEffect(() => {
    if (virtualSymbols.length) {
      if (selectedVirtualAssets.length === virtualSymbols.length) {
        setAllVirtualStatus('checked');
      } else {
        setAllVirtualStatus('unchecked');
      }
    }
  }, [ selectedVirtualAssets, setAllVirtualStatus, virtualSymbols.length ]);

  return (
    <FormControl className={classes.filterItem} variant="standard">
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Tooltip open={isShowTooltip} placement='top' title={value.map(sym => sym.Ticker).join(', ')} >
        <Select
          multiple
          className={classes.select}
          disabled={disabled}
          name={name}
          renderValue={selected => selected.map(sym => sym.Ticker).join(', ')}
          value={value}
          variant="standard"
          onChange={e => onChangeMultiselect(e, notSelectedVirtualAssets)}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          onOpen={() => setShowTooltip(false)}
        >
          <MenuItem value="none">
            Clear
          </MenuItem>
          <MenuItem value={allVirtualStatus}>
            <Checkbox checked={allVirtualStatus === 'checked'} color="primary" />
            All Virtual Assets
          </MenuItem>
          {symbols?.map(symbol => (
            <MenuItem key={symbol.id} value={symbol}>
              <Checkbox checked={value.some(item => item.id === symbol.id)} color="primary" />
              {symbol.Ticker}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    </FormControl>
  );
};

export const SymbolId = connect(mapStateToProps, mapDispatchToProps)(withStyles(SymbolIdView, styles));
