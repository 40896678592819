import { createFetching, initialFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

const transactionsInitialState = {
  fetchOne: initialFetching,
  filter: [],
  lpFilter: {},
  item: null,
  items: [],
  total: 0,
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  setFilter: (state, filter) => ({ ...state, filter }),
  setLpFilter: (state, lpFilter) => ({ ...state, lpFilter }),
  setItem: (state, item) => ({ ...state, item }),
  setItems: (state, items) => ({ ...state, items }),
  setTotal: (state, total) => ({ ...state, total }),
};

export const { actions, reducer } = createSymbiote(transactionsInitialState, symbiote, 'transactions');
