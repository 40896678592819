import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { InputLabel, FormControl, Select, Input, MenuItem } from '@mui/material';

import { actions } from '../symbiotes';
import { investmentStagesSelector } from '../selectors';
import { dictionariesApi } from '../api';

const mapStateToProps = state => ({
  investmentStages: investmentStagesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getInvestmentStages: async () => {
    const res = await dispatch(dictionariesApi.getInvestmentStages);
    dispatch(actions.saveInvestmentStages(res));
  },
});

export const InvestmentStagesItemsView = ({ getInvestmentStages, investmentStages, classes, errors, value, readOnly, onChange, label, name }) => {
  useEffect(() => {
    if (!investmentStages.length) {
      getInvestmentStages();
    }
  }, [ getInvestmentStages, investmentStages.length ]);
  return (
    <FormControl className={classes} error={errors} variant="standard">
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        multiple
        disabled={readOnly}
        error={errors}
        input={<Input id="investmentStages" />}
        name={name}
        value={value}
        variant="standard"
        onChange={e => onChange(e.target.value)}>
        <MenuItem value="">None</MenuItem>
        {investmentStages.map(a => (
          <MenuItem key={a.id} value={a.Name}>
            {a.Name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const InvestmentStagesItems = connect(mapStateToProps, mapDispatchToProps)(InvestmentStagesItemsView);
