export const TokenOfferingStatus = {
  IDLE: 'idle',
  NEW: 'new',
  ONGOING: 'ongoing',
  SUSPENDED: 'suspended',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
};

export const GroupStatus = {
  IDLE: 'idle',
  ACTIVE: 'active',
  CLOSED: 'closed',
};

export const GroupType = {
  AUCTION: 'auction',
  DIRECT: 'direct_sales',
};

export const AuctionsRoutes = {
  AUCTION_ROOT: 'token',
  GROUPS: 'groups',
  OFFERINGS: 'offerings',
  GROUP_OFFERING: 'token/group/:groupId/offering',
  NEW: 'new',
  ID: ':id',
  TOKEN: 'token',
  BIDDING: 'bidding',
  GROUP_PAGE: 'token/group',
};
export const oferringPageTabs = [ 'token', 'bidding' ];

export const TokenPageTabs = {
  groups: { name: 'Groups', index: 0, route: AuctionsRoutes.GROUPS },
  offerings: { name: 'Auction offerings', index: 1, route: AuctionsRoutes.OFFERINGS },
};
export const OfferingPageTabs = {
  token: { name: 'Token offering info', index: 0, route: AuctionsRoutes.TOKEN },
  bidding: { name: 'Bidding info', index: 1, route: AuctionsRoutes.BIDDING },
};
