export const ConversionRoutes = {
  CONVERSION_ROOT: '/conversion-orders',
};
export const ConversionOrdersStatus = {
  SUCCESS: 'Success',
  CANCELED: 'Canceled',
  PARTIALY_CANCELED: 'Partially canceled',
  PARTIALY_FILLED: 'Partially filled',
  NEW: 'New',
};
