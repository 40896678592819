import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchGetConversionOrders: initialFetching,
  fetchGetDSConversionOrders: initialFetching,
  fetchFilterDataConversionOrders: initialFetching,
  fetchResendConversionOrders: initialFetching,
  fetchCancelConversionOrder: initialFetching,
  fetchGetMinSizeConversionOrders: initialFetching,
  allConversionOrders: [],
  allConversionOrdersFilter: {},
  dsConversionOrders: [],
  dsConversionOrdersFilter: {},
  filterData: {},
};

const symbiote = {
  fetchGetConversionOrders: createFetching('fetchGetConversionOrders'),
  fetchGetDSConversionOrders: createFetching('fetchGetDSConversionOrders'),
  fetchFilterDataConversionOrders: createFetching('fetchFilterDataConversionOrders'),
  fetchResendConversionOrders: createFetching('fetchResendConversionOrders'),
  fetchCancelConversionOrder: createFetching('fetchCancelConversionOrder'),
  fetchGetMinSizeConversionOrders: createFetching('fetchGetMinSizeConversionOrders'),
  setAllConversionOrders: (state, data) => ({ ...state, allConversionOrders: data }),
  setAllConversionOrdersFilter: (state, data) => ({ ...state, allConversionOrdersFilter: data }),
  setDSConversionOrders: (state, data) => ({ ...state, dsConversionOrders: data }),
  setDSConversionOrdersFilter: (state, data) => ({ ...state, dsConversionOrdersFilter: data }),
  setFilterData: (state, data) => ({ ...state, filterData: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'conversionOrders');
