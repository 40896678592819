import { getDateTimeFormatFromString, convertNumber, AssetType } from 'lib';
import { TransactionDirection } from 'features/common';

const { CURRENCY, CRYPTO } = AssetType;
const { BUY, SELL } = TransactionDirection;

const quantity = (transaction) => {
  if (transaction.Quantity === 0) {
    return '0';
  } else if (transaction?.Direction === BUY) {
    return String('+' + convertNumber(transaction.Symbol.Type, transaction.Quantity));
  } else if (transaction?.Direction === SELL) {
    return String('-' + convertNumber(transaction.Symbol.Type, transaction.Quantity));
  } else return null;
};

export const mapAccounts = items =>
  items.map(item => ({
    id: item.id,
    linkedId: item.ParentAccountId,
    number: item.AccNo,
    type: item.Type,
    balance: convertNumber(CURRENCY, item.Balance),
    asset: item.Symbol.Ticker,
    capacity: item.Capacity,
  }));

export const mapPositions = items =>
  items.map(item => ({
    id: item.id,
    balance: convertNumber(CURRENCY, item.Balance),
    quantity: convertNumber(item.Symbol.Type ?? CRYPTO, item.Quantity),
    availQty: convertNumber(item.Symbol.Type ?? CRYPTO, item.AvailQty),
    asset: item.Symbol.Ticker,
    symbolId: item.SymbolId,
    unsettledAmount: convertNumber(item.Symbol.Type, item.UnsettledAmount),
    subPositions: mapSubPositions(item.Positions ?? []),
    rootDSId: item.Symbol?.Parent?.id ?? item.SymbolId,
  })).sort((a, b) => {
    if (a.rootDSId > b.rootDSId) return 1;
    if (b.rootDSId > a.rootDSId) return -1;
    if (a.asset > b.asset) return 1;
    if (b.asset > a.asset) return -1;
    return 0;
  });

const mapSubPositions = items =>
  items.map(item => ({
    quantity: convertNumber(CRYPTO, item.Quantity),
    clientId: item.ClientId,
    frtAsset: item.FrtTicker,
    asset: item.DgrTicker,
    symbolId: item.SymbolId,
  }));

export const mapTransactions = items =>
  items.map(item => ({
    date: getDateTimeFormatFromString(item.Date, true),
    tradeId: item.TradeId,
    transactionId: item.id,
    type: item.Type,
    direction: item.Direction,
    account: item.Account?.AccNo,
    asset: item.Symbol?.Ticker,
    section: item.Trade?.Section,
    quantity: quantity(item),
    settlementCurrency: item.SettlementCurrency?.Ticker,
    sender: '',
    status: item?.Status ?? '-',
    unsettled: item?.Unsettled || null,
    counterpartyTxId: item?.CounterpartyTxId ?? null,
    txHash: item.TxHash,
    vasp: item.TxDetails.find(detail => detail.Type === 'ExternalTx')?.Data?.Vasp?.Name ?? '-',
    txUrl: item.TxDetails.find(detail => detail.Type === 'ExternalTx')?.Data?.url ?? '-',
    aml: item.TxDetails.find(detail => detail.Type === 'Scoring')?.Data?.score ?? '-',
    userId: item.TxDetails.find(detail => detail.Type === 'User')?.Data?.Login ?? '-',
  }));
