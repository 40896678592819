import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchPublicKeyList: initialFetching,
  publicKeyList: [],
  publicKey: null,
};

const symbiote = {
  fetchPublicKeyList: createFetching('fetchPublicKeyList'),
  setPublicKeyList: (state, data) => ({ ...state, publicKeyList: data }),
  setPublicKey: (state, data) => ({ ...state, publicKey: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'apiKeys');
