import { withStyles } from 'tss-react/mui';
import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid, Typography } from '@mui/material';

const styles = theme => ({
  pswd_info: {
    fontSize: '.875em',
  },
  invalid: {
    color: 'red',
  },
  valid: {
    color: '#44d437',
  },
  none: {
    display: 'none',
  },
});

function PassRepeatView({ classes, display, same }) {
  return (
    <Grid className={display ? classes.pswd_info : classes.none}>
      <Typography className={!same ? classes.invalid : classes.valid} variant="body2">
        The entered passwords must be the same
      </Typography>
    </Grid>
  );
}
PassRepeatView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const PassRepeat = withStyles(PassRepeatView, styles);
