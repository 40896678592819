import { withStyles } from 'tss-react/mui';
import React from 'react';
import { FormControl, MenuItem, InputLabel, Select, } from '@mui/material';

const styles = theme => ({
  select: {
    minWidth: '60px',
    maxWidth: '220px',
  },
  filterItem: {
    '& + .MuiFormControl-root': {
      marginLeft: 15,
    },
  },
});

export const DirectionView = ({ classes, value, onChange, disabled, label, name }) => {

  const directions = [ 'Buy', 'Sell' ];

  return (
    <FormControl className={classes.filterItem} variant="standard">
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        className={classes.select}
        disabled={disabled}
        name={name}
        value={value}
        variant="standard"
        onChange={onChange}>
        <MenuItem value="">
          None
        </MenuItem>
        {directions.map(direction => (
          <MenuItem key={direction} value={direction}>
            {direction}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const Direction = withStyles(DirectionView, styles);
